import { useEffect, useState, useRef, useMemo, useCallback, useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';
import { CetComponentsContext } from 'cet-components-lib/dist/providers/CetComponentsProvider';
import { Modal } from 'cet-components-lib/dist/Layout/Modal';
import { SliderLayout } from 'cet-components-lib/dist/Layout/SliderLayout';
import { Chip } from 'cet-components-lib/dist/UI/Chip';
import { Button } from 'cet-components-lib/dist/UI/Button';
import { Title } from 'cet-components-lib/dist/UI/Title';
import { Icon } from 'cet-components-lib/dist/UI/Icon';
import { Preloader } from 'cet-components-lib/dist/UI/Preloader';

import { debounce } from '../../utils/events';
import { safelyGetInnerHTML } from '../../utils/html';
import { CacheProvider } from '../../utils/cacheProvider';
import { compareStrings } from '../../utils/strings';
import { useUserProfile, useGenderFormatMessage, useAnnualProfileUpdate } from '../../hooks';

import styles from './GradesEditor.module.scss';

const numberOfStepsBeforeSettingDisciplines = 2;

const GradesEditor = ({ onClose, openSource = 'profileEdit' }) => {
  const intl = useIntl();
  const { isMobileView } = useDeviceData();
  const { themeColors } = useContext(CetComponentsContext);
  const { getGenderFormatMessage, getGenderFormatMessagePlain } = useGenderFormatMessage();
  const { sendLearningEvent } = useCetEvents();
  const { info, lastSchool, ageGrades, disciplines, disciplinesPerAgeGrades, schools } = useSelector(({ user, dimensions }) => ({
    disciplinesPerAgeGrades: dimensions.dependencies.disciplines.ageGrades,
    info: user.info,
    lastSchool: user.lastSchool,
    ageGrades: dimensions.dimensions.ageGrades,
    disciplines: dimensions.dimensions.disciplines,
    schools: user.schools
  }));
  const { endAnnualSession } = useAnnualProfileUpdate();
  const { setTeacherGradesAndDisciplines } = useUserProfile();
  const stepsRef = useRef();
  const [openingMessage, setOpeningMessage] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [stepsCount, setStepsCount] = useState(0);
  const [sortedDisciplines, setSortedDisciplines] = useState([]);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [doneMessage, setDoneMessage] = useState();

  const setCurrentStep = step => {
    if (stepsRef?.current) {
      stepsRef.current.style.transition = '';
    }
    setTimeout(() => {
      setCurrentStepIndex(step);
      setTimeout(() => {
        if (stepsRef?.current) {
          stepsRef.current.style.transition = 'none';
        }
      }, 1000);
    }, 1);
  };

  const forceFinalStep = useCallback(() => {
    setCurrentStep(stepsCount + 1);
  }, [stepsCount]);

  const handleUpdateTeacherGrades = useCallback(id => {
    let updated = [...selectedGrades];
    if (selectedGrades.find(grade => grade.ageGradeId === id)) {
      updated = updated.filter(grade => grade.ageGradeId !== id);
    } else {
      const entryIndex = parseInt(id.slice(-1), 10) - 1;
      updated.splice(entryIndex, 0, {
        ageGradeId: id,
        isClassMaster: false,
        disciplineIds: [],
        name: ageGrades.find(grade => grade.id === id).name,
        disciplines: []
      });
    }
    updated.sort((a, b) => (a.ageGradeId === b.ageGradeId ? 0 : a.ageGradeId < b.ageGradeId ? -1 : 1));
    updated.sort((a, b) => compareStrings(a.name, b.name));
    setSelectedGrades(updated);
  }, [ageGrades, selectedGrades]);

  const handleUpdateClassMaster = useCallback(id => {
    let updated = [...selectedGrades];
    if (id === '0') {
      updated = updated.map(grade => ({ ...grade, isClassMaster: false }));
    } else {
      const gradeIndex = updated.findIndex(({ ageGradeId }) => ageGradeId === id);
      const grade = { ...updated[gradeIndex] };
      if (grade) {
        grade.isClassMaster = !grade.isClassMaster;
      }
      updated.splice(gradeIndex, 1, grade);
    }
    setSelectedGrades(updated);
  }, [selectedGrades]);

  const handleUpdateDisciplineForGrade = useCallback((gradeId, discipline) => {
    const updated = [...selectedGrades];
    const gradeIndex = updated.findIndex(({ ageGradeId }) => ageGradeId === gradeId);
    const grade = { ...updated[gradeIndex] };
    const disciplines = [...grade.disciplines];
    const disciplineIndex = disciplines.findIndex(({ id }) => id === discipline.id);
    if (disciplineIndex < 0) {
      disciplines.push(discipline);
    } else {
      disciplines.splice(disciplineIndex, 1);
    }
    grade.disciplines = disciplines;
    updated.splice(gradeIndex, 1, grade);
    setSelectedGrades(updated);
  }, [selectedGrades]);

  const handleUpdateEmptyDisciplines = useCallback(() => {
    let updated = [...selectedGrades];
    const gradeNoDiscipline = updated.filter(x => x.disciplines.length === 0);
    gradeNoDiscipline.forEach(grade => {
      grade.disciplines = disciplines.filter(d => d.id === 'skills');
    });
    setSelectedGrades(updated);
  }, [disciplines, selectedGrades]);

  const sendUserUpdatedLearningEvent = useCallback(() => {
    sendLearningEvent({
      verbType: 'updated',
      objectId: info.userId,
      objectName: 'profile',
      objectType: 'user',
      optionalData: {
        interactionType: 'User',
        objectAdditionalInformation: { selectedGrades }
      }
    });
  }, [info?.userId, selectedGrades, sendLearningEvent]);

  const handleMoveToPrev = useCallback(event => {
    event.preventDefault();
    debounce(() => setCurrentStep(currentStepIndex - 1), 10)();
  }, [currentStepIndex]);

  const handleMoveToNext = useCallback(event => {
    event.preventDefault();
    setDoneMessage(null);
    if (openSource === 'annualUpdate') {
      CacheProvider.set('shouldTeacherUpdateProfile', '2');
    }
    debounce(() => setCurrentStep(currentStepIndex + 1), 10)();
  }, [currentStepIndex, openSource]);

  const handleSaveChanges = useCallback(async event => {
    CacheProvider.clearSession();
    event.preventDefault();
    setIsLoading(true);
    sendUserUpdatedLearningEvent();
    if (openSource !== 'annualUpdate') {
      setCurrentStep(currentStepIndex + 1);
    } else {
      endAnnualSession();
    }
    try {
      handleUpdateEmptyDisciplines();
      await setTeacherGradesAndDisciplines(selectedGrades);
      setDoneMessage({
        success: true,
        icon: 'Checkmark',
        message: safelyGetInnerHTML(getGenderFormatMessagePlain('update_teaching_details_end_text_1')),
        title: getGenderFormatMessagePlain('update_teaching_details_end_title')
      });
    } catch (error) {
      setIsError(true);
      setDoneMessage({
        success: false,
        icon: 'BrokenHeart',
        message: safelyGetInnerHTML(getGenderFormatMessagePlain('update_grades_disciplines_error')),
        title: getGenderFormatMessagePlain('update_grades_disciplines_error_title')
      });
    } finally {
      if (openSource === 'annualUpdate') {
        forceFinalStep();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [currentStepIndex, endAnnualSession, forceFinalStep, getGenderFormatMessage, getGenderFormatMessagePlain, handleUpdateEmptyDisciplines, openSource, selectedGrades, sendUserUpdatedLearningEvent, setTeacherGradesAndDisciplines]);

  const schoolNameElement = useMemo(() => (
    <b style={{ color: themeColors?.primary?.main }}>{lastSchool?.name}</b>
  ), [lastSchool?.name, themeColors?.primary?.main]);

  const selectedGradesDisciplinesSections = useMemo(() => selectedGrades?.
  filter(grade => !['grade13','grade14'].includes(grade.ageGradeId)).
  map(grade => (
    <section>
      <p className={styles.disciplinesTitle}>
        <div className={styles.step_body}>
          {
            safelyGetInnerHTML(
              getGenderFormatMessagePlain(schools.length > 1 ? 'update_teaching_details_s3_text_2' : 'update_teaching_details_s3_text_1')
                .replace('[grade]', renderToString(<span><b>{getGenderFormatMessagePlain(grade.ageGradeId)}</b></span>))
                .replace('[school_name]', renderToString(schoolNameElement))
              , true)
          }
        </div>
      </p>
      <div className={`${styles.elements} ${styles.disciplines}`}>
        {sortedDisciplines
          .filter(discipline => discipline.grades.includes(grade.ageGradeId))
          .map(discipline => (
            <Chip
              id={discipline.id}
              label={discipline.name}
              onClick={() => handleUpdateDisciplineForGrade(grade.ageGradeId, discipline)}
              isSelected={Boolean(grade.disciplines.find(({ id }) => id === discipline.id))}
              icon="Checkmark"
            />
          ))}
      </div>
    </section>
  )), [getGenderFormatMessagePlain, handleUpdateDisciplineForGrade, schoolNameElement, schools?.length, selectedGrades, sortedDisciplines]);

  const stepsElements = useMemo(() => ([
    <section>
      <div className={styles.openingMessage}>
        <p className={styles.step_body}>
          {safelyGetInnerHTML(openingMessage
            .replace('[school_name]', renderToString(schoolNameElement))
            , true)}
        </p>
        {openSource === 'annualUpdate' && lastSchool?.teacherAgeGrades?.length
          ? <div className={styles.annualUpdateDetails}>
            <Title level={3} color={themeColors.primary.main}>
              {safelyGetInnerHTML(getGenderFormatMessagePlain('update_teaching_details_start_text_6'))}
            </Title>
            <ul className={styles.grades}>
              {lastSchool?.teacherAgeGrades?.map(grade => (
                <li className={styles.gradeLine} key={grade.ageGradeId}>
                  <b className={styles.gradeName}>{grade.name}</b>
                  {(lastSchool?.masterClasses?.filter(masterClass => masterClass.ageGradeId === grade.ageGradeId)).length > 0 && (
                    <b>({getGenderFormatMessagePlain('update_teaching_details_class_master')}) {' '}</b>
                  )}
                  {grade.disciplines?.map(discipline => discipline.name).join(', ')}
                </li>
              ))}
            </ul>
          </div>
          : <b>{safelyGetInnerHTML(getGenderFormatMessagePlain('update_teaching_details_start_text_2'))}</b>
        }
      </div>
    </section>
    ,
    <section>
      <p>
        <div className={styles.step_body}>
          {safelyGetInnerHTML(getGenderFormatMessagePlain(schools.length > 1 ? 'update_teaching_details_s1_text_2' : 'update_teaching_details_s1_text_1')
            .replace('[school_name]', renderToString(schoolNameElement))
            , true)}
        </div>
      </p>
      <div className={styles.elements}>
        {lastSchool?.ageGrades?.length > 0 && lastSchool?.ageGrades?.map(grade => (
          <Chip
            id={grade.ageGradeId}
            label={getGenderFormatMessagePlain(grade.ageGradeId)}
            isSelected={Boolean(selectedGrades.find(selectedGrade => selectedGrade.ageGradeId === grade.ageGradeId))}
            onClick={handleUpdateTeacherGrades}
            icon="Checkmark"
          />
        ))}
        {lastSchool?.ageGrades?.length === 0 && ageGrades?.filter(g => g.id !== 'grade0').map(grade => (
          <Chip
            id={grade.id}
            label={getGenderFormatMessagePlain(grade.id)}
            isSelected={Boolean(selectedGrades.find(selectedGrade => selectedGrade.ageGradeId === grade.id))}
            onClick={handleUpdateTeacherGrades}
            icon="Checkmark"
          />
        ))}
      </div>
    </section>
    ,
    <section>
      <p>
        <div className={styles.step_body}>
          {safelyGetInnerHTML(getGenderFormatMessagePlain(schools.length > 1 ? 'update_teaching_details_s2_text_2' : 'update_teaching_details_s2_text_1')
            .replace('[school_name]', renderToString(schoolNameElement))
            , true)}
        </div>
      </p>
      <div className={styles.elements}>
        {selectedGrades?.map(grade => (
          <Chip
            id={grade.ageGradeId}
            label={getGenderFormatMessagePlain(grade.ageGradeId)}
            onClick={handleUpdateClassMaster}
            isSelected={grade.isClassMaster}
            icon="Checkmark"
          />
        ))}
        <div className={styles.elementsLine}>
          <Chip
            id="0"
            label={getGenderFormatMessagePlain('update_teaching_details_gen_no_class_master_button')}
            onClick={handleUpdateClassMaster}
            isSelected={selectedGrades.filter(grade => grade.isClassMaster).length === 0}
            icon="Checkmark"
          />
        </div>
      </div>
    </section>
    ,
    ...selectedGradesDisciplinesSections
    ,
    <section>
      {!doneMessage || isLoading
        ? <Preloader type={isMobileView ? 'fullscreen' : 'inline'} width={300} height={300} background="white" />
        : <>
          <p><b>{doneMessage?.message}</b></p>
          {!isError && <p>{safelyGetInnerHTML(getGenderFormatMessagePlain('update_teaching_details_end_text_2'))}</p>}
          {schools.length > 1 && !isError && <p className={styles.manySchoolsMessage}>{safelyGetInnerHTML(getGenderFormatMessagePlain('update_teaching_details_end_text_3'))}</p>}
        </>
      }
    </section>
  ]
  ), [ageGrades, doneMessage, getGenderFormatMessagePlain, handleUpdateClassMaster, handleUpdateTeacherGrades, isError, isLoading, isMobileView, lastSchool?.ageGrades, lastSchool?.masterClasses, lastSchool?.teacherAgeGrades, openSource, openingMessage, schoolNameElement, schools?.length, selectedGrades, selectedGradesDisciplinesSections, themeColors?.primary.main]);

  const actionButtons = useMemo(() => {
    let leftButtonLabel, rightButtonLabel, leftButtonAction, rightButtonAction, leftEventsData, rightEventsData;
    const category = schools.length > 1 ? openSource === 'annualUpdate' ? 'profileModal4' : 'profileModal2' : openSource === 'annualUpdate' ? 'profileModal3' : 'profileModal1'
    if (currentStepIndex === 0 && openSource !== 'annualUpdate') {
      rightButtonAction = onClose;
      rightButtonLabel = getGenderFormatMessagePlain('update_teaching_details_start_next_time_button');
      rightEventsData = { category, label: "nextTime" }
      leftButtonAction = handleMoveToNext;
      leftButtonLabel = <>{getGenderFormatMessagePlain('update_teaching_details_start_start_button')}<Icon name="Celebrate" width={40} /></>;
      leftEventsData = { category, label: "start" }
    }
    if (currentStepIndex === 0 && openSource === 'annualUpdate') {
      rightButtonAction = handleMoveToNext;
      rightButtonLabel = getGenderFormatMessagePlain('update_teaching_details_start_edit_mistake_button');
      rightEventsData = { category, label: "start" }
      leftButtonAction = handleSaveChanges;
      leftButtonLabel = <>{getGenderFormatMessagePlain('update_teaching_details_start_no_mistake_button')}<Icon name="ThumbsUp" width="2rem" height="1.5rem" /></>;
      leftEventsData = { category, label: "done" };
    }
    if (currentStepIndex > 0 && currentStepIndex <= stepsCount) {
      rightButtonAction = handleMoveToPrev;
      rightButtonLabel = getGenderFormatMessagePlain('update_teaching_details_gen_back_button');
      rightEventsData = { category, label: "return" + currentStepIndex, optionalData: { objectAdditionalInformation: { selectedGrades } } }
      leftButtonAction = handleMoveToNext;
      leftButtonLabel = getGenderFormatMessagePlain('update_teaching_details_gen_next_button');
      leftEventsData = { category, label: "next" + currentStepIndex, optionalData: { objectAdditionalInformation: { selectedGrades } } }
    }
    if (currentStepIndex === stepsCount) {
      leftButtonAction = handleSaveChanges;
      leftButtonLabel = getGenderFormatMessagePlain('update_teaching_details_gen_save_button');
      leftEventsData = { category, label: "save", optionalData: { objectAdditionalInformation: { selectedGrades } } }
    }
    if (currentStepIndex === stepsCount + 1 && schools?.length === 1) {
      leftButtonAction = onClose;
      leftButtonLabel = getGenderFormatMessagePlain('update_teaching_details_gen_close_button');
      leftEventsData = { category, label: "close" }
    }
    if (currentStepIndex === stepsCount + 1 && schools?.length > 1) {
      rightButtonAction = onClose;
      rightButtonLabel = getGenderFormatMessagePlain('update_teaching_details_start_next_time_button');
      rightEventsData = { category, label: "nextTime" };
      leftButtonAction = () => window.cet.accessmanagement.changeRole(window.location.href, null, intl.locale);
      leftButtonLabel = getGenderFormatMessagePlain('update_teaching_details_gen_update_button');
      leftEventsData = { category, label: "update" };
    }
    const rightButton = <Button
      view="text"
      size="medium"
      onClick={rightButtonAction}
      ariaLabel={rightButtonLabel}
      {...rightEventsData}
    >
      {rightButtonLabel}
    </Button>
    const leftButton = <Button
      view="contained"
      size="medium"
      onClick={leftButtonAction}
      isDisabled={isNextDisabled}
      ariaLabel={leftButtonLabel}
      {...leftEventsData}
    >
      {leftButtonLabel}
    </Button>
    return schools?.length === 1 && currentStepIndex === stepsCount + 1
      ? { leftButton }
      : { rightButton, leftButton }
  }, [currentStepIndex, getGenderFormatMessage, handleMoveToNext, handleMoveToPrev, handleSaveChanges, intl.locale, isNextDisabled, stepsCount, onClose, openSource, schools?.length, selectedGrades]);

  const headerTitle = useMemo(() => {
    if (currentStepIndex !== 0 && currentStepIndex !== stepsCount + 1) {
      return getGenderFormatMessagePlain('update_teaching_details_gen_title')
    }
    if (currentStepIndex === 0) {
      return <div className={styles.openingMessageTitle}>
        {getGenderFormatMessagePlain('update_teaching_details_start_title')
          .replace('[user_name]', info.firstName)}
        <Icon name="Wave" height="40" />
      </div>
    }
    if (currentStepIndex === stepsCount + 1) {
      return <div className={styles.doneMessage}>
        {doneMessage?.title}
        {!isError && <Icon name="Celebrate" height={40} width={40} />}
      </div>
    }
  }, [currentStepIndex, doneMessage?.title, getGenderFormatMessage, info.firstName, isError, stepsCount]);

  useEffect(() => {
    const sorted = disciplines.sort((a, b) => (a.name.length < b.name.length ? -1 : 1));
    sorted.forEach(discipline => {
      discipline.grades = disciplinesPerAgeGrades.filter(element => element.key === discipline.id).map(element => element.value);
    });
    sorted.sort((a, b) => compareStrings(a.name, b.name));
    setSortedDisciplines(sorted);
  }, [disciplines, disciplinesPerAgeGrades]);

  useEffect(() => {
    if (lastSchool?.teacherAgeGrades) {
      const sortedGrades = [...lastSchool?.teacherAgeGrades].sort((a, b) => compareStrings(a.name, b.name));
      setSelectedGrades(sortedGrades);
    }
  }, [lastSchool]);

  useEffect(() => {
    switch (openSource) {
      case 'profileEdit':
      case 'noTeacherAgeGrades':
        setOpeningMessage(getGenderFormatMessagePlain(schools.length > 1 ? 'update_teaching_details_start_text_3' : 'update_teaching_details_start_text_1'));
        break;
      case 'annualUpdate':
        setOpeningMessage(getGenderFormatMessagePlain(schools.length > 1 ? 'update_teaching_details_start_text_7' : 'update_teaching_details_start_text_5'));
        break;
      default:
        break;
    }
  }, [openSource, info.gender, lastSchool, getGenderFormatMessage, schools?.length]);

  useEffect(() => {
    setIsNextDisabled(false);
    if (currentStepIndex === 1 && !selectedGrades.length) {
      setIsNextDisabled(true);
    } else if (currentStepIndex > numberOfStepsBeforeSettingDisciplines) {
      const currentGrade = selectedGrades[currentStepIndex - (numberOfStepsBeforeSettingDisciplines + 1)];
      if (!currentGrade?.disciplines.length) {
        setIsNextDisabled(true);
      }
    }
    if (currentStepIndex === stepsCount + 1) {
      setIsNextDisabled(false);
    }
  }, [currentStepIndex, stepsCount, selectedGrades]);

  useEffect(() => {
    const selectedGradesCount = selectedGrades.filter(grade => !['grade13','grade14'].includes(grade.ageGradeId)).length;
    setStepsCount(numberOfStepsBeforeSettingDisciplines + selectedGradesCount);
  }, [selectedGrades?.length]);

  return (
    <Modal
      isOpen={true}
      isWithCloseButton={false}
      isCloseFromOutsideEnabled={false}
      type={isError ? "alert" : null}
    >
      {!isLoading && (
        <Modal.Header title={headerTitle} />
      )}
      <Modal.Content>
        <SliderLayout width="100%" index={currentStepIndex} items={stepsElements} />
      </Modal.Content>
      {!isLoading && (
        <Modal.Footer justifyContent={'space-between'}>
          <Modal.Footer.Section>{actionButtons.rightButton}</Modal.Footer.Section>
          <Modal.Footer.Section>{actionButtons.leftButton}</Modal.Footer.Section>
        </Modal.Footer>
      )}
    </Modal>
  );
};

GradesEditor.propTypes = {
  onClose: PropTypes.func,
  openSource: PropTypes.oneOf(['profileEdit', 'annualUpdate', 'noTeacherAgeGrades'])
};

export default GradesEditor;
