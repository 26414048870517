import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { UiContext } from '../../contexts';
import Icon from '../Icon';
import styles from './VideoPlayBtn.module.scss';
import { useGenderFormatMessage } from '../../hooks';


const VideoPlayBtn = ({videoUrl, className}) => {
  const [{}, updateUiData] = useContext(UiContext);
  const { getGenderFormatMessage } = useGenderFormatMessage();

  return <>
    {videoUrl && <button aria-label={getGenderFormatMessage('play_video')} className={`${styles.video_play_btn} ${className ? className : ''}`} onClick={() => {updateUiData({videoPlayerUrl: videoUrl})}}>
      <Icon icon="video_play" />
    </button>}
  </>;
};

VideoPlayBtn.propTypes = {
  videoUrl: PropTypes.string,
  className: PropTypes.string,
};

export default VideoPlayBtn;