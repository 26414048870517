import React from 'react';
import PropTypes from 'prop-types';
import Tag from '.'

const NewTag = ({ label, color="#ffffff", gradientFrom="#58e476", gradientTo="#49d0a3", angle="180deg", withSpout=true, className }) => <Tag label={label} className={className} withSpout={withSpout} angle={angle} color={color} gradientFrom={gradientFrom} gradientTo={gradientTo} />;

NewTag.propTypes = {
  label: PropTypes.string.isRequired
};

export default NewTag;
