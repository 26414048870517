export const useGetUserChatTokensLimits = (pageContent, userId) => {
  if(!userId) return {};

  const getUserChatTokens = async () => {
    const response = await window.cet.microservices.teachersapi.profile.getUserChatTokens({ userId });
    if (response && pageContent?.chatbot_limits) {
      const monthlyLimit = Math.max(pageContent?.chatbot_limits.max_tokens_per_month - response.monthlyTokens, 0);
      const dailyLimit = Math.max(pageContent?.chatbot_limits.max_tokens_per_day - response.dailyTokens, 0);
      return { monthlyLimit, dailyLimit };
    }
  };

  return {getUserChatTokens};
};
