import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { lo } from '../../utils/urls';
import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { loadScripts } from '../../utils/resourceLoader';
import { CacheProvider } from '../../utils/cacheProvider';
import { safelyGetInnerHTML } from '../../utils/html';

import Icon from '../Icon';
import Button from '../Button';
import Tabs from '../Tabs';
import StuckMenu from '../StuckMenu';
import Href from '../Href';
import ContentHeader from './Partials/ContentHeader';
import SubjectsList from './Partials/SubjectsList';

import styles from './Preview.module.scss';
import ReferencesList from './Partials/ReferencesList';

import { AttachedTag, NewTag } from '../Tag';

const LO_PLUGIN_URL = `${lo}/plugin/lo-plugin.js`;

const Item = ({
  itemId,
  documentId,
  language,
  activityType,
  activityTypeLabel,
  title,
  contextURL,
  type,
  banner,
  professions,
  isAllDisciplines,
  grades,
  duration,
  yearCycles,
  summary,
  syllabus,
  skills,
  itemDimensions,
  contentParent,
  pedagogicValues,
  references,
  totalNumOfQuestions,
  numOfAutomaticQuestions,
  itemTypes,
  isInFavorites,
  isMobileSupported,
  isLoItem,
  itemSource,
  isNew,
  isCourse,
  onOpenClick,
  onAttachClick,
  onShareClick,
  onFavoritesClick,
  onBannerError,
  onBannerLoad,
  hideButtons
}) => {
  const intl = useIntl();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const { search } = useLocation();

  const [isReady, setIsReady] = useState(false);
  const [isLoReady, setIsLoReady] = useState(false);
  const [isInLoPreview, setIsInLoPreview] = useState(false);
  const loContainerRef = useRef();
  const [selectedTab, setSelectedTab] = useState('summary');

  const loMarketingUrl = getGenderFormatMessage('lo_purchase_url');

  const { isAttached } = useSelector(({ user }) => {
    return {
      isAttached: Boolean(user.tasks.some(task => task.itemId === itemId))
    };
  });

  const parentBooks = contentParent?.filter(parent => parent.parentContext === 'book' && parent.additionalInfo && parent.parentName);
  parentBooks?.forEach(book => {
    book.additionalInfo = removeIllegalCharsInBookAdditionalInfo(book.additionalInfo);
  });

  const parentCourses = contentParent?.filter(parent => parent.parentContext === 'course' && parent.parentName);

  function removeIllegalCharsInBookAdditionalInfo(additionalInfo) {
    if (additionalInfo === "") {
      return additionalInfo;
    }
    let newAdditionalInfo = "";
    const indx1 = additionalInfo.indexOf('"currentChapter"');
    const indx2 = additionalInfo.indexOf('"currentPage"');
    if (indx1 >= 0 && indx2 >= 0) {
      const currentChapterTitleLength = ('"currentChapter":').length;
      const isSpaceAfterCurrentChapterTitle = additionalInfo.substring(indx1 + currentChapterTitleLength, indx1 + currentChapterTitleLength + 1) === ' ';
      const indexStartSubstring = indx1 + currentChapterTitleLength + 1;
      const indexEndSubstring = indx2 - 2;
      let currentChapterValue;
      if (isSpaceAfterCurrentChapterTitle) {
        currentChapterValue = additionalInfo.substring(indexStartSubstring + 1, indexEndSubstring - 1);
      }
      else {
        currentChapterValue = additionalInfo.substring(indexStartSubstring, indexEndSubstring);
      }
      const currentChapterNewValue = currentChapterValue.replaceAll('"', '');
      newAdditionalInfo = additionalInfo.replace(currentChapterValue, currentChapterNewValue);
    }
    return newAdditionalInfo;
  }

  const loadLoDocument = useCallback(async () => {
    const urlParams = new URLSearchParams(search);
    const isFavoriteFormOpen = urlParams.get('fav') !== null;

    if (!isFavoriteFormOpen) {
      try {
        await loadScripts([LO_PLUGIN_URL]);
        setIsReady(true);

        if (loContainerRef?.current) {
          try {
            await window.cet.lo.plugin.init(loContainerRef.current, {}, {})
          }
          catch (error) {
            console.error('LO init failed!', error);
          }
          try {
            const connection = {
              documentId,
              languageId: language
            };
            const viewSettings = {
              childDocumentId: null,
              menubarType: 'nobar',
              hideCloseButton: true,
              hideLogo: true,
              hideCreateTaskButton: true,
              hideStudioButtons: true,
              hideViewAsMenu: true,
              showLoginButton: false,
              showLogoutButton: true,
              hideShareLinks: true,
              isAdaptive: false,
              gotoParams: null
            };

            await window.cet.lo.plugin.loadDocument(connection, viewSettings);
            setIsLoReady(true);
          }
          catch (error) {
            console.error('LO loadDocument failed!', error);
          }
        }
      }
      catch (error) {
        console.error('LO loadScripts failed!', error);
      }
    }
  }, [documentId, language, search, loContainerRef?.current, isMobileView])

  useEffect(() => {
    loadLoDocument();
  }, [loadLoDocument]);

  if (isLoItem && !isReady) return null;

  if (isMobileView) {
    let tabs = [
      { value: 'summary', text: getGenderFormatMessage('preview_mobile_lo_summary'), totalCount: -1 },
      { value: 'syllabus', text: getGenderFormatMessage('preview_mobile_lo_syllabus'), totalCount: -1 }
    ];
    if (skills?.length > 0 || pedagogicValues?.length > 0) {
      tabs.push({ value: 'skills', text: getGenderFormatMessage('preview_mobile_lo_skills'), totalCount: -1 });
    }
    return (
      <div className={styles.mobileContainer} data-scrolling-handler-element="scroller">
        <div className={styles.topMarker} data-scrolling-handler-element="itemTopMarker"></div>
        <div className={`${styles.banner} ${isInLoPreview ? styles.loPreview : ''}`}>
          <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={239} />
          <div className={styles.tags}>
            {isNew && <NewTag label={getGenderFormatMessage('new')} angle="107deg" withSpout={false} />}
            {isAttached && <AttachedTag label={getGenderFormatMessage('attached')} angle="107deg" withSpout={false} />}
          </div>
        </div>
        <div className={styles.content}>
          <ContentHeader
            type={type}
            title={title}
            contextURL={contextURL}
            professions={professions}
            isAllDisciplines={isAllDisciplines}
            grades={grades}
            duration={duration}
            durationLabel={isCourse ? 'hours' : 'minutes'}
            isCourse={isCourse}
            isInFavorites={isInFavorites}
            onOpenClick={onOpenClick}
            onAttachClick={isLoItem && onAttachClick}
            onShareClick={onShareClick}
            onFavoritesClick={onFavoritesClick}
            marketingUrl={loMarketingUrl}
            itemParentCourses={parentCourses}
            hideButtons={hideButtons}
            documentId={documentId}
            itemDimensions={itemDimensions}
          />
          {yearCycles && <div className={styles.yearCycles}>{yearCycles}</div>}
          <StuckMenu
            type="item"
            stuckUpClassName={styles.stuckUpClassName}
            stuckMenuClassName={styles.stuckMenuClassName}
            layoutCenterClassName={styles.layoutCenterClassName}
            layoutTop={
              <>
                <div className={styles.tabs}>
                  <Tabs
                    tabs={tabs}
                    addAllTab={false}
                    initToCenter={true}
                    activeTabKey={selectedTab}
                    onTabClick={({ value }) => {
                      setSelectedTab(value);
                    }}
                  />
                </div>
              </>
            }
          />
          <div>
            {selectedTab === 'summary' && (
              <>
                {/* summary */}
                <div className={styles.row}>{safelyGetInnerHTML(summary)}</div>
                {/*  itemTypes */}
                <div className={styles.row}>
                  {itemTypes?.map((itemType, index) => (
                    <div key={index} className={styles.itemType}>
                      <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
                      <div>{itemType.title}</div>
                    </div>
                  ))}
                  {/* {isMobileSupported && (
                    <div className={styles.itemType}>
                      <Icon icon="mobile_compatible" title={getGenderFormatMessage('mobile_compatible')} width={22} height={22} fill="transparent" stroke="currentColor" />
                      <div>{getGenderFormatMessage('mobile_compatible')}</div>
                    </div>
                  )} */}
                </div>
                {/*  activity type, automatic evaluated questions */}
                {(activityType.startsWith('evaluation') || totalNumOfQuestions > 0) && (
                  <div className={styles.bordered}>
                    {activityType.startsWith('evaluation') && (
                      <div className={styles.activityType}>
                        <strong className={styles.label}>{getGenderFormatMessage('activity_type')}:</strong> {activityTypeLabel}
                      </div>
                    )}
                    {totalNumOfQuestions > 0 && (
                      <div className={styles.automaticQuestions}>
                        <strong className={styles.label}>{getGenderFormatMessage('automatic_evaluated_questions')}:</strong> <b>{numOfAutomaticQuestions}</b>{' '}
                        {getGenderFormatMessage('out_of')} {totalNumOfQuestions}
                      </div>
                    )}
                  </div>
                )}
                {/* references */}
                {references &&
                  Object.keys(references).map(source => {
                    return (
                      <div key={`reference_${source}`} className={`${styles.references} ${styles.margined}`}>
                        <ReferencesList
                          title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage(source)}`}
                          list={references[source]}
                          startLinesCount={references[source] ? references[source].length : 3}
                        />
                      </div>
                    );
                  })}
              </>
            )}
            {selectedTab === 'syllabus' && (
              <>
                {/* syllabus */}
                <div className={styles.bordered}>
                  <SubjectsList list={syllabus} startLinesCount={syllabus ? syllabus.length : 3} />
                </div>
              </>
            )}
            {selectedTab === 'skills' && (
              <>
                {/* skills, pedagogic values */}
                {(skills?.length > 0 || pedagogicValues?.length > 0) && (
                  <>
                    <div className={styles.bordered}>
                      <SubjectsList list={skills} startLinesCount={skills ? skills.length : 3} title={getGenderFormatMessage('skills')} />

                      {/* pedagogic values */}
                      {pedagogicValues?.length > 0 && (
                        <div className={styles.margined}>
                          <SubjectsList
                            list={pedagogicValues}
                            startLinesCount={pedagogicValues ? pedagogicValues.length : 3}
                            title={getGenderFormatMessage('pedagogic_values')}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {/* content parent(s) - mobile */}
            {contentParent && (
              <div className={styles.references}>
                {parentBooks?.length > 0 && (
                  <>
                    <strong>{getGenderFormatMessage(`parent_book`)}:</strong>
                    {parentBooks.map(({ parentName, additionalInfo }, index) => (
                      <Href key={index} target="_blank" to={JSON.parse(additionalInfo).currentPageUrl}>
                        {`${parentName},
                      ${JSON.parse(additionalInfo).currentChapter},
                      ${getGenderFormatMessage('page')} ${JSON.parse(additionalInfo).currentPage}`}
                        ;&nbsp;
                      </Href>
                    ))}
                  </>
                )}
                {parentCourses?.length > 0 && (
                  <>
                    <strong>{getGenderFormatMessage(`parent_course`)}:</strong>
                    {parentCourses.map(({ parentName, parentId }, index) => (
                      <Href key={index} target="_blank" to={`/${intl.locale}/item/${parentId}`}>
                        {parentName}
                        ;&nbsp;
                      </Href>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const getPreviewButtonClassName = () => {
    let classNameList = [styles.previewButton, 'preview_lo'];

    if (isInLoPreview) {
      classNameList.push(styles['previewButton--open']);
    }

    if (!CacheProvider.get('lo_preview_button_animation_done')) {
      classNameList.push(styles['previewButton--animate']);
    }

    return classNameList.join(' ');
  };

  return (
    <div>
      {!isInLoPreview && (
        <div className={styles.tags}>
          {isNew && <NewTag label={getGenderFormatMessage('new')} />}
          {isAttached && <AttachedTag label={getGenderFormatMessage('attached')} />}
        </div>
      )}
      {isInLoPreview && (
        <Button
          type="none"
          className={getPreviewButtonClassName()}
          onClick={() => {
            CacheProvider.set('lo_preview_button_animation_done', '1');
            setIsInLoPreview(!isInLoPreview);
          }}
          category="preview"
          label={isInLoPreview ? 'backFromQuickView' : 'quickView'}
        >
          <div>
            <Icon
              icon={'eye'}
              className={isInLoPreview ? styles.arrow : styles.eye}
              title={getGenderFormatMessage('preview_item')}
              width={isInLoPreview ? 14 : 24}
              height={isInLoPreview ? 14 : 24}
            />
            {getGenderFormatMessage('preview_item')}
          </div>
          <div>{getGenderFormatMessage('back')}</div>
        </Button>
      )}
      <div className={`${styles.banner} ${isInLoPreview ? styles.loPreview : ''}`}>
        {isLoItem && <div ref={loContainerRef} className={styles.loContainer} />}
        <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={239} />
      </div>
      <div className={styles.content}>
        <ContentHeader
          type={type}
          title={title}
          contextURL={contextURL}
          professions={professions}
          isAllDisciplines={isAllDisciplines}
          grades={grades}
          duration={duration}
          durationLabel={isCourse ? 'hours' : 'minutes'}
          isCourse={isCourse}
          summary={summary}
          isInFavorites={isInFavorites}
          onOpenClick={onOpenClick}
          onAttachClick={(isLoItem || itemSource === 'learning') && onAttachClick}
          onShareClick={onShareClick}
          onFavoritesClick={onFavoritesClick}
          documentId={documentId}
          loItemInfo={{ isLoItem, isLoReady, isInLoPreview, setIsInLoPreview }}
          marketingUrl={loMarketingUrl}
          itemParentCourses={parentCourses}
          hideButtons={hideButtons}
          itemDimensions={itemDimensions}
        />
        {/*  itemTypes */}
        <div className={styles.row}>
          {itemTypes?.map((itemType, index) => (
            <div key={index} className={styles.itemType}>
              <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
              <div>{itemType.title}</div>
            </div>
          ))}
          {/* {isMobileSupported && (
            <div className={styles.itemType}>
              <Icon icon="mobile_compatible" title={getGenderFormatMessage('mobile_compatible')} width={22} height={22} fill="transparent" stroke="currentColor" />
              <div>{getGenderFormatMessage('mobile_compatible')}</div>
            </div>
          )} */}
        </div>
        {/*  activity type, automatic evaluated questions */}
        {(activityType.startsWith('evaluation') || totalNumOfQuestions > 0) && (
          <div className={styles.bordered}>
            {activityType.startsWith('evaluation') && (
              <div className={styles.activityType}>
                <strong className={styles.label}>{getGenderFormatMessage('activity_type')}:</strong> {activityTypeLabel}
              </div>
            )}
            {totalNumOfQuestions > 0 && (
              <div className={styles.automaticQuestions}>
                <strong className={styles.label}>{getGenderFormatMessage('automatic_evaluated_questions')}:</strong> <b>{numOfAutomaticQuestions}</b>{' '}
                {getGenderFormatMessage('out_of')} {totalNumOfQuestions}
              </div>
            )}
          </div>
        )}
        {/* syllabus */}
        <div className={styles.bordered}>
          <SubjectsList list={syllabus} title={getGenderFormatMessage('syllabus_subject')} />
        </div>
        {/* skills, pedagogic values */}
        {(skills?.length > 0 || pedagogicValues?.length > 0) && (
          <>
            <div className={styles.bordered}>
              <SubjectsList list={skills} title={getGenderFormatMessage('skills')} />

              {/* pedagogic values */}
              {pedagogicValues?.length > 0 && (
                <div className={styles.margined}>
                  <SubjectsList list={pedagogicValues} title={getGenderFormatMessage('pedagogic_values')} />
                </div>
              )}
            </div>
          </>
        )}
        {/* references */}
        {references &&
          Object.keys(references).map(source => {
            return (
              <div key={`reference_${source}`} className={`${styles.references} ${styles.margined}`}>
                <ReferencesList title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage(source)}`} list={references[source]} />
              </div>
            );
          })}

        {/* content parent(s) - Desktop */}
        {contentParent && (
          <div className={styles.references}>
            {parentBooks?.length > 0 && (
              <>
                <strong>{getGenderFormatMessage(`parent_book`)}:</strong>
                {parentBooks.map(({ parentName, additionalInfo }, index) => (
                  <Href key={index} target="_blank" to={JSON.parse(additionalInfo).currentPageUrl}>
                    {`${parentName},
                      ${JSON.parse(additionalInfo).currentChapter},
                      ${getGenderFormatMessage('page')} ${JSON.parse(additionalInfo).currentPage}`}
                    ;&nbsp;
                  </Href>
                ))}
              </>
            )}
            {parentCourses?.length > 0 && (
              <>
                <strong>{getGenderFormatMessage(`parent_course`)}:</strong>
                {parentCourses.map(({ parentName, parentId }, index) => (
                  <Href key={index} target="_blank" to={`/${intl.locale}/item/${parentId}`}>
                    {parentName}
                    ;&nbsp;
                  </Href>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Item.propTypes = {
  documentId: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  activityTypeLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contextURL: PropTypes.string,
  type: PropTypes.string,
  banner: PropTypes.string.isRequired,
  professions: PropTypes.string.isRequired,
  grades: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  summary: PropTypes.string.isRequired,
  itemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  syllabus: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string,
      subSubject: PropTypes.string,
      details: PropTypes.string
    })
  ),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.string,
      sub: PropTypes.string,
      details: PropTypes.string
    })
  ),
  pedagogicValues: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.string,
      details: PropTypes.string
    })
  ),
  references: PropTypes.shape({
    books: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
      })
    ),
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
      })
    ),
    sequences: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
      })
    )
  }),
  contentParent: PropTypes.arrayOf(
    PropTypes.shape({
      parentId: PropTypes.string,
      parentContext: PropTypes.string,
      additionalInfo: PropTypes.string
    })
  ),
  totalNumOfQuestions: PropTypes.number.isRequired,
  numOfAutomaticQuestions: PropTypes.number.isRequired,
  isInFavorites: PropTypes.bool,
  isMobileSupported: PropTypes.bool,
  isLoItem: PropTypes.bool,
  isNew: PropTypes.bool,
  isCourse: PropTypes.bool,
  onOpenClick: PropTypes.func.isRequired,
  onAttachClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onFavoritesClick: PropTypes.func.isRequired,
  onBannerError: PropTypes.func
};

export default Item;
