import React from 'react';
import PropTypes from 'prop-types';
import { useCetEvents } from 'cet-components-lib/dist/hooks';
import styles from '../DropDown.module.scss';

import Icon from '../../Icon';

const SelectButton = React.forwardRef(
  (
    {
      className,
      isOpen,
      label,
      ariaLabel,
      eventsCategory,
      eventsLabel,
      iconName,
      disabled,
      onClick,
      onKeyDown,
      onKeyUp,
      children,
      onFocus,
      onBlur,
      selectedOption,
      withArrow,
      hasSelectedValue,
      id,
      mobileIconOnly,
    },
    outerRef
  ) => {
    const { sendUiUsageEvent } = useCetEvents();

    const handleOnClick = (event) => {
      if (eventsCategory && eventsLabel) {
        sendUiUsageEvent({ category: eventsCategory, label: eventsLabel })
      }
      if (onClick) {
        onClick(event);
      }
    }

    return (
      <button
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        aria-label={ariaLabel}
        title={typeof label === 'string' ? label : ariaLabel}
        ref={outerRef}
        aria-haspopup="listbox"
        className={`${styles.button} ${isOpen ? styles.open : ''} ${className || ''}`.trimEnd()}
        category={eventsCategory}
        label={eventsLabel}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onClick={handleOnClick}
        onBlur={onBlur}
        onFocus={onFocus}
        aria-expanded={isOpen}
        data-is-open={isOpen}
        data-has-selected-value={hasSelectedValue}
        id={id}
      >
        {selectedOption ? (
          children && typeof children === 'function' ? (
            children(selectedOption)
          ) : (
            <>
              {iconName && <Icon icon={iconName}  width={mobileIconOnly ? '1.5rem' : '1rem'} height={mobileIconOnly ? '1.5rem' : '1rem'} />}
              {!mobileIconOnly && <span>{selectedOption.text}</span>}
            </>
          )
        ) : (
          <>
            {iconName && <Icon icon={iconName} width="1rem" height="1rem" />}
            <span>{label}</span>
          </>
        )}
        {!mobileIconOnly && withArrow && <Icon className={styles.arrow} icon="arrow" width={12} height={12} />}
      </button>
    );
  }
);

SelectButton.propTypes = {
  /** Button label when no option is selected */
  label: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  eventsCategory: PropTypes.string,
  eventsLabel: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  /** Can be either a render props function or a unordered list element. Enables a custom option display */
  children: function (props, propName, componentName) {
    if (props.children) {
      if (typeof props.children === 'function' && !props.options) {
        return new Error(componentName + ' - When passing children as function, options isRequired');
      } else if (props.children.type !== 'ul' && !props.options) {
        return new Error(componentName + ' - Static dropdown children must be an unordered list element (ul)');
      } else if (
        props?.children?.props?.children &&
        ((props.children.props.children.length > 1 &&
          props.children.props.children.filter((li) => !li.props['data-value']).length) ||
          (props.children.props.children.length === 1 && !props.children.props.children.prop['data-value']))
      ) {
        return new Error(
          componentName + ' - All static dropdown children list items must have a "data-value" property'
        );
      }
    }
  },
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  selectedOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    text: PropTypes.string.isRequired,
  }),
  withArrow: PropTypes.bool,
  hasSelectedValue: PropTypes.bool,
  mobileIconOnly: PropTypes.bool,
};

export default SelectButton;
