import { Redirect, Route, useLocation } from "react-router-dom"

export const ProtectedRoute = ({ isAuth = true, component, path }) => {
    const location = useLocation();
    return isAuth
        ? <Route path={path} component={component} />
        : <Redirect
            to={{
                pathname: "/",
                state: { from: location }
            }}
        />
}