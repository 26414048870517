import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import styles from './CheckBox.module.scss';

/** This is a wrapper around the html input (you can pass it any of native attribute) */
const CheckBox = ({
  id,
  containerClassName,
  checkBoxName,
  labelText,
  value,
  isChecked,
  ...rest
}) => {
  const {isMobileView} = useDeviceData();
  const [inputChecked, setInputValue] = useState(() => isChecked || false);

  const handleInputChange = (event) => {
    setInputValue(event.target.checked);

    if (typeof rest?.onChange === 'function') {
      rest.onChange(event);
    }
  };

  const handlemobileClick = () => {
    const isChecked = !inputChecked;
    setInputValue(isChecked);

    if (typeof rest?.onChange === 'function') {
      rest.onChange({target: {
        value: value,
        checked: isChecked
      }});
    }
  };

  useEffect(() => {
    setInputValue(isChecked || false);
  }, [isChecked]);

  return (
    <div className={`${styles.container} ${containerClassName ? containerClassName : ''} ${inputChecked ? styles.isChecked : ''}`} onClick={isMobileView ? handlemobileClick : ()=>{}}>
      <input
        type="checkbox"
        id={checkBoxName + id}
        name={checkBoxName}
        value={value}
        checked={inputChecked}
        {...rest}
        onChange={isMobileView ? ()=>{} : handleInputChange}
      />
      <label className={`${styles.label} ${inputChecked ? styles.isChecked : ''}`} htmlFor={checkBoxName + id}>{labelText}</label>
    </div>
  );
};

CheckBox.propTypes = {
  containerClassName: PropTypes.string,
  checkBoxName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};

export default CheckBox;
