import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import errorImage from '../../img/oops_error_image.png';
import styles from './App.module.scss';

const Error = ({language = "he"}) => {
    const [layout, setLayout] = useState(0);

    useEffect(() => {
        let isActive = true;

        const initLayout = () => {
            if (isActive) {
                setLayout(window.innerWidth/window.innerHeight <= 1000/937 ? 1 : 0);
            }
        };

        window.addEventListener('resize', initLayout);
        initLayout();

        return () => {
            isActive = false;
            window.removeEventListener('resize', initLayout);
        };
    }, []);

    const errors = {
        he: <div data-lang="he"><div>משהו קרה, אנחנו על זה.</div><div>שווה לנסות שוב עוד רגע</div></div>,
        ar: <div data-lang="ar"><div>حدث أمر ما، نحن نعالج الأمر</div><div>ننصح بالمحاولة بعد لحظات</div></div>,
        en: <div data-lang="en"><div></div><div></div></div>,
    };

    return <div data-layout={layout} className={styles.errorPage}>
        <img className={styles.errorPage_image} src={errorImage} alt={''} />
        <div className={styles.errorPage_title}>{language in errors ? errors[language] : errors.he}</div>
    </div>
};

Error.propTypes = {
    language: PropTypes.string
};

export default Error;