import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useGenderFormatMessage } from '../../../hooks';
import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';
import { UiContext } from '../../../contexts';
import listStyles from '../../DataList/DataList.module.scss';
import { selectedValuePropTypes, optionsPropTypes } from '../../DataList/PropTypes';

import Button from '../../Button';
import Icon from '../../Icon';

const SelectList = React.forwardRef(
  (
    {
      className,
      options,
      selectedValue,
      focusedOptionValue,
      onSelect,
      onOptionMouseEnter,
      onOptionMouseLeave,
      label,
      withOmitSelected = false,
      children,
      isOpen,
      isMultiSelect,
      eventsCategory,
      eventsLabel,
    },
    outerRef
  ) => {
    const { getGenderFormatMessage } = useGenderFormatMessage();
    const [{ }, updateUiData] = useContext(UiContext);
    const { isMobileView } = useDeviceData();
    const mobileListRef = useRef();
    const { sendUiUsageEvent } = useCetEvents();

    const isOptionInList = (list, option) => {
      const result = Boolean(
        list &&
        list.find((value) => {
          return value.toString() === option.value.toString();
        })
      );

      return result;
    };

    const [multiSelectedOptions, setMultiSelectedOptions] = useState([]);

    useEffect(() => {
      if (isMultiSelect) {
        setMultiSelectedOptions(
          selectedValue
            ? options.filter((option) => isOptionInList(selectedValue, option)).map(({ value }) => value)
            : []
        );
      }
    }, [isMultiSelect, options, selectedValue]);

    useEffect(() => {
      if (mobileListRef && mobileListRef.current) {
        if (isOpen && isMobileView) {
          updateUiData({
            dropBoxContent: mobileListRef,
            dropBoxType: false,
            dropBoxCloseType: 'bottom'
          });
        }
        else {
          //updateUiData({
          //  //dropBoxContent: null
          //});
        }
      }
    }, [isMobileView, isOpen, mobileListRef, updateUiData]);

    const lists = useMemo(() => {
      if (options) {
        return options?.length > 0 && options[0]?.hasOwnProperty('list') ? options : [{ list: options }];
      }
    }, [options]);

    const handleOptionClick = (option) => {
      if (eventsCategory && eventsLabel && option.value) {
        sendUiUsageEvent({ category: eventsCategory, label: eventsLabel, optionalData: { fieldsResponse: { [option.prop ? option.prop : 'value']: option.value } } });
      }
      if (isMultiSelect) {
        if (isOptionInList(multiSelectedOptions, option)) {
          setMultiSelectedOptions(multiSelectedOptions.filter((value) => value.toString() !== option.value.toString()));
        } else {
          setMultiSelectedOptions([...multiSelectedOptions, option.value]);
        }
        if (isMobileView) {
          updateUiData({});
        }
      } else {
        onSelect(option);
        if (isMobileView) {
          updateUiData({dropBoxContent: null});
        }
      }
    };

    const handleMultiSelectSubmitted = () => {
      onSelect(options.filter((option) => isOptionInList(multiSelectedOptions, option)));
      if (isMobileView) {
        updateUiData({dropBoxContent: null});
      }
    };

    const handleClearSelection = () => {
      setMultiSelectedOptions([]);
      onSelect([]);
      if (isMobileView) {
        updateUiData({dropBoxContent: null});
      }
    };

    let comp = <div className={listStyles.listWrapper}>
      {lists
        ? lists.map((optionsList, index) => (
          <div ref={outerRef} key={index} name="listbox" className={listStyles.container}>
            <ul key={index} role="listbox" aria-label={label}>
              {optionsList.title && <li className={listStyles.title}>{optionsList.title}</li>}
              {optionsList?.list.map((option) => {
                if (withOmitSelected && option.value?.toString() === selectedValue?.toString()) return null;
                const optionIsSelected = isOptionInList(multiSelectedOptions, option);

                return (
                  <li
                    key={option.value}
                    role="option"
                    tabIndex={option?.isSeparator? -1 : 0}
                    data-value={option.value}
                    aria-selected={false}
                    aria-hidden={!isOpen}
                    title={option.text}
                    onClick={(event) => {
                      event.target.blur();
                      event.stopPropagation();
                      handleOptionClick(option);
                    }}
                    onMouseEnter={onOptionMouseEnter}
                    onMouseLeave={onOptionMouseLeave}
                    className={
                      `${option.value?.toString() === focusedOptionValue?.toString() ||
                        option.value?.toString() === selectedValue?.toString() ||
                        (option.value?.toString() === 'all' && !selectedValue) ||
                        (!option.value && !selectedValue) ||
                        optionIsSelected
                        ? listStyles.focused
                        : ''}
                        
                        ${option?.isSeparator? listStyles.separator : ''}`
                    }
                  >
                    {children && typeof children === 'function' ? (
                      children(option)
                    ) : isMultiSelect ? (
                      <>
                        <span
                          className={`${listStyles.checkbox} ${optionIsSelected ? listStyles.checked : ''}`}
                        >
                          <Icon icon="checkmark" width={12} height={12} />
                          <input
                            checked={optionIsSelected}
                            onChange={() => handleOptionClick(option)}
                            name={option.value}
                            id={option.value}
                            type="checkbox"
                          />
                        </span>
                        {option.text}
                      </>
                    ) : (
                      option.text
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        ))
        : children
      }
      {(isOpen || isMobileView) && isMultiSelect && (
        <div className={listStyles.saveButtonContainer}>
          <Button type="link" className={listStyles.clear} onClick={handleClearSelection} aria-label={getGenderFormatMessage('clear')}>
            {getGenderFormatMessage('clear')}
          </Button>
          <Button type="primary" size="small" className={listStyles.save} onClick={handleMultiSelectSubmitted} aria-label={getGenderFormatMessage('save')}>
            {getGenderFormatMessage('save')}
          </Button>
        </div>
      )}
    </div>;

    mobileListRef.current = <div className={`${listStyles.list} ${listStyles.visible} ${isMultiSelect ? listStyles.multiSelect : ''} ${className || ''}`}>{comp}</div>;

    return <div
      className={`${listStyles.list}
        ${lists?.length > 1 ? listStyles.columns : ''}
        ${isOpen ? listStyles.visible : listStyles.hidden} ${isMultiSelect ? listStyles.multiSelect : ''} ${className || ''}`.trimEnd()}
    >{(!isMobileView) && isOpen && comp}</div>;
  }
);

SelectList.propTypes = {
  className: PropTypes.string,
  listCategory: PropTypes.string,
  listLabel: PropTypes.string,
  options: optionsPropTypes,
  selectedValue: selectedValuePropTypes,
  focusedOptionValue: selectedValuePropTypes,
  /** A callback when an option is selected (Should update the selected value) */
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  withOmitSelected: PropTypes.bool,
  /** Can be either a render props function or a unordered list element. Enables a custom option display */
  children: function (props, propName, componentName) {
    if (props.children) {
      if (typeof props.children === 'function' && !props.options) {
        return new Error(componentName + ' - When passing children as function, options isRequired');
      } else if (props.children.type !== 'ul' && !props.options) {
        return new Error(componentName + ' - Static dropdown children must be an unordered list element (ul)');
      } else if (
        props?.children?.props?.children &&
        ((props.children.props.children.length > 1 &&
          props.children.props.children.filter((li) => !li.props['data-value']).length) ||
          (props.children.props.children.length === 1 && !props.children.props.children.prop['data-value']))
      ) {
        return new Error(
          componentName + ' - All static dropdown children list items must have a "data-value" property'
        );
      }
    }
  },
  isOpen: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
};

export default SelectList;
