import { useState, useRef, useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDashboard from '../legacyComponents/hooks/useDashboard';
import { getStudyYearDataByDate } from 'cet-components-lib/dist/utilities/studyYear';

import { useGenderFormatMessage } from './';
import { isDateInRange, addDaysToDate } from '../utils/dates';
import { updateUserDashboard } from '../state/user';

import { CacheProvider } from '../utils/cacheProvider';

export const checkIsPendingQuestion = (questionData = {}, taskStatus = '') => {
  return ['SUBMITTED', 'SERVED'].includes(taskStatus?.toUpperCase()) && questionData?.answered && questionData?.questionScore === null;
};

export const useUserDashboard = () => {
  const taskTypes = useRef({ exam: 'exam', learningWork: 'learningwork', evalWork: 'evalwork', selfLearning: 'selflearning' });

  const { userId, userRole, schoolId, userDashboard } = useSelector(({ user }) => ({
    userId: user.info?.userId?.toLowerCase(),
    userRole: user.info?.role,
    schoolId: user.info?.schoolId,
    userDashboard: user?.dashboard
  }));

  const { dashboardAPI } = useDashboard(userRole);
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [defaultTimeFilter, setDefaultTimeFilter] = useState(window.localStorage.getItem('DashboardDefaultTimeFilter') || 2);
  const dispatch = useDispatch();
  const currentStudyYearData = useRef(getStudyYearDataByDate());

  let isFetchingStudents = useRef(false);
  let isFetchingTasks = useRef(false);
  let isUpdatingTasks = useRef(false);

  const buildGroupName = useCallback(
    (studentsInGroup, studentsInfo) => {
      let groupName = '';

      if (studentsInfo && studentsInGroup?.length > 0) {
        const studentByClass = {};

        studentsInGroup.forEach(student => {
          const singleStudentInfo = studentsInfo.find(s => s.studentId === student.studentId);

          if (singleStudentInfo) {
            if (!studentByClass[singleStudentInfo.classId]) {
              studentByClass[singleStudentInfo.classId] = { className: singleStudentInfo.className, studentsNames: [singleStudentInfo.studentName] };
            } else {
              studentByClass[singleStudentInfo.classId].studentsNames.push(singleStudentInfo.studentName);
            }
          }
        });

        const classNames = Object.values(studentByClass)?.map(sc => sc.className);

        if (studentsInGroup.length > 1) {
          groupName += `${classNames.join(', ')} - ${studentsInGroup.length} ${getGenderFormatMessage('single_students_short')}`;
        } else {
          groupName += `${classNames.join(', ')} - ${Object.values(studentByClass)[0]?.studentsNames?.join(', ') ?? ''}`;
        }
      }

      return groupName;
    },
    [getGenderFormatMessage]
  );

  const splitTasksByStudentsGroups = useCallback(
    (tasks, studentsInfo) => {
      let newTasksArray = [];

      tasks.forEach(task => {
        if (task?.students?.length > 0) {
          const taskGroups = task.students.reduce((r, a) => {
            const groupId = a.groupId || '';
            r[groupId] = r[groupId] || [];
            r[groupId].push(a);
            return r;
          }, Object.create(null));

          for (let groupId in taskGroups) {
            const taskGroup = taskGroups[groupId];
            const taskGroupName = taskGroup[0]?.groupName || buildGroupName(taskGroup, studentsInfo);
            const taskGroupStudents = taskGroup.map(ts => ts.studentId);
            const studentsProfiles = studentsInfo?.filter(studentInfo => taskGroupStudents.includes(studentInfo.studentId));

            newTasksArray.push({
              ...task,
              groupId: groupId,
              groupName: taskGroupName,
              isSingleStudentsGroup: !groupId,
              students: taskGroup,
              studentsProfiles
            });
          }
        } else {
          newTasksArray.push(task);
        }
      });

      return newTasksArray;
    },
    [buildGroupName]
  );

  const saveDefaultTimeFilter = useCallback(val => {
    window.localStorage.setItem('DashboardDefaultTimeFilter', val);
    setDefaultTimeFilter(val);
  }, []);

  const fetchTeacherStudents = useCallback(async () => {
    let students = [];

    if (dashboardAPI && userRole === 'teacher') {
      isFetchingStudents.current = true;
      students = await dashboardAPI.getStudents({ teacherId: userId });
      students = students.map(student => {
        return { ...student, studentName: student.studentName.split(' ').reverse().join(' ') };
      });
      const lastUpdated = new Date().getTime();
      dispatch(updateUserDashboard({ students: { data: students, lastUpdated } }));
      isFetchingStudents.current = false;
    }

    return students;
  }, [dispatch, userId, userRole, dashboardAPI]);

  const fetchTasks = useCallback(
    async (lightTasks = false, minDate = null, maxDate = null, taskIds = null) => {
      let tasks = [];

      if (dashboardAPI) {
        isFetchingTasks.current = true;
        minDate = new Date(minDate || currentStudyYearData.current.start).toDateString();
        maxDate = new Date(maxDate || currentStudyYearData.current.end).toDateString();

        if (userRole === 'teacher') {
          if (lightTasks) {
            tasks = await dashboardAPI.getLightTasks({ teacherId: userId, schoolId, minDate, maxDate, taskIds });
          } else {
            tasks = await dashboardAPI.getTasks({ teacherId: userId, schoolId, minDate, maxDate, taskIds });
          }
        } else if (userRole === 'student') {
          tasks = await dashboardAPI.getTasks({ studentId: userId, schoolId, minDate, maxDate });
        }

        const tasksKeyVal = tasks.reduce((o, val) => {
          o[val.taskId] = val;
          return o;
        }, {});

        const reduxTasks = lightTasks ? userDashboard?.lightTasks : userDashboard?.tasks;

        let newDashboardData = {
          minDate: reduxTasks?.minDate && new Date(reduxTasks.minDate) < new Date(minDate) ? reduxTasks.minDate : minDate,
          maxDate: reduxTasks?.maxDate && new Date(reduxTasks.maxDate) > new Date(maxDate) ? reduxTasks.maxDate : maxDate
        };

        if (lightTasks) {
          newDashboardData = { lightTasks: { ...newDashboardData, data: { ...userDashboard?.lightTasks?.data, ...tasksKeyVal } } };
        } else {
          newDashboardData = { tasks: { ...newDashboardData, data: { ...userDashboard?.tasks?.data, ...tasksKeyVal } } };
        }

        dispatch(updateUserDashboard(newDashboardData));
        isFetchingTasks.current = false;
      }

      return tasks;
    },
    [dashboardAPI, dispatch, schoolId, userDashboard?.lightTasks, userDashboard?.tasks, userId, userRole]
  );

  const getTeacherStudents = useCallback(async () => {
    if (userDashboard?.students?.data) {
      return userDashboard.students.data;
    } else if (!isFetchingStudents.current) {
      const students = await fetchTeacherStudents();
      return students;
    }
    return null;
  }, [userDashboard, fetchTeacherStudents]);

  const fetchFullTask = useCallback(
    async (taskId, groupId) => {
      let groupTask = null;

      if (dashboardAPI && userRole === 'teacher' && taskId) {
        const task = await dashboardAPI.getFullTask({ teacherId: userId, taskId });

        if (task) {
          const studentsInfo = await getTeacherStudents();
          const tasksByGroups = splitTasksByStudentsGroups([task], studentsInfo) || [];
          groupTask = groupId
            ? tasksByGroups.find(task => task.groupId === groupId)
            : tasksByGroups
              ? tasksByGroups.find(task => task.isSingleStudentsGroup) ?? task
              : task;
        }
      }

      return groupTask;
    },
    [userId, userRole, dashboardAPI, getTeacherStudents, splitTasksByStudentsGroups]
  );

  const fetchSpecificTasks = useCallback(
    async taskIds => {
      let tasks = null;

      if (dashboardAPI && userRole === 'teacher' && taskIds?.length > 0) {
        tasks = await dashboardAPI.getTasksByIds({ teacherId: userId, taskIds });

        const tasksKeyVal = tasks.reduce((o, val) => {
          o[val.taskId] = val;
          return o;
        }, {});

        let newDashboardData = {};

        if (userDashboard?.tasks) {
          newDashboardData.tasks = { ...userDashboard?.tasks, data: { ...userDashboard?.tasks?.data, ...tasksKeyVal } };
        }
        if (userDashboard?.lightTasks) {
          newDashboardData.lightTasks = { ...userDashboard?.lightTasks, data: { ...userDashboard?.lightTasks?.data, ...tasksKeyVal } };
        }

        dispatch(updateUserDashboard(newDashboardData));
      }

      return tasks;
    },
    [dashboardAPI, dispatch, userDashboard?.lightTasks, userDashboard?.tasks, userId, userRole]
  );

  const addTasksToUpdate = useCallback(
    async tasksIds => {
      if (tasksIds?.length > 0) {
        const tasksToUpdate = userDashboard?.tasksToUpdate ? [...userDashboard.tasksToUpdate, ...tasksIds] : tasksIds;
        dispatch(updateUserDashboard({ tasksToUpdate }));
      }
    },
    [dispatch, userDashboard?.tasksToUpdate]
  );

  const updateTasks = useCallback(
    async tasksIds => {
      if (tasksIds?.length > 0) {
        if (!isUpdatingTasks.current) {
          isUpdatingTasks.current = true;
          const updatedTasks = await fetchSpecificTasks(tasksIds);
          const tasksToUpdate = tasksIds.filter(taskId => !updatedTasks.find(t => t.taskId === taskId));
          dispatch(updateUserDashboard({ tasksToUpdate }));
          isUpdatingTasks.current = false;
        } else {
          await new Promise(resolve => {
            setInterval(() => {
              if (!isUpdatingTasks.current) {
                resolve();
              }
            }, 50);
          });
        }
      }
    },
    [dispatch, fetchSpecificTasks]
  );

  const getLightTasks = useCallback(
    async (minDate = null, maxDate = null, splitTasksByGroups = true) => {
      let tasks = [];
      minDate = minDate ? new Date(minDate).toDateString() : null;
      maxDate = maxDate ? new Date(maxDate).toDateString() : null;

      if (
        userDashboard?.lightTasks?.data &&
        (!minDate || new Date(userDashboard.lightTasks.minDate) <= new Date(minDate)) &&
        (!maxDate || new Date(userDashboard.lightTasks.maxDate) >= new Date(maxDate))
      ) {
        if (userDashboard.tasksToUpdate?.length > 0) {
          await updateTasks(userDashboard.tasksToUpdate);
        }

        tasks = Object.values(userDashboard.lightTasks.data);

        if (minDate || maxDate) {
          tasks = tasks.filter(t => isDateInRange(t.taskDueDate, minDate || t.taskDueDate, maxDate || t.taskDueDate));
        }
      } else if (!isFetchingTasks.current) {
        tasks = await fetchTasks(true, minDate, maxDate);
      }

      return splitTasksByGroups ? splitTasksByStudentsGroups(tasks) : tasks;
    },
    [
      fetchTasks,
      splitTasksByStudentsGroups,
      updateTasks,
      userDashboard?.lightTasks?.data,
      userDashboard?.lightTasks?.maxDate,
      userDashboard?.lightTasks?.minDate,
      userDashboard?.tasksToUpdate
    ]
  );

  const getTasks = useCallback(
    async (minDate = null, maxDate = null, splitTasksByGroups = true) => {
      let tasks = [];
      minDate = minDate ? new Date(minDate).toDateString() : null;
      maxDate = maxDate ? new Date(maxDate).toDateString() : null;
      let refetchAttemptsAfterTaskDeletion = CacheProvider.get('refetchAttemptsAfterTaskDeletion') || 0;

      if (
        refetchAttemptsAfterTaskDeletion < 1 &&
        userDashboard?.tasks?.data &&
        (!minDate || new Date(userDashboard.tasks.minDate) <= new Date(minDate)) &&
        (!maxDate || new Date(userDashboard.tasks.maxDate) >= new Date(maxDate))
      ) {
        if (userDashboard.tasksToUpdate?.length > 0) {
          await updateTasks(userDashboard.tasksToUpdate);
        }

        tasks = Object.values(userDashboard.tasks.data);

        if (minDate || maxDate) {
          tasks = tasks.filter(t => isDateInRange(t.taskDueDate, minDate || t.taskDueDate, maxDate || t.taskDueDate));
        }
      } else if (!isFetchingTasks.current) {
        refetchAttemptsAfterTaskDeletion = Math.max(refetchAttemptsAfterTaskDeletion - 1, 0);
        CacheProvider.set('refetchAttemptsAfterTaskDeletion', refetchAttemptsAfterTaskDeletion);
        tasks = await fetchTasks(false, minDate, maxDate);
        const lastUpdated = new Date().getTime();
        dispatch(updateUserDashboard({ tasks: { data: tasks, lastUpdated } }));
      }

      if (splitTasksByGroups) {
        const studentsInfo = await getTeacherStudents();
        tasks = splitTasksByStudentsGroups(tasks, studentsInfo);
      }

      return tasks;
    },
    [
      dispatch,
      fetchTasks,
      getTeacherStudents,
      splitTasksByStudentsGroups,
      updateTasks,
      userDashboard?.tasks?.data,
      userDashboard?.tasks?.maxDate,
      userDashboard?.tasks?.minDate,
      userDashboard?.tasksToUpdate
    ]
  );

  const isUserHasTasksInCurrentYear = useMemo(() => {
    if (userDashboard?.tasks?.data) {
      const minDate = new Date(currentStudyYearData.current.start);
      const maxDate = new Date(currentStudyYearData.current.end);
      return Object.values(userDashboard.tasks.data).filter(t => isDateInRange(t.taskDueDate, minDate, maxDate))?.length > 0;
    }
  }, [userDashboard]);

  const calcTaskAnalytics = useCallback(task => {
    const taskAnalytics = {
      averageTaskScore: 0,
      averageTaskQuestionsScore: [],
      difficultQuestions: [],
      fullyAnsweredSubmits: 0,
      fullyAnsweredSubmitsPercent: 0,
      hasPendingQuestions: false,
      isTaskInProgress: false,
      studentsEvaluated: 0,
      studentsInGroup: 0,
      studentsInReview: 0,
      studentsInReReview: 0,
      studentsSubmitted: 0,
      studentsInProgress: 0,
      studentsSubmittedPercent: 0,
      toScore100: 0,
      toScore84: 0,
      toScore69: 0,
      toScore54: 0,
      toScorePending: 0
    };

    let studentsTaskScoresSum = 0;
    let taskQuestions = task?.questions;
    let taskQuestionsScores = new Array(taskQuestions?.length);
    let difficultQuestionsCount = new Array(taskQuestions?.length);
    let evaluationsPerQuestion = new Array(taskQuestions?.length);
    let taskType = task?.taskType;
    let isEvaluableTask = taskType.toLowerCase() !== 'learningwork';

    if (task?.students?.length > 0) {
      task.students.forEach((taskStudent, i) => {
        let unansweredCount = taskQuestions?.length;
        let isSubmitted = false;
        let isEvaluated = false;
        let studentHasPendingQuestions = false;
        const taskStatus = taskStudent.taskStatus?.toLowerCase();

        taskAnalytics.isTaskInProgress = typeof taskStatus !== 'undefined' || taskAnalytics.isTaskInProgress;

        if (taskStatus === 'submitted' || taskStatus === 'evaluated' || taskStatus === 'returned' || taskStudent.taskSubmitTrial > 1) {
          isSubmitted = true;
          taskAnalytics.studentsSubmitted++;
        }

        if (taskStatus === 'started') {
          taskAnalytics.studentsInProgress++;
        }

        if (isEvaluableTask && taskStatus === 'submitted') {
          taskAnalytics.studentsInReview++;

          if (taskStudent.taskSubmitTrial > 1) {
            taskAnalytics.studentsInReReview++;
          }
        }

        let studentQuestions = taskStudent.questions;
        isEvaluated = taskStatus === 'evaluated' || taskStatus === 'submitted' || taskStudent.taskSubmitTrial > 1;

        studentQuestions?.forEach((question, j) => {
          taskAnalytics.hasPendingQuestions = checkIsPendingQuestion(question, taskStatus) || taskAnalytics.hasPendingQuestions;
          studentHasPendingQuestions = checkIsPendingQuestion(question, taskStatus) || studentHasPendingQuestions;

          if (question?.answered || question?.ignored) {
            unansweredCount--;
          }

          if (!taskQuestionsScores[j]) {
            taskQuestionsScores[j] = 0;
          }
          if ((taskStatus === 'evaluated' || taskStatus === 'returned' || (taskStatus === 'submitted' && !checkIsPendingQuestion(question, taskStatus))) && !question?.ignored) {
            taskQuestionsScores[j] += question.questionScore;

            evaluationsPerQuestion[j] = evaluationsPerQuestion[j] || 0;
            evaluationsPerQuestion[j]++;
          }

          let shouldBeEvaluatedQuestion = (isSubmitted && (!checkIsPendingQuestion(question, taskStatus) || !question.answered)) || isEvaluated || question.questionScore > 0;
          if (question.questionScore < 55 && question.questionScore > -1 && shouldBeEvaluatedQuestion && !question?.ignored) {
            difficultQuestionsCount[j] = difficultQuestionsCount[j] || 0;
            difficultQuestionsCount[j]++;
          }

          if (j === studentQuestions.length - 1 && isSubmitted && unansweredCount === 0) {
            taskAnalytics.fullyAnsweredSubmits++;
          }
        });

        if ((taskStatus === 'submitted' && !studentHasPendingQuestions) || taskStatus === 'evaluated' || taskStatus === 'returned' || taskStudent.taskSubmitTrial > 1) {
          taskAnalytics.studentsEvaluated++;
          studentsTaskScoresSum += taskStudent.taskScore;

          if (taskStudent.taskScore <= 54) {
            taskAnalytics.toScore54++;
          } else if (taskStudent.taskScore <= 69) {
            taskAnalytics.toScore69++;
          } else if (taskStudent.taskScore <= 84) {
            taskAnalytics.toScore84++;
          } else if (taskStudent.taskScore > 0) {
            taskAnalytics.toScore100++;
          }
        } else if (isSubmitted) {
          taskAnalytics.toScorePending++;
        }

        taskAnalytics.studentsDifficulty = taskAnalytics.toScore54;
        taskAnalytics.studentsInGroup++;
      });

      const studentsCount = taskAnalytics.studentsInGroup;
      taskAnalytics.averageTaskScore = taskAnalytics.studentsEvaluated > 0 ? Number((studentsTaskScoresSum / taskAnalytics.studentsEvaluated).toFixed(1)) : null;
      taskAnalytics.studentsSubmittedPercent = Math.round((taskAnalytics.studentsSubmitted / studentsCount) * 100);
      taskAnalytics.fullyAnsweredSubmitsPercent = Math.floor((taskAnalytics.fullyAnsweredSubmits / taskAnalytics.studentsSubmitted) * 100);
      for (let i = 0; i < taskQuestionsScores.length; i++) {
        taskAnalytics.averageTaskQuestionsScore[i] = evaluationsPerQuestion[i] ? Math.floor(taskQuestionsScores[i] / evaluationsPerQuestion[i]) : -1;
      }
      if (taskAnalytics.studentsSubmitted > 3) {
        difficultQuestionsCount.forEach((questionCount, i) => {
          let percent = Math.floor((questionCount / taskAnalytics.studentsSubmitted) * 100);
          if (percent >= 25) {
            taskAnalytics.difficultQuestions.push(i + 1);
          }
        });
      }
    }

    return taskAnalytics;
  }, []);

  const isBeforeLateTask = useCallback(task => {
    if (task?.taskDueDate) {
      const nowDate = new Date();
      let numOfDaysBeforeAlert = 4;

      if (nowDate >= new Date(task.taskDueDate)) {
        return false; // Old task
      }

      if (task?.students && task.students.filter(ts => !['submitted', 'evaluated'].includes(ts.taskStatus?.toLowerCase())).length === 0) {
        return false; // All student submitted
      }

      if (isDateInRange(task.taskDueDate, nowDate, addDaysToDate(nowDate, numOfDaysBeforeAlert))) {
        return true;
      }
    }

    return false;
  }, []);

  const isDifficultTask = useCallback(task => {
    if (task.isContainsQuestions === false || task.taskType.toLowerCase() === 'learningwork') {
      return false;
    }

    if (task?.students?.length > 0) {
      const totalEvaluatedStudents = task.students?.filter(
        ts => ts.taskScore >= 0 && (['evaluated', 'submitted'].includes(ts.taskStatus?.toLowerCase()) || ts.taskSubmitTrial > 1)
      );
      const studentsWithDifficulty = totalEvaluatedStudents.filter(ts => ts.taskScore <= 54);

      if (studentsWithDifficulty.length / totalEvaluatedStudents.length >= 0.25) {
        return true;
      }
    }

    return false;
  }, []);

  const isTodoTask = useCallback(task => {
    return task.taskType.toLowerCase() !== 'learningwork' && task.students?.filter(t => t.taskStatus?.toLowerCase() === 'submitted').length > 0;
  }, []);

  const isEvaluatedTask = useCallback(task => {
    return task.isContainsQuestions && task.students?.filter(t => t.taskStatus?.toLowerCase() === 'evaluated').length > 0;
  }, []);

  if (dashboardAPI) {
    if (userRole === 'teacher') {
      return {
        taskTypes: taskTypes?.current,
        currentStudyYearData: currentStudyYearData?.current,
        defaultTimeFilter: Number(defaultTimeFilter),
        saveDefaultTimeFilter,
        getLightTasks,
        getTasks,
        getTeacherStudents,
        fetchFullTask,
        addTasksToUpdate,
        calcTaskAnalytics,
        isUserHasTasksInCurrentYear,
        isBeforeLateTask,
        isDifficultTask,
        isTodoTask,
        isEvaluatedTask,
        checkIsPendingQuestion
      };
    }
  } else if (userRole === 'admin') {
    return {
      getTasks,
      currentStudyYearData: currentStudyYearData?.current
    };
  }

  return {};
};
