export const getEnvDomain = (usingQaDomain = false) => {
    const host = window.location.hostname.toLowerCase();
    let envDomain = '.';
    if (host.includes('.testing.')) {
        envDomain = '.testing.';
        if (usingQaDomain && host.includes('-')) {
            envDomain = '-' + host.split('-')[1].replace('cet.ac.il', '');
        }
    }
    else if (host.includes('.dev.') || host.includes('localhost')) {
        envDomain = '.dev.';
    }
    return envDomain;
};

export const isFullUrl = (url) => {
  const pattern = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (pattern.test(url))
    return true;
  return false;
};

export const getQueryStringParams = (searchParams) => {
  const { search } = window.location;
  const searchStr = searchParams || search;
  if (searchStr) {
    const queryParams = {};
    const urlSearchParams = new URLSearchParams(searchStr);
    urlSearchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }

  return {};
};

export const apiGatewayUrl = window.location.hostname.includes('myofek.') && getEnvDomain() === '.'
  ? `https://nairobigateway${getEnvDomain()}cet.ac.il`
  : `https://apigateway${getEnvDomain()}cet.ac.il`;
export const ofakimApiUrl = `https://ebag${getEnvDomain()}cet.ac.il/api`;
export const cdnUrl = `https://cdn${getEnvDomain()}cet.ac.il`;
export const lo = `https://lo${getEnvDomain()}cet.ac.il`;
export const environmentUrl = `https://environment${getEnvDomain()}cet.ac.il/api`;
