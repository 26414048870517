import {useMemo, useRef} from 'react';
import {useDimensionsForSelect} from "./useDimensionsForSelect";
import {useSelector} from "react-redux";

export const useGroupOptionsByAgeGrades = (options, ageGrades, dependency) => {
  const dimensionsForSelect = useRef(['syllabusSubjects', 'syllabusSubsubjects', 'syllabusDetails', 'activityTypes, shvilimBooks', 'isAllAutomaticQuestions']);
  const { dependenciesMapper, ageGradesMapper } = useDimensionsForSelect(dimensionsForSelect.current);


  return useMemo(() => {
    const groupsMapper = {}
    const ageGradesArray = (typeof ageGrades === 'string') ? ageGrades.split(',') : ageGrades;
    options?.forEach(option => {
      const ageGradesDependenciesMapper = dependenciesMapper[dependency]?.ageGrades[option.value];
      if (ageGradesDependenciesMapper) {
        ageGradesArray?.forEach(ageGrade => {
          if (ageGradesDependenciesMapper[ageGrade]) {
            if (groupsMapper[ageGrade]?.length) {
              groupsMapper[ageGrade].push(option)
            } else {
              const label = (ageGradesMapper && ageGradesMapper[ageGrade]) ? ageGradesMapper[ageGrade].name : ageGrade
              groupsMapper[ageGrade] = [{ value: ageGrade, label, text: label, isSeparator: true }, option]
            }
          }
        })
      }
    })

    const toReturn = Object.values(groupsMapper)
      // sort object groups
      .sort((a, b) => (a[0].value > b[0].value) ? 1 : ((b[0].value > a[0].value) ? -1 : 0))
      // flatten array
      .reduce((acc, ageGrade) => acc.concat(...ageGrade
          // sort group options
          .sort((a, b) => (a.isSeparator || b.isSeparator) ? 2 : (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)))
        , [])
    const allOption = options.find(option => option.value === 'all') ?? null
    return allOption ? [allOption, ...toReturn] : toReturn;
  }, [ageGrades, options, dependency, dependenciesMapper, ageGradesMapper]);
};
