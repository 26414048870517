import { useEffect, useState } from 'react';
import { callWithCache } from '../utils/callWithCache';

let cachedResponses;

export const useFilterItemTypes = () => {
  const [filterItemTypes, setFilterItemTypes] = useState({});

  useEffect(() => {
    if (!cachedResponses) {
      callWithCache(window.cet.microservices.nairobiapi.filters.getFiltersItemTypes, 'getFiltersItemTypes', null).then(response => {
        cachedResponses = response;
        setFilterItemTypes(response);
      });
    } else {
      setFilterItemTypes(cachedResponses);
    }
  }, []);

  return filterItemTypes;
};
