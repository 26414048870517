import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDeviceData, useLabels } from 'cet-components-lib/hooks';
import { A } from 'cet-components-lib/UI/A';
import { Icon } from 'cet-components-lib/UI/Icon';
import useStyles from '../SideBar.styles';
import { labels } from '../SideBar.labels';

import teachersCenterShvilimHe from './teachersCenterShvilimHe.png';
import teachersCenterShvilimAr from './teachersCenterShvilimAr.png';
import teachersCenterKesem from './teachersCenterKesem.png';

export const SideBarListItem = itemProps => {
  const intl = useIntl();
  const getLabel = useLabels({ labels });
  const { item, isMoreMenuItem = false, onItemClick, isMinimized, handleAccordionOpen } = itemProps;
  const { id, label, isSelected, isNewItem, isMoreMenuItemOpen, withTopSeparator, href, target, icon, onClick, items = [], expandOnHover, children = [] } = item;
  const { isMobileView } = useDeviceData();
  const hasItems = items && items.length > 0;
  const hasChildren = children && children.length > 0;
  const [isOpen, setIsOpen] = useState(isMoreMenuItemOpen !== undefined ? isMoreMenuItemOpen : isMobileView ? true : false);

  const styles = useStyles(!!isSelected, isOpen, isMoreMenuItem, withTopSeparator, isNewItem);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const sidebarRef = useRef(null);
  const isAccordionOpenRef = useRef(false);

  const handleAccordionState = useCallback(
    open => {
      if (typeof handleAccordionOpen === 'function' && !isMobileView) {
        handleAccordionOpen(open);
      }
      isAccordionOpenRef.current = open;
    },
    [handleAccordionOpen, isMobileView]
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (!isMobileView && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (isAccordionOpenRef.current) {
          setIsOpen(false);
          setIsMenuVisible(false);
          handleAccordionState(false);
        }
      }
    };

    const handleFocusOutside = event => {
      if (!isMobileView && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (isAccordionOpenRef.current) {
          setIsOpen(false);
          setIsMenuVisible(false);
          handleAccordionState(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', handleFocusOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', handleFocusOutside);
    };
  }, [isMobileView, handleAccordionState]);

  // toggle menu on click
  const toggleMenu = e => {
    e.preventDefault();
    !isMobileView && setIsOpen(isOpen => !isOpen);
  };

  //close menu when item is clicked
  const handleItemClick = item => {
    if (!isMobileView) {
      setIsMenuVisible(false);
      onItemClick && onItemClick(item.expandOnHover);
      handleAccordionState(false);
    }
    setIsOpen(false);
  };

  const handleOnClick = e => {
    if (hasItems || hasChildren) {
      const shouldOpenAccordion = !isOpen && (hasItems || hasChildren);
      const newshouldOpenAccordionState = shouldOpenAccordion ? true : false;
      toggleMenu(e);
      !isMobileView && setIsMenuVisible(true);
      handleAccordionState(newshouldOpenAccordionState);
    } else {
      onClick && onClick(e);
      onItemClick && onItemClick(!isMobileView ? expandOnHover : e);
      handleAccordionState(false);
    }
  };

  const handleCombinedClick = item => e => {
    handleItemClick(item);
    if (item.onClick) {
      item.onClick(e);
    }
  };

  const handleOnFocus = e => {
    if (!isMobileView && (hasItems || hasChildren || isMinimized) && e.target === e.currentTarget) {
      setIsOpen(true);
      setIsMenuVisible(true);
      handleAccordionState(true);
    }
  };

  const role = hasItems || hasChildren ? 'button' : undefined;
  const ariaControls = hasItems || hasChildren ? `cc-SideBar__menu__item__accordion-${id}` : undefined;

  return (
    <li key={id} className={styles['cc-SideBar__menu__item']} ref={sidebarRef} onFocus={handleOnFocus} tabIndex={0}>
      {
        <A
          isBtnView={true}
          view="alternate"
          color="basic"
          size={isMobileView ? 'small' : 'medium'}
          shape={isMobileView && !isMoreMenuItem ? 'rectangle' : 'roundedRectangle'}
          onClick={e => handleOnClick(e)}
          to={href ?? ''}
          target={target}
          id={id}
          role={role}
          ariaControls={ariaControls}
          ariaExpanded={((hasItems || hasChildren) && isOpen) || undefined}
          customStyle={styles['cc-SideBar__menu__item__anchor']()}
        >
          <div className={styles['cc-SideBar__menu__item__anchor-content']}>
            <div className={styles['cc-SideBar__menu__item__icon']}>{icon && <Icon name={icon}></Icon>}</div>
            <div className={styles['cc-SideBar__menu__item__label']}>{label}</div>
            {isNewItem && <div className={styles['cc-SideBar__menu__item__new-item']}>{getLabel('newItemLabel')}</div>}
            {(hasItems || hasChildren) && !isMobileView && (
              <span className={styles['cc-SideBar__menu__item__arrow']}>
                <Icon name="SidebarLeftArrow" />
              </span>
            )}
          </div>
        </A>
      }
      {(hasItems || hasChildren) && (
        <div role="region" aria-label={label} id={ariaControls} className={isOpen && isMenuVisible && !isMobileView ? styles['cc-SideBar__menu__item__inner-accordion'] : ''}>
          {isMenuVisible ? (
            <ul key={`ul-${id}`} className={styles['cc-SideBar__menu__item__inner-list']}>
              {items.map((item, i) => {
                const itemProps = { item, isMoreMenuItem, onItemClick: () => handleItemClick(item) };
                return <SideBarListItem key={i} {...itemProps} />;
              })}
              {children && children.length > 0 && !isMobileView && (
                <ul className={styles['cc-SideBar__menu__item__children-list']}>
                  {children.map((child, i) => (
                    <li key={i}>
                      <div className={`${i > 0 ? styles['cc-SideBar__menu__item__children-list-borderTop'] : ''}`}></div>
                      <div className={styles['cc-SideBar__menu__item__children-list-title']}>
                        <span className={styles['cc-SideBar__menu__item__children-list-icon']}>
                          <Icon name="Directions" />
                        </span>
                        {child.data.length > 0 ? child.title : ''}
                      </div>
                      <ul className={styles['cc-SideBar__menu__item__children-list']}>
                        {child.data.length > 0
                          ? child.data.map((item, j) => (
                              <li key={j} className={styles['cc-SideBar__menu__item__inner-list-item']}>
                                {child.name === 'recommendedSites' ? (
                                  <A to={item.link} target={item.link_target} id={item.name}>
                                    <img src={item.image} alt={item.name} className={styles['cc-SideBar__menu__item__image']} />
                                  </A>
                                ) : child.name === 'teachersCenter' ? (
                                  <A to={''} onClick={handleCombinedClick(item)} id={item.id}>
                                    <img
                                      src={
                                        item.name === 'teachersCenterShvilim'
                                          ? intl.locale === 'he'
                                            ? teachersCenterShvilimHe
                                            : teachersCenterShvilimAr
                                          : teachersCenterKesem
                                      }
                                      alt={item.label}
                                      className={styles['cc-SideBar__menu__item__image']}
                                    />
                                  </A>
                                ) : null}
                              </li>
                            ))
                          : null}
                      </ul>
                    </li>
                  ))}
                </ul>
              )}
            </ul>
          ) : (
            <ul key={`ul-${id}`} className={styles['cc-SideBar__menu__item__inner-list']}>
              {items.map((item, i) => {
                const itemProps = { item, isMoreMenuItem, onItemClick: onItemClick };
                return <SideBarListItem key={i} {...itemProps} />;
              })}
            </ul>
          )}
        </div>
      )}
    </li>
  );
};
