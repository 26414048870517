import React from 'react';
import PropTypes from 'prop-types';
import Tag from '.'

const AttachedTag = ({ label, className, color="#ffffff", gradientFrom="#fc882e", gradientTo="#f8bb2b", angle="180deg", withSpout=true }) => <Tag angle={angle} className={className} withSpout={withSpout} label={label} color={color} gradientFrom={gradientFrom} gradientTo={gradientTo} />;

AttachedTag.propTypes = {
  label: PropTypes.string.isRequired
};

export default AttachedTag;
