import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageKeys, usePage } from '.';

function useRecommendedSites() {
  const { pageContent } = usePage(pageKeys.tools);
  const [recommendedSites, setRecommendedSites] = useState([]);

  const { userInfo, lastSchool } = useSelector(({ user }) => ({
    userInfo: user.info,
    lastSchool: user.lastSchool
  }));

  const getDisciplines = useCallback(() => {
    let disciplinesArr = [];
    let disciplinesAgeGradesMapper = {};

    if (lastSchool?.teacherAgeGrades.length > 0) {
      lastSchool.teacherAgeGrades.map(ageGrade =>
        ageGrade.disciplines?.forEach(discipline => {
          if (!disciplinesAgeGradesMapper[discipline.id]) {
            disciplinesAgeGradesMapper[discipline.id] = {};
          }
          disciplinesAgeGradesMapper[discipline.id][ageGrade.ageGradeId] = true;

          if (disciplinesArr.indexOf(discipline.id) === -1) {
            disciplinesArr.push(discipline.id);
          }
        })
      );
      return disciplinesArr;
    } else {
      return null;
    }
  }, [lastSchool?.teacherAgeGrades]);

  useEffect(() => {
    if (pageContent?.recommended_sites?.items.length) {
      const publishedSites = pageContent.recommended_sites.items.filter(site => site.isPublish);

      if (!userInfo?.userId) {
        setRecommendedSites(publishedSites);
      } else {
        const disciplines = getDisciplines();
        //If logged-in teacher: display relevant tools first - by teacher's disciplines, then the rest:
        if (disciplines) {
          setRecommendedSites(publishedSites.sort(x => (x.disciplines.some(d => disciplines.includes(d)) ? -1 : 1)));
        } else {
          setRecommendedSites(publishedSites);
        }
      }
    }
  }, [getDisciplines, pageContent, userInfo?.userId]);

  return { recommendedSites };
}

export default useRecommendedSites;
