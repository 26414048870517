import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import Controlled from './Controlled';

const Tooltip = ({ children, content, position = 'top', isInteractive, offset, className }) => {
  const { isMobileView } = useDeviceData();
  const [isActive, setIsActive] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const animationTimeoutRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    if (isOver) {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
      animationTimeoutRef.current = null;
      setIsActive(true);
    }
    else if (isInteractive) {
      if (!animationTimeoutRef.current) {
        animationTimeoutRef.current = setTimeout(() => {
          if (isMounted) {
            animationTimeoutRef.current = null;
            setIsActive(false);
          }
        }, 150);
      }
    }
    else {
      animationTimeoutRef.current = null;
      setIsActive(false);
    }

    return () => {
      isMounted = false;
    };
  }, [isInteractive, isOver]);

  return (
    <Controlled
      position={position}
      content={content}
      isActive={isActive && (isInteractive || !isMobileView)}
      onMouseLeave={()=>{setIsOver(false)}}
      onMouseOver={()=>{setIsOver(true)}}
      offset={offset}
      className={className || ''}
    >
      {children}
    </Controlled>
  );
};

Tooltip.propTypes = {
  /** When true, the tooltip will stay open while user is over it */
  isInteractive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
  /** The number of pixels to add to the tooltip offset left */
  offset: PropTypes.number,
  className: PropTypes.string
};

export default Tooltip;

export const ControlledTip = Controlled;
