import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearch } from './';
import { useSelector } from 'react-redux';
import { callWithCache } from '../utils/callWithCache';

const getNewestItems =
  (intl, dispatchSearch, numResults = 10, lastSchool, isExtendedCatalog) =>
  async () => {
    const getAgeGrades = () => {
      if (lastSchool?.teacherAgeGrades?.length > 0) {
        return lastSchool.teacherAgeGrades.map(ageGrade => ageGrade.ageGradeId);
      } else {
        return null;
      }
    };

    const getDisciplines = () => {
      let disciplinesArr = [];
      let disciplinesAgeGradesMapper = {};

      if (lastSchool?.teacherAgeGrades?.length > 0) {
        lastSchool.teacherAgeGrades.map(ageGrade =>
          ageGrade.disciplines?.forEach(discipline => {
            if (!disciplinesAgeGradesMapper[discipline.id]) {
              disciplinesAgeGradesMapper[discipline.id] = {};
            }
            disciplinesAgeGradesMapper[discipline.id][ageGrade.ageGradeId] = true;

            if (disciplinesArr.indexOf(discipline.id) === -1) {
              disciplinesArr.push(discipline.id);
            }
          })
        );
        return disciplinesArr;
      } else {
        return null;
      }
    };

    const ageGrades = getAgeGrades();
    const disciplines = getDisciplines();

    let result = [];

    if (!lastSchool || !ageGrades || ageGrades === [] || !disciplines || disciplines === []) {
      await callWithCache(window.cet.microservices.contentapi.content.getNewestItemsIds, 'getNewestItemsIds', { numResults })
        .then(async data => {
          result = data ? await dispatchSearch([{ id: data }], null, numResults) : [];
        })
        .catch(error => {
          console.error('error getting results from getNewestItemsIds: ' + error);
        });
    } else {
      await callWithCache(window.cet.microservices.contentapi.content.getNewestItemsIds, 'getNewestItemsIds', {
        numResults: numResults,
        dimensionParams: {
          ageGrades: ageGrades, // e.g. ['grade1', 'grade9'],
          disciplines: disciplines, // e.g. ['math','skills']
          sectors: isExtendedCatalog ? ['state', 'mmad', 'arab'] : lastSchool?.sectorId ? [lastSchool.sectorId] : intl.locale === 'ar' ? ['arab'] : ['state', 'mmad']
        }
      })
        .then(async data => {
          result = data ? await dispatchSearch([{ id: data }], null, numResults) : [];
        })
        .catch(error => {
          console.error('error getting results from getNewestItemsIds: ' + error);
        });
    }

    if (result?.length) {
      const items = result[0];
      items.title = intl.formatMessage({ id: 'newest_items' });
      if (items.data && items.data.length > numResults) {
        items.data = items.data.slice(0, numResults);
      }
      items.totalCount = items.data ? items.data.length : 0;
      return items;
    }

    return null;
  };

export const useNewestItems = (numResults = 10) => {
  const intl = useIntl();
  const dispatchSearch = useSearch();

  const { lastSchool, isExtendedCatalog = false } = useSelector(({ user }) => ({
    lastSchool: user.lastSchool,
    isExtendedCatalog: user.info?.isExtendedCatalog
  }));

  return useMemo(() => getNewestItems(intl, dispatchSearch, numResults, lastSchool, isExtendedCatalog), [intl, dispatchSearch, numResults, lastSchool, isExtendedCatalog]);
};
