import { CacheProvider } from './cacheProvider';

export const callWithCache = (functionToCall, prefix, params) => {
  const localCacheKey = CacheProvider.generateKey(prefix, params ?? '');
  return new Promise(async (resolve, reject) => {
    const cached = CacheProvider.get(localCacheKey);
    if (cached) {
      // console.log('callWithCache', prefix, cached);
      resolve(cached);
    } else {
      try {
        const result = await functionToCall(params);
        CacheProvider.set(localCacheKey, result);
        resolve(result);
      } catch(ex) {
        if (ex?.status === 401 || ex?.detail?.status === 401) {
          window.cet?.accessmanagement?.getSessionAsync().then(session => {
            if (!session) {
              window.cet?.accessmanagement?.logout();
            }
          });
        }
        reject(ex);
      }      
    }
  });
};
