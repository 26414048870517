export const addDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const calcDatesDiff = (date1, date2) => {
    const inputDate1 = new Date(date1);
    const inputDate2 = new Date(date2);

    var datesDiff = inputDate2 - inputDate1;

    if (!isNaN(datesDiff)) {
        const mSecondsDiff= Math.floor(datesDiff);
        const secondsDiff = Math.floor(mSecondsDiff/ 1000);
        const minutesDiff = Math.floor(secondsDiff / 60);
        const hoursDiff   = Math.floor(minutesDiff / 60);
        const daysDiff    = Math.floor(hoursDiff   / 24);
        const weeksDiff   = Math.floor(daysDiff    / 7);
        const yearsDiff   = Math.floor(daysDiff    / 365);

        return {
            'milliseconds': mSecondsDiff,
            'seconds': secondsDiff,
            'minutes': minutesDiff,
            'hours': hoursDiff,
            'days': daysDiff,
            'weeks': weeksDiff,
            'years': yearsDiff
        };
    }

    return null;
}

export const isDateInRange = (date, minDate, maxDate) => {
    const inputDate = new Date(date);
    const minDateRange = new Date(minDate);
    const maxDateRange = new Date(maxDate);

    if (typeof minDate === 'string' && !minDate.includes(':')) {
        minDateRange.setHours(0, 0, 0);
    }

    if (typeof maxDate === 'string' && !maxDate.includes(':')) {
        maxDateRange.setHours(23, 59, 59);
    }

    return minDateRange <= inputDate && inputDate <= maxDateRange;
}

export const dateToString = (_date, getDate, getTime) => {
    let date = _date;

    const dayStr = () => {
        let day = date.getDate();
        day = day < 10 ? '0' + day : '' + day;
        let month = date.getMonth() + 1;
        month = month < 10 ? '0' + month : '' + month;
        let year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const timeStr = () => {
        let hours = date.getHours();
        hours = hours < 10 ? '0' + hours : '' + hours;
        let minutes = date.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : '' + minutes;

        return `${hours}:${minutes}`;
    };

    if (!date) {
        return '';
    }

    if (typeof (date) === 'number' || typeof (date) === 'string') {
        date = new Date(date);
    }

    if (getDate && getTime) {
        return `${dayStr()} ${timeStr()}`;
    }
    if (getDate) {
        return dayStr();
    }
    if (getTime) {
        return timeStr();
    }
};
/**
 *
 * @returns {string} - current date in format dd-mm-yy
 */
export const getCurrentDate = () => {
    const date = new Date();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().substr(-2);
    return day + "-" + month + "-" + year;
}