import { nairobiGateway, apiGatewayUrl, thumbnailsStorage, getEnvDomain } from './urls';

const bodyElement = document.querySelector('body');
const envDomain = getEnvDomain();

// Testing-->apiGateway ; Prod-->nairobiGateway
const externalServiceGateway = envDomain === '.' ? nairobiGateway : apiGatewayUrl;

const defaultScripts = [
  `${nairobiGateway}/nairobiapi/provider/nairobiprovider.js`,
  `${nairobiGateway}/contentapi/provider/ContentProvider.js`,
  `${nairobiGateway}/teachersapi/provider/teachersprovider.js`,
  `${nairobiGateway}/nairobirecommendationsapi/provider/RecommendationsProvider.js`,
  `${externalServiceGateway}/accessmngapi/provider/accessmanagement.js`
];
export const extrinsicScript = [
  `${externalServiceGateway}/bigdataapi/provider/BigDataProvider.js`,
  `${nairobiGateway}/chatbotapi/provider/chatapiprovider.js`
];

export const loadScripts = async (scripts = defaultScripts) => {
  return new Promise((resolve, reject) => {
    let scriptLoadedCount = 0;
    if (scripts) {
      function checkCounter() {
        if (scriptLoadedCount === scripts.length) {
          resolve();
        }
      }

      scripts.forEach(script => {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';

        scriptTag.onerror = error => {
          scriptLoadedCount++;
          checkCounter();
        };
        scriptTag.onload = () => {
          scriptLoadedCount++;
          checkCounter();
        };

        const scriptId = btoa(script);
        if (document.getElementById(scriptId)) {
          scriptLoadedCount++;
          checkCounter();
        } else {
          scriptTag.src = script;
          scriptTag.id = scriptId;
        }

        bodyElement.appendChild(scriptTag);
      });
    } else {
      resolve();
    }
  });
};

export const getImageUrl = (id, width = 220, height = 186, getStorageUrl = true) => {
  if (id?.includes('/')) {
    return id; // given image id is already an URL
  }

  if (width <= 400) {
    width = 220;
    height = 186;
  } else {
    width = 666;
    height = 239;
  }

  return id
    ? getStorageUrl
      ? `${thumbnailsStorage}${id}/w_${width}_h_${height}.png`
      : window.cet.microservices.contentapi.thumbnails.getImageUrl({ id, width, height })
    : '';
};
