export const compareStrings = (a, b) => {
    return a?.localeCompare(b, ['he', 'ar'], { sensitivity: 'base' });
};

// add ordinal suffix to numbers (1st, 2nd, 3rd, 4th, etc.)
export const addOrdinalSuffix = (i) => {
    if (i == 0) return '';
    if (i % 100 >= 11 && i % 100 <= 13) return i + 'th';
    switch (i % 10) {
        case 1:
            return i + 'st';
        case 2:
            return i + 'nd';
        case 3:
            return i + 'rd';
        default:
            return i + 'th';
    }
}
