import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './SnackbarMessage.module.scss';

const SnackbarMessage = ({
  containerClassName,
  contentText,
  timeout,
  onTimeout,
}) => {

  const [onFade, setOnFade] = useState(true);
  const [showText, setShowText] = useState('');

  useEffect(() => {
    let timer = false;
    let startTimer = timeout && timeout > 0 && contentText;
    setOnFade(!startTimer);
    if (startTimer) {
      timer = setTimeout(() => {
        setOnFade(true);
        if (onTimeout) {
          onTimeout();
        }
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [contentText, onTimeout, timeout]);

  useEffect(() => {
    if (contentText) {
      setShowText(contentText);
    }
  }, [contentText])

  return (
    <div className={`${styles.container} ${containerClassName} ${onFade? styles.fadeOut : ''}`}>
      <p>{showText}</p>
    </div>
  );
};

SnackbarMessage.propTypes = {
  containerClassName: PropTypes.string,
  contentText: PropTypes.string.isRequired,
  timeout: PropTypes.number,
};

export default SnackbarMessage;