const types = {
  setLoginData: 'setLoginData',
};

const initialState = {
  login: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setLoginData:
      return { ...state, login: {...(action.loginData)} };
    default:
      return state;
  }
};

export const setLoginData = (loginData) => ({
  type: types.setLoginData,
  loginData
});

export default reducer;
