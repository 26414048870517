import { CacheProvider } from '../utils/cacheProvider';

const types = {
  setUserData: 'setUserData',
  updateUserBasicProfileInfo: 'updateUserBasicProfileInfo',
  updateUserFavorites: 'updateUserFavorites',
  setUserBooks: 'setUserBooks',
  setUserCourses: 'setUserCourses',
  setUserActivities: 'setUserActivities',
  updateUserLastSchool: 'updateUserLastSchool',
  updateLastSchoolData: 'updateLastSchoolData',
  removeBookFromMyBooks: 'removeBookFromMyBooks',
  updateActivity: 'updateActivity',
  updateProductSubscriptions: 'updateProductSubscriptions',
  setPersonalBanners: 'setPersonalBanners',
  setSchoolYear: 'setSchoolYear',
  updateDashboard: 'updateDashboard',
  setUILanguage: 'setUILanguage',
  setUserTasks: 'setUserTasks'
};

const initialState = {
  info: null,
  schools: [],
  lastSchool: null,
  favorites: [],
  courses: [],
  books: [],
  isBooksInit: false,
  booksActivities: [],
  activities: [],
  isActivitiesInit: false,
  productSubscriptions: [],
  personalBanners: [],
  schoolYear: null,
  dashboard: null,
  tasks: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setUserData: {
      const info = { ...action.info };
      const favorites = info.favorites ? { ...info.favorites } : info.userId ? state.favorites : [];
      const schools = info.schools ? [...info.schools] : [];
      const lastSchool = info.lastSchool ? { ...info.lastSchool } : null;

      delete info.favorites;
      delete info.schools;
      delete info.lastSchoolId;
      delete info.lastSchool;

      return { ...state, info: info, favorites: favorites, schools: schools, lastSchool: lastSchool };
    }
    case types.updateUserBasicProfileInfo: {
      return { ...state, info: action.info };
    }
    case types.updateUserLastSchool: {
      return { ...state, lastSchool: action.school };
    }
    case types.updateLastSchoolData: {
      const { schools } = state;
      const updatedSchools = schools.map(school => {
        if (school.schoolId === action.updatedSchool.schoolId) {
          return action.updatedSchool;
        }

        return school;
      });

      return { ...state, schools: updatedSchools, lastSchool: action.updatedSchool };
    }
    case types.updateUserFavorites:
      return { ...state, favorites: action.favorites };
    case types.setUserBooks:
     return { ...state, books: action.books, isBooksInit: state.isActivitiesInit };
    case types.setUserCourses:
      return { ...state, courses: action.courses };
    case types.setUserActivities:
      const booksActivities = action.activities && action.activities.length > 0 ? action.activities.filter(activity => activity.activityType === 'book') : [];
      return { ...state, activities: action.activities, booksActivities, isActivitiesInit: true };
    case types.updateActivity: {
      const activityIndex = state.activities.findIndex(activity => activity.externalId === action.activity.externalId);

      let activities;
      if (activityIndex > -1) {
        activities = [...state.activities.slice(0, activityIndex), ...state.activities.slice(activityIndex + 1)];
      } else {
        activities = [...state.activities];
      }

      activities.push(action.activity);

      let booksActivities = state.booksActivities;
      const activityBooksIndex = booksActivities.findIndex(activity => activity.externalId === action.activity.externalId);
      if (activityBooksIndex > -1 || action.activity.activityType === 'book') {
        booksActivities = activities && activities.length > 0 ? activities.filter(activity => activity.activityType === 'book') : [];
      }

      return { ...state, activities, booksActivities };
    }
    case types.updateProductSubscriptions: {
      return { ...state, productSubscriptions: action.productSubscriptions };
    }
    case types.setPersonalBanners: {
      CacheProvider.set('randomTextImageBannerMapper', '{}');
      return { ...state, personalBanners: action.personalBanners };
    }
    case types.removeBookFromMyBooks:
      const { books } = state;
      let updated = null;

      if (books) {
        updated = { ...books[0] };
        updated.data = updated.data.filter(book => book.externalContextId !== action.externalId);
        updated.totalCount = updated.data.length;

        if (updated.totalCount === 0) {
          updated = null;
        }
      }

      return { ...state, books: [updated] };
    case types.setSchoolYear: {
      return { ...state, schoolYear: action.schoolYear };
    }
    case types.updateDashboard: {
      return { ...state, dashboard: { ...state.dashboard, ...action.dashboardData } };
    }
    case types.setUILanguage: {
      return { ...state, info: { ...state?.info, lastUILanguage: action.lastUILanguage}  }
    }
    case types.setUserTasks: {
      return { ...state, tasks: action.tasks };
    }
    default:
      return state;
  }
};

export const setUserData = info => ({ type: types.setUserData, info });
export const updateUserBasicProfileInfo = info => ({ type: types.updateUserBasicProfileInfo, info });
export const updateUserExProfileInfo = info => ({ type: types.updateUserBasicProfileInfo, info });
export const updateUserIsExtendedCatalog = info => ({ type: types.updateUserBasicProfileInfo, info });
export const updateUserFavorites = favorites => ({ type: types.updateUserFavorites, favorites });
export const setUserBooks = books => ({ type: types.setUserBooks, books });
export const setUserCourses = courses => ({ type: types.setUserCourses, courses });
export const setUserActivities = activities => ({ type: types.setUserActivities, activities });
export const setUILanguage = lastUILanguage => ({ type: types.setUILanguage, lastUILanguage });
export const updateActivity = activity => ({ type: types.updateActivity, activity });
export const removeBookFromMyBooks = externalId => ({ type: types.removeBookFromMyBooks, externalId });
export const updateUserLastSchool = school => ({ type: types.updateUserLastSchool, school });
export const updateLastSchoolData = updatedSchool => ({ type: types.updateLastSchoolData, updatedSchool });
export const updateProductSubscriptions = productSubscriptions => ({ type: types.updateProductSubscriptions, productSubscriptions });
export const setPersonalBanners = personalBanners => ({ type: types.setPersonalBanners, personalBanners });
export const setSchoolYear = schoolYear => ({ type: types.setSchoolYear, schoolYear });
export const updateUserDashboard = dashboardData => ({ type: types.updateDashboard, dashboardData });
export const setUserTasks = tasks => ({ type: types.setUserTasks, tasks });
export default reducer;
