import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import styles from './HorizontalSwipeLayout.module.scss';

let touchstartX = 0;
let touchstartY = 0;
let touchendX = 0;
let touchendY = 0;

const HorizontalSwipeLayout = ({index, slides, className, slideClassName, startHeight=0, onSwipeRight=null, onSwipeLeft=null}) => {
  const {isMobile} = useDeviceData();
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [isAnimate, setIsAnimate] = useState(false);
  const [height, setHeight] = useState(0);
  const horizontalSwipeLayoutRef = useRef();


  useEffect(() => {
    let isActive = true;
    let isOn = false;
    let canSwipe = false;
    let currentRef = horizontalSwipeLayoutRef.current;

    const handleGesture = () => {
      const absX = window.Math.abs(touchstartX - touchendX);
      const absY = window.Math.abs(touchstartY - touchendY);

      if (canSwipe && isActive && absX > absY && absX > 10) {
        if (touchstartX > touchendX) {
          if (onSwipeLeft) {
            onSwipeLeft();
          }
        }
        else {
          if (onSwipeRight) {
            onSwipeRight();
          }
        }
      }
    };

    const onTouchStart = (e) => {
      touchstartX = isMobile ? e.changedTouches[0].screenX : e.screenX;
      touchstartY = isMobile ? e.changedTouches[0].screenY : e.screenY;
      canSwipe = !(e.target && e.target.closest('.ignore_swipe'));
    };

    const onTouchEnd = (e) => {
      touchendX = isMobile ? e.changedTouches[0].screenX : e.screenX;
      touchendY = isMobile ? e.changedTouches[0].screenY : e.screenY;
      handleGesture();
    };

    if (horizontalSwipeLayoutRef && horizontalSwipeLayoutRef.current && (onSwipeRight || onSwipeLeft)) {
      isOn = true;
      if (isMobile) {
        currentRef.addEventListener('touchstart', onTouchStart);
        currentRef.addEventListener('touchend', onTouchEnd);
      }
      else {
        currentRef.addEventListener('mousedown', onTouchStart);
        currentRef.addEventListener('mouseup', onTouchEnd);
      }
    }

    return () => {
      isActive = false;
      if (isOn) {
        isOn = false;
        if (isMobile) {
          currentRef.removeEventListener('touchstart', onTouchStart);
          currentRef.removeEventListener('touchend', onTouchEnd);
        }
        else {
          currentRef.removeEventListener('mousedown', onTouchStart);
          currentRef.removeEventListener('mouseup', onTouchEnd);
        }
      }
    };
  }, [onSwipeRight, onSwipeLeft, horizontalSwipeLayoutRef, isMobile])

  useEffect(() => {
    if (horizontalSwipeLayoutRef.current && index >= 0) {
      if (selectedIndex === false) {
        setIsAnimate(!!startHeight);
        setSelectedIndex(index);
      }
      else {
        const horizontalSwipeLayoutBoundingClientRect = horizontalSwipeLayoutRef.current.getBoundingClientRect();
        setHeight((horizontalSwipeLayoutBoundingClientRect && horizontalSwipeLayoutBoundingClientRect.height) || startHeight);
        setSelectedIndex(index);
        setIsAnimate(true);
      }
    }
  }, [horizontalSwipeLayoutRef, index, selectedIndex, startHeight]);

  useEffect(() => {
    let isActive = true;

    if (horizontalSwipeLayoutRef.current && isAnimate) {
      const children = horizontalSwipeLayoutRef.current.children;
      if (selectedIndex >= 0 && selectedIndex < children.length) {
        const childrenBoundingClientRect = children[selectedIndex] && children[selectedIndex].getBoundingClientRect();
        setHeight((childrenBoundingClientRect && childrenBoundingClientRect.height) || startHeight);
      }
      setTimeout(() => {
        if (isActive) {
          setIsAnimate(false);
        }
      }, 510);
    }

    return () => {
      isActive = false;
    };
  }, [horizontalSwipeLayoutRef, isAnimate, selectedIndex, startHeight])


  return <div className={`${styles.horizontalSwipeLayout} ${isAnimate ? styles.isAnimate : ''} ${index < 0 && startHeight ? styles.isStart : ''} ${className || ''}`} ref={horizontalSwipeLayoutRef} style={{height: height || startHeight}}>
    {slides && slides.map((slide, i) => {
      return <div key={`horizontalSwipeLayout-slide--${i}`} className={`${styles.slide} ${slideClassName || ''} ${((!selectedIndex && !i) || i === selectedIndex) ? styles.slide_inner : i < selectedIndex ? styles.slide_before : styles.slide_after}`}>
        {slide}
      </div>;
    })}
  </div>;
};

HorizontalSwipeLayout.propTypes = {
  index: PropTypes.number,
  slides: PropTypes.array,
  className: PropTypes.string,
  slideClassName: PropTypes.string,
};

export default HorizontalSwipeLayout;