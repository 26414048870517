import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGenderFormatMessage, useUserProfile, useUserDashboard } from '../../hooks';

import { TaskManager } from 'cet-components-lib/dist/Services/TaskManager';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

import { UiContext } from '../../contexts';

import Button from '../Button';

import styles from './TaskForm.module.scss';

const TaskForm = ({
  onClose,
  onTotalClose,
  onSave,
  documentId,
  taskID,
  contextLanguage,
  title,
  itemSource,
  setPopupClass,
  setCreateTaskSuccess,
  layout,
  sitekey,
  ...rest
}) => {
  const { isMobileView } = useDeviceData();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { updateUserActivity } = useUserProfile();
  const { addTasksToUpdate } = useUserDashboard();
  const [, updateUiData] = useContext(UiContext);
  const [userInfo, lastSchool] = useSelector(({ user }) => [user.info, user.lastSchool]);
  const [isEnd, setIsEnd] = useState(false);

  const isLearningActivity = sitekey === 'learning' || itemSource === 'learning';

  const itemLanguage = useMemo(() => {
    let cetLanguage = 'Hebrew';
    switch (contextLanguage) {
      case 'ar':
        cetLanguage = 'Arabic';
        break;
      case 'en':
        cetLanguage = 'English';
        break;
      default:
        break;
    }
    return cetLanguage;
  }, [contextLanguage]);

  const schoolID = useMemo(() => {
    return lastSchool && lastSchool.externalSchoolId ? lastSchool.externalSchoolId : null;
  }, [lastSchool]);

  const onTaskManagerClose = useCallback(() => {
    if (setPopupClass) {
      setPopupClass(false);
    }
    if (isEnd && onTotalClose) {
      onTotalClose();
    }
    else if (onClose) {
      onClose();
    }
  }, [isEnd, onClose, onTotalClose, setPopupClass])

  const onTaskManagerSave = useCallback(task => {
    if (task && task.taskID && task.task && task.task.item && task.task.item.id && userInfo && userInfo.userId) {
      updateUserActivity({
        teacherId: userInfo.userId,
        externalId: task.task.item.id,
        activityType: 'task',
        name: task.taskID,
        additionalInfo: JSON.stringify({}),
        schoolProfileId: task.task.schoolID,
      });
      addTasksToUpdate([task.taskID]);
    }
    if (onSave) {
      onSave(task);
    }
  }, [addTasksToUpdate, onSave, updateUserActivity, userInfo])

  const onTaskManagerStatusChange = useCallback(status => {
    const isSuccess = status === 'success';
    setIsEnd(isSuccess);
    if (setPopupClass) {
      setPopupClass(status === 'error' ? styles['taskForm--red'] : status === 'success' ? styles['taskForm--green'] : false);
    }
  }, [setPopupClass])

  useEffect(() => {
    if (typeof setCreateTaskSuccess === 'function') {
      setCreateTaskSuccess(isEnd);
    }
  }, [isEnd, setCreateTaskSuccess])

  return (
    <div className={styles.taskForm}>
      {!userInfo || !userInfo.userId || !schoolID
        ? <div className={styles.taskFormContainer}>
          <div className={styles.taskFormContainerItem}>
            <div className={styles.taskFormContainerItemContent}>
              <div className={styles.taskFormNoLoginTitle}>{getGenderFormatMessage('taskForm_noLogin_title')}</div>
              <p>
                {getGenderFormatMessage('taskForm_noLogin_line1_start')}{' '}
                <Button
                  onClick={() => { updateUiData({ showLogin: true }) }}
                  type="link"
                  category="assignPopup" label="login"
                >
                  {getGenderFormatMessage('taskForm_noLogin_loginBtn')}
                </Button>{' '}
                {getGenderFormatMessage('taskForm_noLogin_line1_end')}
              </p>
              <p>{getGenderFormatMessage('taskForm_noLogin_line2')}</p>
              <div className={styles.taskFormContainerItemImage}></div>
            </div>
            <div className={styles.taskFormContainerItemFooter}>
              <div className={styles.taskFormContainerItemFooterSide_center}>
                <Button onClick={onClose} type="line" size="medium" className={styles.footerButton}>
                  {getGenderFormatMessage('back')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        : <TaskManager
          isLearningActivity={isLearningActivity}
          layout={layout ?? (isMobileView ? 'popup' : 'inline')}
          taskID={taskID}
          itemID={documentId}
          itemLanguage={itemLanguage}
          onClose={onTaskManagerClose}
          onSave={onTaskManagerSave}
          onStatusChange={onTaskManagerStatusChange}
        />
      }
    </div>)
};

TaskForm.propTypes = {};

export default TaskForm;
