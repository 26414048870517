import { useEffect, useState, useRef, useCallback } from 'react';
import { usePrevious } from '.';

export const useVisibilityCheck = (
  ref,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  }
) => {
  const [isVisible, setIsVisible] = useState();
  const [wasVisibleOnRender, setWasVisibleOnRender] = useState(undefined);

  const wasVisible = useRef();
  wasVisible.current = usePrevious(isVisible ?? wasVisibleOnRender);

  const callback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (typeof wasVisibleOnRender === 'undefined') {
          setWasVisibleOnRender(entry.intersectionRatio >= options.threshold);
        }

        setIsVisible(entry.intersectionRatio >= options.threshold);
      });
    },
    [options.threshold, wasVisibleOnRender]
  );


  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    const element = ref?.current || ref;

    if (!element || !element.addEventListener || !typeof element.addEventListener === 'function') {
      console.warn(`missing a reference to a rect 'ref object' or to a instance of HTMLElement`);
      return;
    }

    if (element) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return { isVisible, wasVisible: wasVisible.current, wasVisibleOnRender };
};
