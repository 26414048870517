import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';

import { useGenderFormatMessage } from '../../../hooks';

import styles from '../Preview.module.scss';

const SubjectList = ({ title, list, startLinesCount=3 }) => {
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [linesCount, setLinesCount] = useState(startLinesCount);
  const readMoreLabel = <b>{getGenderFormatMessage('read_more')}</b>;

  return (
    <>
      {list?.length > 0 && (
        <>
          <h3 className={styles.label}>{title}</h3>
          <ul className={styles.list}>
            {list.slice(0, linesCount).map(({ subject, subSubject, details }, index) => (
              <li key={index}>
                <strong className={styles.listSubject}>{subject}</strong>
                {subSubject ? ',' : ''} <strong>{subSubject}</strong>
                {details ? ',' : ''} {details}
              </li>
            ))}
          </ul>

          {list.length > linesCount && (
            <div>
              <Button onClick={() => setLinesCount(999)} type="link">
                {readMoreLabel} ...  
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

SubjectList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string,
      subSubject: PropTypes.string,
      details: PropTypes.string,
    })
  ),
};

export default SubjectList;
