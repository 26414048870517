import { combineReducers, createStore, compose } from 'redux';

import dimensions from './dimensions';
import user from './user';
import globalItems from './globalItems';
import deviceData from './deviceData';
import items from './items';
import sectors from './sectors';
import ui from './ui';

const rootReducer = combineReducers({
  user,
  dimensions,
  globalItems,
  deviceData,
  ui,
  items,
  sectors
});

export const configureStore = (initialState = {}) => {
  // Using compose for redux devtools
  return createStore(
    rootReducer,
    initialState,
    compose(
      process.env.NODE_ENV !== 'production' &&
        typeof window === 'object' &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
        : (f) => f
    )
  );
};

export const store = configureStore();
