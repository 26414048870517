export const CacheProvider = {
  generateKey: (prefix, data) => {
    let dataString = '';
    if (data) {
      if (typeof data === 'object') {
        dataString = JSON.stringify(data);
      } else {
        dataString = data.toString();
      }
    }
    return window.btoa(encodeURIComponent(`${prefix}_${dataString}`));
  },
  set: (key, value) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    try {
      window.sessionStorage.setItem(key, value);
    }
    catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  },
  get: key => {
    let cachedValue, paredResult;

    cachedValue = window.sessionStorage.getItem(key);

    try {
      paredResult = JSON.parse(cachedValue);
    } catch (e) {
      paredResult = cachedValue;
    }

    return paredResult;
  },
  clearSession: () => {
    window.sessionStorage.clear();
  },
  clearKeyValue: (key) => {
    window.sessionStorage.setItem(key, null);
  }
};
