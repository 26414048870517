import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCetEvents } from 'cet-components-lib/dist/hooks';
import styles from './Input.module.scss';
import listStyles from '../DataList/DataList.module.scss';

import { optionsPropTypes } from '../DataList/PropTypes';

/** This is a wrapper around the html input (you can pass it any of native attribute) */
const Input = ({
  containerClassName,
  autoCompleteCategory,
  autoCompleteOptions,
  autoCompleteClassName,
  onAutoCompleteSelect,
  children,
  value,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState();
  const [inputValue, setInputValue] = useState(() => value || '');
  const { sendUiUsageEvent } = useCetEvents();
  const currentOptionIndexRef = useRef();
  const [currentOptionValue, setCurrentOptionValue] = useState();
  const [lists, setLists] = useState();
  const containerRef = useRef();
  const listRef = useRef();
  const inputRef = useRef();
  const onParentKeyPress = rest?.onKeyPress;

  const handleAutoCompleteSelect = useCallback(
    (option) => {
      if (typeof onAutoCompleteSelect === 'function') {
        onAutoCompleteSelect(option);
      } else {
        setInputValue(option.text);
      }
      sendUiUsageEvent({category: autoCompleteCategory, label: 'selectAutoComplete'})
    },
    [autoCompleteCategory, onAutoCompleteSelect, sendUiUsageEvent]
  );

  const handleInputKeyPress = useCallback(
    (event) => {
      let { key } = event;

      const listElements = listRef.current?.querySelectorAll('li');

      if (!listElements) return;

      switch (key) {
        case 'ArrowDown':
          event.preventDefault();
          if (currentOptionIndexRef.current === undefined) {
            currentOptionIndexRef.current = 0;
          } else if (currentOptionIndexRef.current < listElements.length - 1) {
            currentOptionIndexRef.current++;
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (currentOptionIndexRef.current > 0) {
            currentOptionIndexRef.current--;
          }
          break;
        case 'Enter':
          {
            const selectedLi = listElements[currentOptionIndexRef.current];
            if (selectedLi) {
              const lists =
                autoCompleteOptions?.length > 0 && autoCompleteOptions[0]?.hasOwnProperty('list')
                  ? autoCompleteOptions.reduce((previous, current) => {
                      return (previous = [...previous, ...current.list]);
                    }, [])
                  : [...autoCompleteOptions];

              const selected = lists.find((option) => {
                return option.value.toString() === selectedLi.dataset.value.toString();
              });

              inputRef.current.blur();
              handleAutoCompleteSelect(selected);
            }
          }
          break;
        case 'Escape':
          setIsOpen(false);
          break;
        case 'Home':
          currentOptionIndexRef.current = 0;
          break;
        case 'End':
          currentOptionIndexRef.current = listRef.current.children.length - 1;
          break;
        default:
          break;
      }

      const option = listElements[currentOptionIndexRef.current];
      if (option) {
        setCurrentOptionValue(option.dataset.value);

        //Scroll list to focused option
        if (listRef.current.scrollHeight > listRef.current.clientHeight) {
          var scrollBottom = listRef.current.clientHeight + listRef.current.scrollTop;
          var elementBottom = option.offsetTop + option.offsetHeight;
          if (elementBottom > scrollBottom) {
            listRef.current.scrollTop = elementBottom - listRef.current.clientHeight;
          } else if (option.offsetTop < listRef.current.scrollTop) {
            listRef.current.scrollTop = option.offsetTop - 32;
          }
        }
      }

      if (typeof onParentKeyPress === 'function') {
        onParentKeyPress(event);
      }
    },
    [autoCompleteOptions, handleAutoCompleteSelect, onParentKeyPress]
  );

  const handleInputFocus = (event) => {
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
    if (typeof rest?.onFocus === 'function') {
      rest.onFocus(event);
    }
  };

  const handleInputBlur = (event) => {
    if (containerRef.current?.contains(event.relatedTarget)) {
      return;
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 100);

    if (typeof rest?.onBlur === 'function') {
      rest.onBlur(event);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (!autoCompleteOptions || autoCompleteOptions.length === 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    if (typeof rest?.onChange === 'function') {
      rest.onChange(event);
    }
  };

  useEffect(() => {
    currentOptionIndexRef.current = undefined;
    setCurrentOptionValue(undefined);
  }, [isOpen]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    if (autoCompleteOptions?.length) {
      setIsOpen(true);
    }

    const updatedLists = autoCompleteOptions?.length
      ? autoCompleteOptions[0]?.hasOwnProperty('list')
        ? autoCompleteOptions
        : [{ list: autoCompleteOptions }]
      : null;

    setLists(updatedLists);
  }, [autoCompleteOptions]);

  const label = 'label';

  return (
    <div ref={containerRef} className={`${styles.input} ${containerClassName}`}>
      <input
        ref={inputRef}
        {...rest}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyPress}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {lists && (
        <div
          className={`${listStyles.list}
            ${lists.length > 1 ? listStyles.columns : ''}
            ${listStyles.visible} ${autoCompleteClassName || ''}`.trimEnd()}
        >
          <div data-id="list" ref={listRef} className={listStyles.container}>
            {lists.map((optionsList, i) => (
              <ul key={i} role="listbox" aria-label={label}>
                {optionsList?.title && <li key={`${i}-${optionsList.title}`} className={listStyles.title}>{optionsList.title}</li>}
                {optionsList?.list.map((option,j) => (
                  <li
                    key={`li-${i}-${j}`}
                    role="option"
                    tabIndex={-1}
                    data-value={option.value}
                    aria-selected={false}
                    aria-hidden={!isOpen}
                    onClick={() => handleAutoCompleteSelect(option)}
                    className={option.value.toString() === currentOptionValue?.toString() ? listStyles.focused : ''}
                  >
                    {children && typeof children === 'function' ? children(option) : option.text}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  containerClassName: PropTypes.string,
  autoCompleteClassName: PropTypes.string,
  autoCompleteCategory: PropTypes.string,
  autoCompleteOptions: optionsPropTypes,
  onAutoCompleteSelect: PropTypes.func,
  children: PropTypes.func,
};

export default Input;
