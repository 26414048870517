import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './Tooltip.module.scss';

import { useDisplay } from './useDisplay';

const Controlled = ({ children, content, isActive, position = 'top', offset = 0, onMouseEnter, onMouseLeave, onMouseOver, className }) => {
  const tipRef = useRef();
  const containerRef = useRef();
  let [isIn, left] = useDisplay(isActive, containerRef, tipRef, offset);

  return (
    <div ref={containerRef} className={`${styles.container} ${className||''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseOver={onMouseOver}>
      {isActive && (
        <div
          ref={tipRef}
          className={`${styles.tip} ${!isIn ? styles.hidden : ''} ${styles[position]}`}
          style={{ left }}
        >
          {content}
        </div>
      )}
      {children}
    </div>
  );
};

Controlled.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
  offset: PropTypes.number,
  className: PropTypes.string
};

export default Controlled;
