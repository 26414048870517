import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useSearch } from './';
import { useCallback } from 'react';
import { callWithCache } from '../utils/callWithCache';

export const useBooks = () => {
  const dispatchSearch = useSearch();
  const intl = useIntl();
  const { booksActivities, lastSchool, isExtendedCatalog } = useSelector(({ user }) => ({
    booksActivities: user.booksActivities,
    lastSchool: user.lastSchool,
    isExtendedCatalog: user.info?.isExtendedCatalog
  }));

  const getItemsForNewBooks = useCallback(
    async data => {
      const result = await dispatchSearch([{ id: data }]);
      if (result?.length) {
        let items = result[0];
        items.title = intl.formatMessage({ id: 'new_books' });
        return [items];
      }
      return null;
    },
    [dispatchSearch, intl]
  );

  const getTeacherBooks = useCallback(
    async (teacherId /*, removeBookFromMyBooks*/) => {
      if (teacherId) {
        const bookIds = booksActivities.map(book => book.externalId);
        const result = await dispatchSearch([{ externalContextId: bookIds }]);
        if (result?.length) {
          let items = result[0];
          items.title = intl.formatMessage({ id: 'my_books' });
          items.data.forEach(item => {
            const book = booksActivities.filter(book => book.externalId === item.externalContextId);
            item.activityInfo = book[0].additionalInfo;
            item.isInMyBooks = true;
            // item.onRemoveFromMyBooks = removeBookFromMyBooks;
          });

          items.data.sort((a, b) => (a.activityInfo.timestamp > b.activityInfo.timestamp ? -1 : a.activityInfo.timestamp < b.activityInfo.timestamp ? 1 : 0));
          return [items];
        }

        return null;
      } else {
        return null;
      }
    },
    [booksActivities, dispatchSearch, intl]
  );

  const removeBookFromTeacherBooks = useCallback(async (teacherId, externalId) => {
    if (teacherId && externalId) {
      try {
        await window.cet.microservices.teachersapi.lmsActivity.hideActivityFromView({
          teacherId,
          externalId
        });
        return true;
      } catch (error) {
        return false;
      }
    }
  }, []);

  const getBookInfo = useCallback(itemId => {
    if (itemId) {
      return window.cet.microservices.contentapi.content
        .getItemById({
          itemId: itemId
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      return null;
    }
  }, []);

  const getBooksRealtedItems = useCallback(items => {
    if (items) {
      return window.cet.microservices.contentapi.content
        .getRelatedItemActivitiesByIds({
          ids: items
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      return null;
    }
  }, []);

  const getNewBooks = useCallback(() => {
    return callWithCache(window.cet.microservices.contentapi.content.getNewestItemsIds, 'getNewestItemsIds', {
      numResults: 10,
      dimensionParams: {
        itemTypes: ['book'],
        sectors: isExtendedCatalog ? ['state', 'mmad', 'arab'] : lastSchool?.sectorId ? [lastSchool.sectorId] : intl.locale === 'ar' ? ['arab'] : ['state', 'mmad']
      }
    })
      .then(data => {
        const items = getItemsForNewBooks(data);
        return items;
      })
      .catch(error => {
        return [];
      });
  }, [getItemsForNewBooks, intl.locale, isExtendedCatalog, lastSchool]);

  return {
    getTeacherBooks: getTeacherBooks,
    removeBookFromTeacherBooks: removeBookFromTeacherBooks,
    getBookInfo: getBookInfo,
    getNewBooks: getNewBooks,
    getBooksRealtedItems: getBooksRealtedItems
  };
};
