let timeout;
export const debounce = (func, wait, immediate) => {
  return function () {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};
/**
 * A simple async delay function
 * @param {number} ms
 * @returns {Promise<unknown>}
 */
export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let passiveOptionAccessed = false;
const options = {
  get passive() {
    return (passiveOptionAccessed = true);
  },
};

const noop = () => {};
window.addEventListener && window.addEventListener('p', noop, options);
window.removeEventListener && window.removeEventListener('p', noop, false);

export const supportsPassiveEvents = passiveOptionAccessed;
