import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { callWithCache } from '../utils/callWithCache';

export const useSystemMessages = () => {
  const { locale } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(null);
  const [eliteMessage, setEliteMessage] = useState(null);
  const [dominateMessage, setDominateMessage] = useState(null);

  useEffect(() => {
    if (locale) {
      setIsLoading(true);
      setErrorLoading(null);

      callWithCache(window.cet.microservices.nairobiapi.systemMessages.getMessagesByDate, 'getMessagesByDate', {
        language: locale,
        date: JSON.stringify(new Date()).replace(/"/gi, '')
      })
        .then(data => {
          let elite = null;
          let dominate = null;

          if (data && data.length > 0) {
            elite = data.find(mess => mess.type === 'elite');
            elite = elite || null;
            dominate = data.find(mess => mess.type === 'dominate');
            dominate = dominate || null;
          }

          setIsLoading(false);
          setErrorLoading(null);
          setEliteMessage(elite);
          setDominateMessage(dominate);
        })
        .catch(error => {
          setIsLoading(false);
          setErrorLoading(error);
          setEliteMessage(null);
          setDominateMessage(null);
        });
    } else {
      setEliteMessage(null);
      setDominateMessage(null);
    }
  }, [locale]);

  return {
    isMessagesLoading: isLoading,
    messagesErrorLoading: errorLoading,
    elite: eliteMessage,
    dominate: dominateMessage
  };
};
