import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ContentHeader from './Partials/ContentHeader';
import SubjectsList from './Partials/SubjectsList';
import ReferencesList from './Partials/ReferencesList';
import TableOfContent from './Partials/CourseTableOfContent';
import AuthorizationMessage from './Partials/AuthorizationMessage';
import Icon from '../Icon';
import Href from '../Href';
import { NewTag } from '../Tag';
import Tabs from '../Tabs';
import StuckMenu from '../StuckMenu';
import VideoPlayBtn from '../VideoPlayBtn';
import DropDown from '../DropDown';

import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { UiContext } from '../../contexts';
import { safelyGetInnerHTML } from '../../utils/html';

import styles from './Preview.module.scss';

const Item = ({
  itemId,
  documentId,
  title,
  type,
  banner,
  professions,
  grades,
  contextURL,
  chapters,
  summary,
  yearCycles,
  syllabus,
  additionalInfo,
  skills,
  itemDimensions,
  pedagogicValues,
  references,
  itemTypes,
  duration,
  isInFavorites,
  isMobileSupported,
  isNew,
  isCourse,
  onOpenClick,
  onAttachClick,
  onShareClick,
  onFavoritesClick,
  onBannerError,
  onBannerLoad,
  hideButtons
}) => {
  const [subscription, setSubscription] = useState(null);
  const [audiences, setAudiences] = useState([]);
  const [courseMarketingURL, setCourseMarketingURL] = useState(null);
  const [courseVideoURL, setCourseVideoURL] = useState(null);
  const [courseContent, setCourseContent] = useState([]);

  const [{ }, updateUiData] = useContext(UiContext);
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const [selectedTab, setSelectedTab] = useState('summary');

  const { productSubscriptions, activities, isGuest } = useSelector(({ user }) => {
    let schoolId = user.lastSchool ? user.lastSchool.externalSchoolId : '';
    schoolId = schoolId ? schoolId.toLocaleUpperCase() : '';
    return {
      productSubscriptions: user.productSubscriptions,
      activities: user.activities.find(
        activity => activity.externalId === documentId && activity.schoolProfileId && activity.schoolProfileId.toLocaleUpperCase() === schoolId
      ),
      isGuest: !user.info?.userId
    };
  });

  useEffect(() => {
    setSubscription(
      productSubscriptions
        ? productSubscriptions.find(
          productSubscription =>
            productSubscription.source === 'capsules' &&
            productSubscription.folderID &&
            documentId &&
            productSubscription.folderID.toLocaleLowerCase() === documentId.toLocaleLowerCase()
        )
        : null
    );
  }, [productSubscriptions, documentId]);

  useEffect(() => {
    if (activities?.additionalInfo?.audiences) {
      setAudiences(activities.additionalInfo.audiences.filter(audience => !audience.archiveYear));
    }
  }, [activities]);

  useEffect(() => {
    let courseVideoURL = null,
      courseMarketingURL = null,
      courseContent = [];
    // courseShowCreateEnvironmentButton = false;

    if (additionalInfo && additionalInfo.courseData) {
      if (!subscription && additionalInfo.courseData.courseVideoURL) {
        courseVideoURL = additionalInfo.courseData.courseVideoURL;
      }
      if (!subscription && additionalInfo.courseData.courseMarketingURL) {
        courseMarketingURL = additionalInfo.courseData.courseMarketingURL;
      }
      if (!subscription && additionalInfo.courseData.courseContent) {
        courseContent = additionalInfo.courseData.courseContent
          .replace(/(\r\n|\n|\r)/gm, ';')
          .split(';')
          .map(item => item.trim());
      }
    }

    setCourseMarketingURL(courseMarketingURL);
    setCourseVideoURL(courseVideoURL);
    setCourseContent(courseContent);
  }, [subscription, additionalInfo]);

  if (isMobileView) {
    return (
      <div className={styles.mobileContainer} data-scrolling-handler-element="scroller">
        <div className={styles.topMarker} data-scrolling-handler-element="itemTopMarker"></div>
        <div className={styles.banner}>
          <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={239} />
          <div className={styles.tags}>{isNew && <NewTag label={getGenderFormatMessage('new')} angle="107deg" withSpout={false} />}</div>
          <VideoPlayBtn videoUrl={courseVideoURL} className={styles.videoPlayBtn} />
        </div>
        <div className={styles.content}>
          <ContentHeader
            type={type}
            title={title}
            professions={professions}
            grades={grades}
            duration={duration}
            durationLabel={isCourse ? 'hours' : 'minutes'}
            isCourse={isCourse}
            isInFavorites={isInFavorites}
            onOpenClick={onOpenClick}
            marketingUrl={courseMarketingURL}
            onShareClick={onShareClick}
            chapters={chapters}
            onFavoritesClick={onFavoritesClick}
            hideButtons={hideButtons}
            documentId={documentId}
            hasAudiences={audiences?.length > 0}
            itemDimensions={itemDimensions}
          />
          {/* audiences */}
          {audiences && audiences.length > 0 && (
            <div>
              <DropDown buttonClassName={styles.audiencesBtn} withArrow={false} label={`${getGenderFormatMessage('my_audiences')} (${audiences.length})`}>
                <div className={styles.audiences}>
                  <ul>
                    {audiences.sort((a, b) => a.name.localeCompare(b.name)).map(audience => (
                      <li key={audience.id}>
                        <Href
                          to={`${contextURL}?audience=${audience.id}`}
                          target="_blank"
                          onClick={() => {
                            updateUiData({ dropBoxContent: null });
                          }}
                        >
                          {audience.name}
                        </Href>
                      </li>
                    ))}
                  </ul>
                </div>
              </DropDown>
            </div>
          )}
          {isGuest && (
            <AuthorizationMessage
              message={getGenderFormatMessage('course_view_first')}
              openText={getGenderFormatMessage('course_peek')}
              onOpenClick={onOpenClick}
              isPurchaseBtn={false}
            />
          )}
          {yearCycles && <div className={styles.yearCycles}>{yearCycles}</div>}
          <StuckMenu
            type="item"
            stuckUpClassName={styles.stuckUpClassName}
            stuckMenuClassName={styles.stuckMenuClassName}
            layoutCenterClassName={styles.layoutCenterClassName}
            layoutTop={
              <>
                <div className={styles.tabs}>
                  <Tabs
                    tabs={[
                      { value: 'summary', text: getGenderFormatMessage('preview_mobile_courses_summary'), totalCount: -1 },
                      { value: 'tableOfContent', text: getGenderFormatMessage('preview_mobile_courses_tableOfContent'), totalCount: -1 },
                      { value: 'syllabus', text: getGenderFormatMessage('preview_mobile_courses_syllabus'), totalCount: -1 }
                    ]}
                    addAllTab={false}
                    initToCenter={true}
                    activeTabKey={selectedTab}
                    onTabClick={({ value }) => {
                      setSelectedTab(value);
                    }}
                  />
                </div>
              </>
            }
          />
          <div>
            {selectedTab === 'summary' && (
              <>
                {/* summary */}
                <div className={styles.row}>{safelyGetInnerHTML(summary)}</div>

                {/*  itemTypes */}
                <div className={styles.row}>
                  {itemTypes?.map((itemType, index) => (
                    <div key={index} className={styles.itemType}>
                      <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
                      <div>{itemType.title}</div>
                    </div>
                  ))}
                  {/* {isMobileSupported && (
                    <div className={styles.itemType}>
                      <Icon icon="mobile_compatible" title={getGenderFormatMessage('mobile_compatible')} width={22} height={22} fill="transparent" stroke="currentColor" />
                      <div>{getGenderFormatMessage('mobile_compatible')}</div>
                    </div>
                  )} */}
                </div>

                {/* courseContent */}
                {courseContent && courseContent.length > 0 && (
                  <div className={styles.courseContent}>
                    <div className={styles.courseContentTitle}>{getGenderFormatMessage('preview_course_content_title')}</div>
                    {courseContent.map((item, i) => (
                      <div key={`courseContentItem--${i}`} className={styles.courseContentItem}>
                        <Icon icon="icon_v" />
                        {item}
                      </div>
                    ))}
                  </div>
                )}

                {/* references */}
                {references?.length > 0 && (
                  <div className={`${styles.references} ${styles.margined}`}>
                    <ReferencesList
                      title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage('books')}`}
                      list={references}
                      startLinesCount={references ? references.length : 3}
                    />
                  </div>
                )}
              </>
            )}
            {selectedTab === 'tableOfContent' && (
              <>
                {/* syllabus */}
                <TableOfContent data={additionalInfo?.tableOfContents} contextURL={contextURL} alwaysOpen={true} />
              </>
            )}
            {selectedTab === 'syllabus' && (
              <>
                {/* syllabus */}
                <SubjectsList list={syllabus} title={getGenderFormatMessage('syllabus_subject')} startLinesCount={syllabus ? syllabus.length : 3} />
                {(skills?.length || pedagogicValues?.length) && (
                  <div className={styles.bordered}>
                    <SubjectsList list={skills} title={getGenderFormatMessage('skills')} startLinesCount={skills ? skills.length : 3} />

                    {/* pedagogic values */}
                    {pedagogicValues?.length > 0 && (
                      <div className={styles.margined}>
                        <SubjectsList
                          list={pedagogicValues}
                          title={getGenderFormatMessage('pedagogic_values')}
                          startLinesCount={pedagogicValues ? pedagogicValues.length : 3}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.tags}>{isNew && <NewTag label={getGenderFormatMessage('new')} />}</div>
      <div className={styles.banner}>
        <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={239} />
        <VideoPlayBtn videoUrl={courseVideoURL} className={styles.videoPlayBtn} />
      </div>
      <div className={styles.content}>
        <ContentHeader
          type={type}
          title={title}
          professions={professions}
          grades={grades}
          duration={duration}
          durationLabel={isCourse ? 'hours' : 'minutes'}
          isCourse={isCourse}
          summary={summary}
          isInFavorites={isInFavorites}
          onOpenClick={onOpenClick}
          marketingUrl={courseMarketingURL}
          onShareClick={onShareClick}
          onFavoritesClick={onFavoritesClick}
          chapters={chapters}
          documentId={documentId}
          hideButtons={hideButtons}
          hasAudiences={audiences?.length > 0}
          itemDimensions={itemDimensions}
        />
        {/*  itemTypes */}
        <div className={styles.row}>
          {itemTypes?.map((itemType, index) => (
            <div key={index} className={styles.itemType}>
              <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
              <div>{itemType.title}</div>
            </div>
          ))}
          {/* {isMobileSupported && (
            <div className={styles.itemType}>
              <Icon icon="mobile_compatible" title={getGenderFormatMessage('mobile_compatible')} width={22} height={22} fill="transparent" stroke="currentColor" />
              <div>{getGenderFormatMessage('mobile_compatible')}</div>
            </div>
          )} */}
        </div>
        {/* courseContent */}
        {courseContent && courseContent.length > 0 && (
          <div className={styles.courseContent}>
            <div className={styles.courseContentTitle}>{getGenderFormatMessage('preview_course_content_title')}</div>
            {courseContent.map((item, i) => (
              <div key={`courseContentItem--${i}`} className={styles.courseContentItem}>
                <Icon icon="icon_v" />
                {item}
              </div>
            ))}
          </div>
        )}
        {/* audiences */}
        {audiences && audiences.length > 0 && (
          <TableOfContent
            data={audiences.sort((a, b) => a.name.localeCompare(b.name)).map(audience => {
              return { title: audience.name, id: `?audience=${audience.id}` };
            })}
            title={getGenderFormatMessage('my_audiences')}
            contextURL={contextURL}
            openOnStart={true}
          />
        )}
        {isGuest && (
          <AuthorizationMessage
            message={getGenderFormatMessage('course_view_first')}
            openText={getGenderFormatMessage('course_peek')}
            onOpenClick={onOpenClick}
            isPurchaseBtn={false}
          />
        )}
        <TableOfContent data={additionalInfo?.tableOfContents} title={getGenderFormatMessage('table_of_contents')} contextURL={contextURL} />
        {/* syllabus */}
        <SubjectsList list={syllabus} title={getGenderFormatMessage('syllabus_subject')} />
        {(skills?.length || pedagogicValues?.length) && (
          <div className={styles.bordered}>
            <SubjectsList list={skills} title={getGenderFormatMessage('skills')} />

            {/* pedagogic values */}
            {pedagogicValues?.length > 0 && (
              <div className={styles.margined}>
                <SubjectsList list={pedagogicValues} title={getGenderFormatMessage('pedagogic_values')} />
              </div>
            )}
          </div>
        )}
        {/* references */}
        {references?.length > 0 && (
          <div className={`${styles.references} ${styles.margined}`}>
            <ReferencesList title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage('books')}`} list={references} />
          </div>
        )}
      </div>
    </div>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  banner: PropTypes.string.isRequired,
  professions: PropTypes.string.isRequired,
  grades: PropTypes.string.isRequired,
  contextURL: PropTypes.string.isRequired,
  chapters: PropTypes.number.isRequired,
  summary: PropTypes.string.isRequired,
  syllabus: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string,
      subSubject: PropTypes.string,
      details: PropTypes.string
    })
  ),
  tableOfContent: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      chapters: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired
        })
      )
    })
  ),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.string,
      sub: PropTypes.string,
      details: PropTypes.string
    })
  ),
  pedagogicValues: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.string,
      details: PropTypes.string
    })
  ),
  references: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  ),
  isInFavorites: PropTypes.bool,
  isMobileSupported: PropTypes.bool,
  isNew: PropTypes.bool,
  isCourse: PropTypes.bool,
  onOpenClick: PropTypes.func.isRequired,
  onAttachClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onFavoritesClick: PropTypes.func.isRequired,
  onBannerError: PropTypes.func
};

export default Item;
