import { useState, useEffect } from 'react';

export function useParamsManagement(initialState = {}) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    const newState = {};
    for (const [key, value] of queryString.entries()) {
      newState[key] = value.toLocaleLowerCase();
    }
    setState(newState);
  }, []);

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    Object.entries(state).forEach(([key, value]) => {
      if (value) {
        queryString.set(key, value.toLocaleLowerCase());
      } else {
        queryString.delete(key);
      }
    });
    window.history.pushState({}, '', `${window.location.pathname}?${queryString}`);
  }, [state]);

  return [state, setState];
}