import { useEffect, useState } from 'react';

export const useVisibleGridCols = (containerRef, defaultColsCount = 2) => {
  const [visibleCols, setVisibleCols] = useState(defaultColsCount);

  useEffect(() => {
    const calcColumns = () => {
      if (!containerRef.current) return;
      const containerComputerStyle = window.getComputedStyle(containerRef.current);
      const nCols = containerComputerStyle.getPropertyValue('grid-template-columns').replace(' 0px', '').split(' ').length;
      setVisibleCols(nCols);
    };
    calcColumns();
    window.addEventListener('resize', calcColumns);
    return () => {
      window.removeEventListener('resize', calcColumns);
    };
  }, [containerRef, setVisibleCols]);

  return visibleCols;
};