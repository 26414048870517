import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useHorizontalScroller, useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import styles from './Tabs.module.scss';
import buttonStyles from '../Button/Button.module.scss';

import Button from '../Button';
import DropDown from '../DropDown';

const Tabs = ({ tabs, onTabClick, activeTabKey = 'all', addAllTab = false, cutWidth = 0, skin = 'tabs', initToCenter=false }) => {
  const intl = useIntl();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [visibleTabs, setVisibleTabs] = useState(
    addAllTab
      ? [
        {
          value: 'all',
          text: getGenderFormatMessage('all'),
          totalCount: -1,
        },
      ]
      : []
  );

  const [moreTabs, setMoreTabs] = useState([]);
  const [lineStyle, setLineStyle] = useState({ width: 0 });

  const tabsContainerRef = useRef();
  const moreButtonRef = useRef();
  const isMountedRef = useRef();
  const remSizeRef = useRef(
    Number(window.getComputedStyle(document.body).getPropertyValue('font-size').replace('px', ''))
  );

  const containerRef = useRef();
  const {isMobileView} = useDeviceData();
  const {setHorizontalScrollerFocusData} = useHorizontalScroller(containerRef, isMobileView && !initToCenter, initToCenter, "drag");

  const getTabWidth = (tab) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = `<button class="${buttonStyles.button} ${buttonStyles.link} ${buttonStyles.medium}">${tab.text}</button>`;
    tempElement.className = styles.tab;
    tempElement.style.opacity = 0;
    tempElement.style.position = 'absolute';
    tabsContainerRef.current.appendChild(tempElement);
    const tempWidth = tempElement.getBoundingClientRect().width + (3.75 * remSizeRef.current);

    tabsContainerRef.current.removeChild(tempElement);

    return tempWidth;
  };



  const setLine = useCallback((value) => {
    setTimeout(() => {
      if (isMountedRef.current && containerRef && containerRef.current) {
        const el = containerRef.current.querySelector(`[data-value="${value}"]`);
        if (el) {
          const isRtl = intl.locale !== 'en';
          const boundingClientRect = el.getBoundingClientRect();
          const p = containerRef.current;
          const containerClientRect = p.getBoundingClientRect();
          const width = boundingClientRect.width;
          const leftT = containerClientRect.left - boundingClientRect.left - (16 * 0.5);
          const rightT = containerClientRect.right - boundingClientRect.right - (16 * 0.5);
          const left = isRtl ? 'auto' : leftT;
          const right = isRtl ? rightT : 'auto';

          setHorizontalScrollerFocusData({ left: leftT, right: rightT, width: width, dir: isRtl ? 'rtl' : 'ltr' });
          setLineStyle({ left, right, width });
        } else {
          setHorizontalScrollerFocusData(null);
          setLineStyle({ display: 'none' });
        }
      }
    }, 0);
  }, [intl.locale, setHorizontalScrollerFocusData]);

  const setTabs = useCallback(() => {
    setVisibleTabs([]);
    setMoreTabs([]);
    if (containerRef.current) {
      let visibleTabsWidth = 0;

      const buttonWidth = moreTabs.length > 0 && moreButtonRef.current ? moreButtonRef.current.getBoundingClientRect().width : 0;
      const containerWidth = containerRef.current.parentNode.getBoundingClientRect().width;

      const tempVisibleTabs = addAllTab
        ? [
          {
            value: 'all',
            text: getGenderFormatMessage('all'),
            totalCount: -1,
          },
        ]
        : [];

      const allTabWidth = addAllTab ? getTabWidth(tempVisibleTabs[0]) : 0;

      visibleTabsWidth = allTabWidth;

      const tempMoreTabs = [];

      tabs.forEach((tab) => {
        const tabWidth = getTabWidth(tab);
        if (visibleTabsWidth + tabWidth < containerWidth - buttonWidth - 16 - cutWidth || isMobileView) {
          tempVisibleTabs.push(tab);

          visibleTabsWidth += tabWidth;
        } else {
          tempMoreTabs.push(tab);
        }
      });

      setVisibleTabs(tempVisibleTabs);
      setMoreTabs(tempMoreTabs);
      setLine(activeTabKey);
    }
  }, [moreTabs.length, addAllTab, getGenderFormatMessage, tabs, setLine, activeTabKey, cutWidth, isMobileView]);

  const handleTabSelected = (tab) => {
    if (typeof onTabClick === 'function') {
      onTabClick(tab);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setTabs();
  }, [tabs, setTabs]);

  useEffect(() => {
    let timer = null;
    let isOn = false;

    const debounce = (e) => {
      if (e.initUIEvent) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        setTabs();
      }
      else {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        timer = setTimeout(() => {
          timer = null;
          setTabs();
        }, 100);
      }
    };

    setTabs();
    if (!isMobileView) {
      isOn = true;
      window.addEventListener('resize', debounce);
    }

    return () => {
      if (isOn) {
        window.removeEventListener('resize', debounce);
        isOn = false;
      }

      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [isMobileView, activeTabKey, cutWidth, setTabs]);

  useEffect(() => {
    setLine(activeTabKey);
  }, [activeTabKey, containerRef, setLine]);

  return (
    <>
      <div className={`${styles.container} ${styles['container--' + skin]}`} ref={containerRef}>
        <div ref={tabsContainerRef} className={styles.tabs}>
          {visibleTabs.map((tab) => {
            return (
              <div id={`tab-${tab?.id || tab.value}`} key={tab.value} className={`${styles.tab} ${activeTabKey === tab.value || tab.isChecked ? styles.active : ''}`}>
                <Button aria-label={tab.text} data-value={tab.value} type="link" data-title={tab.text} onClick={() => handleTabSelected(tab)}>
                  {tab.text}
                </Button>
              </div>
            );
          })}
        </div>
        {isMobileView && <div className={styles.line} style={lineStyle} />}
        {!isMobileView && moreTabs.length > 0 && <DropDown
          withArrow={false}
          listClassName={styles.selectList}
          disabled={moreTabs.length < 1}
          closeOnScroll={true}
          closeOnResize={true}
          label={
            <div
              ref={moreButtonRef}
              className={`${styles.moreButton} ${moreTabs.find((option) => activeTabKey === option.value) ? styles.active : ''
                } ${moreTabs.length > 0 ? styles.visible : ''}`}
            >
              <div className={styles.dots}>
                <div className={styles.dot}>.</div>
                <div className={styles.dot}>.</div>
                <div className={styles.dot}>.</div>
              </div>
              <div className={styles.label}>{getGenderFormatMessage('more')}</div>
            </div>
          }
          options={moreTabs}
          onSelect={handleTabSelected}
        >
          {(option) => (
            <div className={`${styles.tab} ${activeTabKey === option.value ? styles.active : ''}`}>
              <Button data-index={999} type="link" title={option.text} onClick={() => handleTabSelected(option, 0)}>
                {option.text}
              </Button>
            </div>
          )}
        </DropDown>}
      </div>
      {!isMobileView && <div className={styles.line} style={lineStyle} />}
    </>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      totalCount: PropTypes.number,
      isChecked: PropTypes.bool,
    })
  ),
  addAllTab: PropTypes.bool,
  onTabClick: PropTypes.func.isRequired,
  skin: PropTypes.oneOf(['tabs', 'chips'])
};

export default Tabs;
