import PropTypes from 'prop-types';

export const buttonPropTypes = {
  lang: PropTypes.oneOf(['he', 'ar', 'en']),
  sitekey: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  target: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'danger', 'none', 'light']),
    PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
      color: PropTypes.string
    })
  ]),
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large', 'x-large']),
  view: PropTypes.oneOf(['contained', 'outlined', 'text', 'link']),
  shape: PropTypes.oneOf(['ellipse', 'round']),
  className: PropTypes.string,
  category: PropTypes.string,
  label: PropTypes.string,
  optionalData: PropTypes.object,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool
};
