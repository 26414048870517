import { inverseObject } from "../utils/objects";

const types = {
  initSectors: 'initSectors',
  initSectorsByDisciplines: 'initSectorsByDisciplines',
  setSectors: 'setSectors',
};

const reducer = (state = {}, action) => {
  const { sectorsByDisciplines, user, language } = action;
  let sectors = language === "ar" ? ["arab"] : ["state", "mmad"]; // Guest users, define sectors by language
  let relevantDisciplinesBySectors = {};

  switch (action.type) {
    case types.initSectors:
        return { ...state, sectors };

    case types.initSectorsByDisciplines:
    {
      const disciplinesBySectors = inverseObject(sectorsByDisciplines);
      const disciplineByLanguage = language === 'he' ? [...new Set([...disciplinesBySectors['state'], ...disciplinesBySectors['mmad'], ...disciplinesBySectors['haredi']])] : disciplinesBySectors['arab'];
      relevantDisciplinesBySectors = disciplineByLanguage;

      return {...state, sectorsByDisciplines, disciplinesBySectors, disciplineByLanguage, relevantDisciplinesBySectors};
    }
    case types.setSectors: {
      // Logged in user
      const isExtendedCatalog = user?.isExtendedCatalog; // Teachers that explicitly belong to more than one sector
      const sectorId = user?.lastSchool?.sectorId || ''; // Teachers that belong to one sector (default case)

      sectors = sectorId ?
          isExtendedCatalog ? ["state", "mmad", "arab"] : [sectorId] :
          language === "ar" ? ["arab"] : ["state", "mmad"];

      const disciplinesBySectors = inverseObject(state.sectorsByDisciplines);

      relevantDisciplinesBySectors = [...new Set(sectors.map(sector => disciplinesBySectors[sector]).flat())];
      return {...state, sectors, disciplinesBySectors, relevantDisciplinesBySectors};
    }
    default:
      return state;
  }
};
/**
 * @param {string} language
 * @returns {{language, type: string}}
 */
export const initSectors = (language) => ({
  type: types.initSectors,
  language
});
/**
 * @param {object} sectorsByDisciplines
 * @param {string} language
 * @returns {{sectorsByDisciplines, language, type: string}}
 */
export const initSectorsByDisciplines = (sectorsByDisciplines, language) => ({
  type: types.initSectorsByDisciplines,
  sectorsByDisciplines,
  language
});
/**
 * Re-set sectors after user logged in
 * @param {object} user
 * @param {string} language
 * @returns {{language, type: string, user}}
 */
export const setSectors = (user, language) => ({
  type: types.setSectors,
  user,
  language
});

export default reducer;
