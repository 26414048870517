import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useCetEvents } from 'cet-components-lib/dist/hooks';
import {isFullUrl} from '../../utils/urls';

/** This is a wrapper around react-router-dom Link component */
export default function Href({ children, target = '_top', to = '#', onClick, category, label, optionalData, ...rest }) {
  const { sendUiUsageEvent } = useCetEvents();

  const handleOnClick = (event) => {
    if (category && label){
      sendUiUsageEvent({category, label, optionalData})
    }
    if (onClick){
      onClick(event);
    }
  }
  return (
    <>
      {target === '_top' && !isFullUrl(to) && (to+'').substring(0, 11) !== "javascript:" ? (
        <Link to={to} {...rest} onClick={handleOnClick}>
          {children}
        </Link>
      ) : (
        <a href={to} target={target} {...rest} onClick={handleOnClick}>
          {children}
        </a>
      )}
    </>
  );
}

Href.propTypes = {
  children: PropTypes.node.isRequired,
  target: PropTypes.oneOf(['_top', '_blank']),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.string,
    }),
  ]),
  onClick: PropTypes.func,
  category:PropTypes.string,
  label:PropTypes.string,
  optionalData:PropTypes.object
};
