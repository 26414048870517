import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFreeStatus, updateAuthorizationStatus } from '../state/items';

export const useProductAuthorization = () => {
  const dispatch = useDispatch();

  const { user, productSubscriptions, itemsFreeStatus, itemsAuthorizationStatus } = useSelector(({ user, items }) => ({
    productSubscriptions: user.productSubscriptions,
    user: user.info,
    itemsFreeStatus: items.freeStatus,
    itemsAuthorizationStatus: items.authorizationStatus
  }));

  const checkIsFreeItem = useCallback(
    async (externalId, type) => {
      return new Promise(async (resolve, reject) => {
        if (itemsFreeStatus.hasOwnProperty(externalId)) {
          resolve(itemsFreeStatus[externalId]);
        } else {
          //TODO: should be taken from item.isNeedPayment
          switch (type) {
            case 'Lo':
              dispatch(updateFreeStatus({ [externalId]: true }));
              resolve(true);
              break;
            default:
              dispatch(updateFreeStatus({ [externalId]: false }));
              resolve(false);
              break;
          }
        }
      });
    },
    [dispatch, itemsFreeStatus]
  );

  const getAuthorizationInfoTillTimeout = (apiFunc, apiData, callback, timeout) => {
    let isRealResponse = false;
    let isTimeoutResponse = false;

    apiFunc(apiData)
      .then(response => {
        if (!isTimeoutResponse) {
          isRealResponse = true;
          const responseObj = JSON.parse(response);
          callback(responseObj);
        }
      })
      .catch(error => {
        console.error(error);
      });

    window.setTimeout(() => {
      if (!isRealResponse) {
        isTimeoutResponse = true;
        const responseObj = { value: true };
        callback(responseObj);
      }
    }, timeout);
  };

  const checkIsAuthorizedItem = useCallback(
    async (externalId, type, parentId, lang) => {
      return new Promise(async (resolve, reject) => {
        if (user?.userId && itemsAuthorizationStatus.hasOwnProperty(externalId)) {
          resolve(itemsAuthorizationStatus[externalId]);
        } else {
          if (!user?.userId) {
            checkIsFreeItem(externalId, type).then(async isFree => {
              resolve(isFree);
            });
          } else {
            if (user.role === 'admin') {
              dispatch(updateAuthorizationStatus({ [externalId]: true }));
              resolve(true);
            } else {
              switch (type.toLowerCase()) {
                case 'course':
                case 'externalcourse':
                  // const productSubscription = productSubscriptions?.filter(
                  //   productSubscription => productSubscription.source === 'capsules' && productSubscription.folderID?.toLocaleLowerCase() === externalId.toLocaleLowerCase()
                  // );
                  // const isCourseAllowed = productSubscription?.length > 0;
                  // dispatch(updateAuthorizationStatus({ [externalId]: isCourseAllowed }));
                  // resolve(isCourseAllowed);
                  dispatch(updateAuthorizationStatus({ [externalId]: true }));
                  resolve(true);
                  break;
                case 'book':
                  // getAuthorizationInfoTillTimeout(
                  //   window.cet.microservices.contentapi.mybag.isBookAllowedToUser,
                  //   { nBookID: externalId, gUserID: user.userId, gSchoolID: user.schoolId },
                  //   response => {
                  //    dispatch(updateAuthorizationStatus({ [externalId]: response.value }));
                  //    resolve(response.value);
                  //   },
                  //   3000
                  // );
                  dispatch(updateAuthorizationStatus({ [externalId]: true }));
                  resolve(true);
                  break;
                case 'lo':
                  if (/^[0-9]+$/.test(externalId)) {
                    dispatch(updateAuthorizationStatus({ [externalId]: true }));
                    resolve(true);
                  } else {
                    getAuthorizationInfoTillTimeout(
                      window.cet.microservices.contentapi.mybag.getAuthorizationInfo,
                      { itemID: externalId, loggedInUserID: user.userId, schoolID: user.schoolId, cultureName: lang },
                      response => {
                        const isLoAllowed = response.value.hasOwnProperty('isAuthorized') ? response.value.isAuthorized : response.value;
                        dispatch(updateAuthorizationStatus({ [externalId]: isLoAllowed }));
                        resolve(isLoAllowed);
                        if (parentId) {
                          checkIsAuthorizedItem(parentId, 'Course', null);
                        }
                      },
                      3000
                    );
                  }
                  break;
                default:
                  resolve(true);
                  break;
              }
            }
          }
        }
      });
    },
    // eslint-disable-next-line no-use-before-define
    [checkIsFreeItem, dispatch, itemsAuthorizationStatus, user]
  );

  return {
    checkIsAuthorizedItem,
    checkIsFreeItem
  };
};
