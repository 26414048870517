import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import styles from './StuckMenu.module.scss';

const StuckMenu = ({
  fixOn = true, 
  layoutStart, 
  layoutCenter, 
  layoutEnd, 
  layoutBottom, 
  layoutTop, 
  stuckMenuClassName = '', 
  stuckUpClassName ='', 
  layoutStartClassName = '', 
  layoutCenterClassName = '', 
  layoutEndClassName = '',
  layoutBottomClassName = '', 
  layoutTopClassName = '',
  type
}) => {
  const intl = useIntl();

  return <>
    <div data-scrolling-handler-element="stuckMenuPadding"></div>
    <div className={`${styles.stuckMenu} ${stuckMenuClassName}`} data-is_top='start' data-scrolling-handler-element="stuckMenu" data-scrolling-handler-data-type={type} data-scrolling-handler-data-fixon={fixOn ? '1' : '0'} data-scrolling-handler-data-isrtl={intl && (intl.locale === 'he' || intl.locale === 'ar') ? '1' : '0'}>
      <div className={`${styles.stuckUp} ${styles.contentPadding} ${stuckUpClassName}`} data-scrolling-handler-element="stuckMenuUp">
        <div className={`${styles.layoutTop} ${layoutTopClassName}`}>{layoutTop}</div>
        <div className={`${styles.layoutCenter} ${layoutCenterClassName}`} data-scrolling-handler-element="stuckMenuCenter">{layoutCenter}</div>
        <div className={`${styles.layoutStart} ${layoutStartClassName}`} data-scrolling-handler-element="stuckMenuStart">{layoutStart}</div>
        <div className={`${styles.layoutEnd} ${layoutEndClassName}`} data-scrolling-handler-element="stuckMenuEnd">{layoutEnd}</div>
      </div>
      <div className={`${styles.layoutBottom} ${layoutBottomClassName}`}>{layoutBottom}</div>
    </div>
  </>;
};

StuckMenu.propTypes = {
  layoutStart:  PropTypes.any,
  layoutCenter:  PropTypes.any,
  layoutEnd:  PropTypes.any,
  layoutBottom:  PropTypes.any,
  layoutTop:  PropTypes.any,
  fixOn: PropTypes.bool
};

export default StuckMenu;