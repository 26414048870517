import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useUserProfile, useGenderFormatMessage } from '../../hooks';
import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';
import { capitalizeFirstLetter } from '../../utils/urls';
import { UiContext } from '../../contexts';

import Icon from '../Icon';
import Button from '../Button';
import CheckBox from '../CheckBox';
import Title from '../Title';
import Input from '../Input';

import styles from './FavoritesForm.module.scss';

const FavoritesForm = ({
  onClose,
  onTotalClose,
  itemName,
  itemId,
  itemExternalId,
  itemType,
  itemUrl,
  itemAgeGrades,
  itemDisciplines,
  favoritesData,
  userId,
  setPopupClass,
}) => {
  const {isMobileView} = useDeviceData();
  const [{ }, updateUiData] = useContext(UiContext);
  const { sendLearningEvent } = useCetEvents();

  const convertFavoritesListToCategoriesList = (array) => {
    let list = [];
    if (array?.length) {
      for (let i = 0; i < array.length; i++) {
        let isChecked = !!array[i].favorites.find((fav) => fav.contentCatalogId === itemId); //// true = item exists in category. otherwise, false.

        list[i] = {
          key: i,
          value: array[i].id,
          labelText: array[i].name,
          checkBoxName: 'favoriteLists',
          isChecked: isChecked,
          onChange: handleOnCheckBoxChange,
          lastUpdatedDate: array[i].lastUpdatedDate,
          isLastNewCategory: false,
        };
        if (isChecked) {
          favoritesData[i].selected = true;
        }
        if (lastNewCategoryName && array[i].name === lastNewCategoryName) {
          list[i].isChecked = true;
          list[i].isLastNewCategory = true;
          favoritesData[i].selected = true;
        }
      }
    }

    //Sort categories first by selected and then by date (descending order)
    list.sort(function (a, b) {
      if (a.isChecked === b.isChecked) {
        return new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate);
      } else if (a.isChecked) {
        return -1;
      } else {
        return 1;
      }
    });
    return list;
  };

  const [categories, setCategories] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [mode, setMode] = useState('default');
  const [errorType, setErrorType] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [categoryIsCreated, setCategoryIsCreated] = useState(true);
  const [invalidCategoryName, setInvalidCategoryName] = useState(false);
  const [favoritesToDelete, setFavoritesToDelete] = useState([]);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [btnSaveNewCategoryDisabled, setBtnSaveNewCategoryDisabled] = useState(true);
  const [lastNewCategoryName, setLastNewCategoryName] = useState('');
  const [generalCategoryId, setGeneralCategoryId] = useState('');
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { addFavoriteCategory, loadFavorites, assigningFavoriteToCategories, deleteFavoriteCategory } = useUserProfile();

  const loadCurrentFavorites = () => {
    let categoriesArray = convertFavoritesListToCategoriesList(favoritesData);
    setCategories(categoriesArray);
    let categoriesChecked = categoriesArray.filter((category) => category.isChecked).map((category) => category.value);
    setCheckedCategories(categoriesChecked);
  };

  useEffect(() => {
    if (favoritesData?.length === 0) {
      //if no categories --> create new general category
      addFavoriteCategory({
        teacherId: userId,
        name: getGenderFormatMessage('favForm_generalCategory'),
      }).then(
        id => {
          setGeneralCategoryId(id);
        }
      );
    }
    loadFavorites(userId); //for first init
  }, []);

  useEffect(() => {
    setPopupClass(mode === 'error' ? styles.popupError : false)
  }, [mode]);

  useEffect(() => {
    loadCurrentFavorites();
  }, [favoritesData]);

  const handleOnCheckBoxChange = (e) => {
    let index = favoritesData.findIndex((x) => x.id === e.target.value);
    favoritesData[index].selected = e.target.checked;
    let listDelete = favoritesToDelete;
    if (e.target.checked === false && !listDelete.find((x) => x === favoritesData[index].id)) {
      let itemToDelete = favoritesData[index].favorites.find((x) => x.contentCatalogId === itemId);
      if (itemToDelete) {
        listDelete.push(itemToDelete.id);
      }
    }
    setFavoritesToDelete(listDelete);
    const favoritesDataChecked = favoritesData.filter((category) => category.selected).map((category) => category.id);
    if (checkedCategories.sort().join(',') === favoritesDataChecked.sort().join(',')) {
      setBtnSaveDisabled(true);
    } else {
      setBtnSaveDisabled(false);
    }
  };

  const getAddedToCategories = () => {
    const added = [];
    favoritesData.forEach((category) => {
      const originalCategory = categories.filter((originalCategory) => originalCategory.value === category.id);
      if (category.selected && (!originalCategory[0].isChecked || originalCategory[0].isLastNewCategory)) {
        added.push(category.id);
      }
    });
    return added;
  };

  const sendFavoriteLearningEvent = (verb, categoryIds) => {
    const isWordpressType = (itemType === 'Lo' && /^[0-9]+$/.test(itemExternalId));
    let wordpressType = '';
    if (isWordpressType) {
      const url = new URL(itemUrl);
      wordpressType = capitalizeFirstLetter(url.hostname.replace('www.', '').split('.')[0]);
    }

    let optionalData = {
      interactionType: isWordpressType ? wordpressType : itemType,
      objectAdditionalInformation: { categoryIds: categoryIds }
    };
    if (itemType === 'Lo' && !isWordpressType) {
      optionalData.contentAgeGroup = itemAgeGrades;
      optionalData.contentSubject = itemDisciplines;
    }

    let key = (itemType === 'Book' || itemType === 'Course' || isWordpressType) ? 'contentGroupingId' : 'loId';
    optionalData[key] = itemExternalId;

    sendLearningEvent({
      verbType: verb,
      objectId: document.URL + (document.URL.includes('?fav') ? '' : '?fav'),
      objectName: 'favorite',
      objectType: 'favorite',
      optionalData,
    });
  };

  const assignFavoriteToCategories = () => {
    const selectedCategories = favoritesData.filter((category) => category.selected);
    const selectedCategoriesIds = selectedCategories.map((category) => category.id);
    const favoritesToAdd = getAddedToCategories();
    let messageId;
    assigningFavoriteToCategories({
      teacherId: userId,
      categoriesIds: selectedCategoriesIds,
      contentCatalogId: itemId,
      title: itemName,
      itemContext: itemType,
    })
      .then(() => {
        if (favoritesToAdd.length > 0 && favoritesToDelete.length > 0) {
          messageId = 'fav_change_success';
          sendFavoriteLearningEvent('added', favoritesToAdd);
          sendFavoriteLearningEvent('removed', favoritesToDelete);
        } else if (favoritesToAdd.length > 0) {
          messageId = 'fav_add_success';
          sendFavoriteLearningEvent('added', favoritesToAdd);
        } else if (favoritesToDelete.length > 0) {
          messageId = 'fav_delete_success';
          sendFavoriteLearningEvent('removed', favoritesToDelete);
        } else {
          messageId = '';
        }
        if (messageId) {
          updateUiData({ snackbarMessage: `${getGenderFormatMessage(messageId)}` });
        }
        setFavoritesToDelete([]);
        onClose();
      })
      .catch((error) => {
        if (favoritesToAdd.length > 0 && favoritesToDelete.length > 0) {
          setErrorType('updateFavorites');
        } else if (favoritesToAdd.length > 0) {
          setErrorType('addToFavorites');
        } else if (favoritesToDelete.length > 0) {
          setErrorType('removeFromFavorites');
        }
        setMode('error');
      });

    getAddedToCategories();

    if (generalCategoryId) {
      let isNewGeneralCategoryInUse = false;
      favoritesData.forEach(fav => {
        if (fav.id === generalCategoryId && fav.selected) {
          isNewGeneralCategoryInUse = true;
        }
      });
      if (!isNewGeneralCategoryInUse) {
        //Removing General category if empty
        deleteFavoriteCategory(generalCategoryId);
      }
    }

  };

  const handleAddToFavorites = () => {
    assignFavoriteToCategories();
  };

  // Add category to categories list [via API]
  const handleAddFavoriteCategory = (value) => {
    if (!categoryIsCreated) {
      if (newCategoryName) {
        setCategoryIsCreated(true);
        addFavoriteCategory({
          teacherId: userId,
          name: newCategoryName,
        })
          .then(() => {
            setBtnSaveDisabled(false);
            loadCurrentFavorites();
            setLastNewCategoryName(newCategoryName);
            setMode('default');
            setCategoryIsCreated(false);
          }).catch(error => {
            setErrorType('addCategory');
            setMode('error');
            setCategoryIsCreated(false);
          });
      } else {
        setInvalidCategoryName(true);
      }
    }
  };

  //Switch to Add-new-category mode
  const handleAddCategory = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setNewCategoryName('');
    setInvalidCategoryName(false);
    setCategoryIsCreated(false);
    setBtnSaveNewCategoryDisabled(true);
    setMode('addCategory');
  };

  const handleCancel = (event) => {
    setMode('default'); // return to list mode
  };

  const handleBackFromError = (event) => {
    if (errorType === 'addCategory')
      setMode('addCategory');
    else
      setMode('default');
  };

  const handleInputChanged = (event) => {
    setNewCategoryName(event.target.value);
    setInvalidCategoryName(false);
    setBtnSaveNewCategoryDisabled(event.target.value?.length > 0 ? false : true);
  };

  useEffect(() => {
    if (!userId) {
      setMode('guest');
    } else {
      setMode('default');
    }
  }, [userId]);

  return (
    <div className={`${styles.container} ${styles[mode + 'Container']}`}>
      {(() => {
        switch (mode) {
          case 'default':
            return <>
              <header>
                <Title level={2}>{getGenderFormatMessage('fav_title')}</Title>
                <Title level={1}>{itemName}</Title>
                {isMobileView && !(document.URL.includes('?fav') || document.URL.includes('&fav')) && <div className={styles.mobileBackButton} onClick={onClose}><Icon icon="icon_back_arrow_left" width={18} height={16} /></div>}
                {isMobileView && (document.URL.includes('?fav') || document.URL.includes('&fav')) && <button className={styles.mobileCloseButton} onClick={onClose}><Icon icon="close" width={13.231} height={13.231} /></button>}
              </header>
              <main>
                <Title level={3}>{getGenderFormatMessage('fav_saveAt')}</Title>
                <ul className={styles.checkBoxList}>
                  {
                    categories.map((list) => {
                      return (<CheckBox {...list} id={list.key} />)
                    })
                  }
                </ul>
              </main>
              <footer>
                {!isMobileView && <>
                  <Button
                    type="link"
                    size="medium"
                    className={styles.btnCancel}
                    onClick={onClose}
                    category="favoritesFlow"
                    label="cancelFavorites_defaultView">
                    {getGenderFormatMessage('favForm_cancel')}
                  </Button>
                  <div>
                    <Button
                      type="default"
                      className={styles.btnNewCategory}
                      onClick={handleAddCategory}
                      category="favoritesFlow"
                      label="createCategory">
                      <strong>+</strong>&nbsp;{getGenderFormatMessage('fav_newCategory')}</Button>
                    <Button
                      type="primary"
                      disabled={btnSaveDisabled}
                      className={styles.btnPrimary}
                      onClick={handleAddToFavorites}
                      category="favoritesFlow"
                      label="saveToFavorites">
                      {getGenderFormatMessage('fav_save')}</Button>
                  </div>
                </>}
                {isMobileView && <>
                  <Button
                    type="default"
                    className={styles.btnNewCategory}
                    onClick={handleAddCategory}
                    category="favoritesFlow"
                    label="createCategory">
                    <strong>+</strong>&nbsp;{getGenderFormatMessage('fav_newCategory')}</Button>
                  <Button
                    type="primary"
                    disabled={btnSaveDisabled}
                    className={styles.btnPrimary}
                    onClick={handleAddToFavorites}
                    category="favoritesFlow"
                    label="saveToFavorites">
                  {getGenderFormatMessage('fav_save')}</Button>
                </>}
              </footer>
            </>;
          case 'addCategory':
            return <>
              <header>
                <Title level={2}>{getGenderFormatMessage('fav_title')}</Title>
                <Title level={1}>{itemName}</Title>
                {isMobileView && <div className={styles.mobileBackButton} onClick={handleCancel}><Icon icon="icon_back_arrow_left" width={18} height={16} /></div>}
              </header>
              <main>
                <div className={styles.addCategoryContainer}>
                  <Input
                    type="text"
                    placeholder={getGenderFormatMessage('fav_chooseName')}
                    aria-label={getGenderFormatMessage('fav_chooseName')}
                    containerClassName={styles.input}
                    className={(invalidCategoryName && !newCategoryName ? styles.invalid : '')}
                    maxLength={50}
                    onChange={handleInputChanged}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter' || event.keyCode === 13) {
                        event.preventDefault();
                        handleAddFavoriteCategory();
                      }
                    }}
                    autoFocus={mode === 'addCategory'}
                  ></Input>
                </div>
              </main>
              <footer>
                <Button
                  type="link"
                  className={styles.btnCancel}
                  onClick={handleCancel}
                  category="favoritesFlow"
                  label="cancelFavorites_addCategoryView">
                  {getGenderFormatMessage('fav_cancel')}</Button>
                <Button
                  type="primary"
                  disabled={btnSaveNewCategoryDisabled}
                  className={`${styles.btnPrimary} ${styles.btnCreate}`}
                  onClick={handleAddFavoriteCategory}
                  category="favoritesFlow"
                  label="saveNewCategory">
                  {getGenderFormatMessage('fav_create')}</Button>
              </footer>
            </>;
          case 'guest':
            return <>
              <header className={styles.guestHeader}>
                <Title level={2}>{getGenderFormatMessage('favForm_noLogin_title')}</Title>
                {isMobileView && !(document.URL.includes('?fav') || document.URL.includes('&fav')) && <div className={styles.mobileBackButton} onClick={onClose}><Icon icon="icon_back_arrow_left" width={18} height={16} /></div>}
                {isMobileView && (document.URL.includes('?fav') || document.URL.includes('&fav')) && <button className={styles.mobileCloseButton} onClick={onClose}><Icon icon="close" width={13.231} height={13.231} /></button>}
              </header>
              <main>
                <p className={styles.guestLine1}>{getGenderFormatMessage('favForm_noLogin_line1_start')} <Button onClick={() => { updateUiData({ showLogin: true }); }} type="link" category="favoritesPopup" label="login">{getGenderFormatMessage('favForm_noLogin_loginBtn')}</Button> {getGenderFormatMessage('favForm_noLogin_line1_end')}</p>
                <p className={styles.guestLine2}>{getGenderFormatMessage('favForm_noLogin_line2')}</p>
                <div className={styles.mainImage}></div>
              </main>
              <footer className={styles.guestFooter}>
                <Button
                  type="line"
                  onClick={onClose}
                  size="medium"
                  className={styles.footerButton}
                  category="favoritesFlow"
                  label="cancelFavorites_guestView"
                >{getGenderFormatMessage('back')}</Button>
              </footer>
            </>;
          case 'error':
            return <>
              <header>
                <div className={styles.title}>
                  <div className={styles.titleIcon}>
                    <Icon icon="broken_heart"></Icon>
                  </div>
                  <Title level={2} className={styles.error}>{getGenderFormatMessage('favForm_error_' + errorType)}</Title>
                </div>
                {isMobileView && <div className={styles.mobileBackButton} onClick={handleBackFromError}><Icon icon="icon_back_arrow_left" width={18} height={16} /></div>}
                <Title level={1}>{itemName}</Title>
              </header>
              <main>
                <div className={styles.errorMessage}>
                  {getGenderFormatMessage('favForm_try_again')}
                </div>
              </main>
              <footer className={styles.guestFooter}>
                <Button
                  type="line"
                  onClick={isMobileView ? onClose : handleBackFromError}
                  size="medium"
                  className={styles.footerButton}
                  category="favoritesFlow"
                  label="cancelFavorites_errorView"
                >{getGenderFormatMessage(isMobileView ? 'close' : 'back')}</Button>
              </footer>
            </>;
          default:
            return null;
        }
      })()}
    </div>
  );
};

FavoritesForm.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  itemExternalId: PropTypes.string,
  itemType: PropTypes.string,
  itemUrl: PropTypes.string,
  itemAgeGrades: PropTypes.string,
  itemDisciplines: PropTypes.string,
  favoritesData: PropTypes.array,
  userId: PropTypes.string,
};

export default FavoritesForm;