import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './DropDown.module.scss';
import listStyles from '../DataList/DataList.module.scss';

import { selectedValuePropTypes, optionsPropTypes } from '../DataList/PropTypes';

import SelectButton from './Partials/SelectButton';
import SelectList from './Partials/SelectList';

let idCounter = 0;

const DropDown = React.forwardRef(
  (
    {
      buttonClassName,
      buttonCategory,
      buttonLabel,
      listClassName,
      listCategory,
      listLabel,
      options,
      selectedValue,
      onSelect,
      onItemFocus,
      label,
      ariaLabel,
      iconName,
      disabled = false,
      withArrow = true,
      withOmitSelected = false,
      openOnFocus = false,
      isMultiSelect = false,
      children,
      onFocus,
      onBlur,
      closeOnScroll = false,
      closeOnResize = false,
      id,
      mobileIconOnly = false,
      filterKey
    },
    outerRef
  ) => {
    
    const [isOpen, setIsOpen] = useState();
    const listRef = useRef();
    const isMountedRef = useRef();
    const containerRef = useRef();

    const currentOptionIndexRef = useRef();
    const [currentOptionValue, setCurrentOptionValue] = useState();
    const currentOptionRef = useRef();
    const parsedSelectedValue = useRef();
    const [uniqueId] = useState(`${filterKey || id || buttonClassName}-${Date.now()}-${idCounter++}`);
    const selectedOption = useMemo(() => options && options.find((o) => o.value === selectedValue), [options, selectedValue]);

    useEffect(() => {
      isMountedRef.current = true;

      return () => {
        isMountedRef.current = false;
      };
    }, []);

    useEffect(() => {
      if (isOpen && listRef?.current) {
        const firstChild = listRef.current.children[0];
        if (firstChild) {
          currentOptionRef.current = 0;
          firstChild.focus();
          currentOptionIndexRef.current = undefined;
        }
      }
    }, [isOpen]);

    useEffect(() => {
      let isActive = true;

      const close = () => {
        if (isActive) {
          setIsOpen(false);
        }
      };

      if (isOpen) {
        if (closeOnScroll) {
          document.querySelector('#rootScroll').addEventListener('scroll', close);
        }
        if (closeOnResize) {
          window.addEventListener('resize', close);
        }
      }

      return () => {
        isActive = false;
        if (isOpen) {
          if (closeOnScroll) {
            document.querySelector('#rootScroll').removeEventListener('scroll', close);
          }
          if (closeOnResize) {
            window.removeEventListener('resize', close);
          }
        }
      };
    }, [isOpen, closeOnScroll, closeOnResize])

    useEffect(() => {
      if (selectedValue && isMultiSelect) {
        if (Array.isArray(selectedValue)) {
          parsedSelectedValue.current = selectedValue;
        } else {
          parsedSelectedValue.current = [selectedValue];
        }
      } else {
        parsedSelectedValue.current = selectedValue;
      }
    }, [selectedValue, isMultiSelect]);

    useEffect(() => {
      const listener = (event) => {
        if (isOpen) {
          let path = event?.composedPath ? event.composedPath() : (event?.path || []);
          if (event.key === 'Escape' || event.key === 'Tab' || !path?.includes(containerRef.current)) {
            setIsOpen(false);
          }
        }
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
        document.addEventListener('keydown', listener);
      };
    }, [isMultiSelect, isOpen]);

    const handleInputBlur = (event) => {
      if (listRef.current?.contains(event.relatedTarget)) return;
      setTimeout(() => {
        if (isMountedRef.current && !isMultiSelect) {
          setIsOpen(false);
        }
      }, 300);

      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    };

    const handleInputFocus = useCallback(() => {
      if (typeof onFocus === 'function') {
        onFocus();
      }
      if (openOnFocus) {
        setTimeout(() => {
          if (isMountedRef.current) {
            setIsOpen(true);
          }
        }, 300);
      }
    }, [onFocus, openOnFocus]);

    const handleInputClick = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        setIsOpen(!isOpen);
      },
      [isOpen]
    );

    const handleInputKeyUp = useCallback((event) => {
      if (event.key === 'ArrowDown') {
        setIsOpen(true);
      }
    }, []);

    const handleSingleSelectedOptionsUpdate = useCallback(
      (option) => {
        setIsOpen(false);
        onSelect(option);
      },
      [onSelect]
    );

    const handleMultiSelectedOptionsUpdate = useCallback(
      (option) => {
        onSelect(option);
      },
      [onSelect]
    );

    const handleInputKeyPress = useCallback(
      (event) => {
        let { key } = event;
        const listElements = listRef.current?.querySelectorAll('li');
        if (!listElements) {
          return;
        }

        switch (key) {
          case 'ArrowDown':
            event.preventDefault();
            if (currentOptionIndexRef.current === undefined) {
              currentOptionIndexRef.current = 0;
            } else if (currentOptionIndexRef.current < listElements.length - 1) {
              currentOptionIndexRef.current++;
            }
            break;
          case 'ArrowUp':
            event.preventDefault();
            if (currentOptionIndexRef.current > 0) {
              currentOptionIndexRef.current--;
            }
            break;
          case 'Enter':
            {
              const selectedLi = listElements[currentOptionIndexRef.current];
              if (selectedLi) {
                const lists =
                  options?.length > 0 && options[0]?.hasOwnProperty('list')
                    ? options.reduce((previous, current) => {
                      return (previous = [...previous, ...current.list]);
                    }, [])
                    : [...options];

                const selected = lists.find((option) => {
                  return option.value.toString() === selectedLi.dataset.value.toString();
                });

                if (isMultiSelect) {
                  handleMultiSelectedOptionsUpdate(selected);
                } else {
                  handleSingleSelectedOptionsUpdate(selected);
                }
              }
            }
            break;
          case 'Escape':
            setIsOpen(false);
            break;
          case 'Home':
            currentOptionIndexRef.current = 0;
            break;
          case 'End':
            currentOptionIndexRef.current = listRef.current.children.length - 1;
            break;
          default:
            break;
        }

        const option = listElements[currentOptionIndexRef.current];
        if (option) {
          setCurrentOptionValue(option.dataset.value);
          if (typeof onItemFocus === 'function') {
            onItemFocus(option.dataset.value, listStyles.focused);
          }
          //Scroll list to focused option
          if (listRef.current.scrollHeight > listRef.current.clientHeight) {
            var scrollBottom = listRef.current.clientHeight + listRef.current.scrollTop;
            var elementBottom = option.offsetTop + option.offsetHeight;
            if (elementBottom > scrollBottom) {
              listRef.current.scrollTop = elementBottom - listRef.current.clientHeight + 10;
            } else if (option.offsetTop < listRef.current.scrollTop) {
              listRef.current.scrollTop = option.offsetTop - 10;
            }
          }
        }
      },
      [handleMultiSelectedOptionsUpdate, handleSingleSelectedOptionsUpdate, isMultiSelect, onItemFocus, options]
    );

    const handleOptionMouseEnter = (option) => {
      setCurrentOptionValue(option.value);
    };

    const handleOptionMouseLeave = (option) => {
      setCurrentOptionValue(null);
    };

    return (
      <div id={`dropdown-filter-${uniqueId}`} className={styles.container} ref={containerRef}>
        <SelectButton
          label={
            isMultiSelect ?
              `${label} ${parsedSelectedValue?.current?.length ? '(' + parsedSelectedValue?.current?.length + ')' : ''}`.trimEnd()
              : selectedValue && (options?.length > 0) ?
                options.find((option) => option.value.toString() === selectedValue.toString())?.text
                : label
          }
          disabled={disabled}
          ariaLabel={ariaLabel || label}
          iconName={iconName}
          ref={outerRef}
          className={buttonClassName}
          eventsCategory={buttonCategory}
          eventsLabel={buttonLabel}
          onKeyUp={handleInputKeyUp}
          onKeyDown={handleInputKeyPress}
          onClick={handleInputClick}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          isOpen={isOpen}
          withArrow={withArrow}
          selectedOption={isMultiSelect ? null : selectedOption}
          hasSelectedValue={isMultiSelect ? parsedSelectedValue.current?.length > 0 : Boolean(selectedValue)}
          id={id}
          mobileIconOnly={mobileIconOnly}
        />
        <SelectList
          ref={listRef}
          className={listClassName}
          eventsCategory={listCategory}
          eventsLabel={listLabel}
          options={options}
          selectedValue={parsedSelectedValue.current}
          focusedOptionValue={currentOptionValue}
          onSelect={handleSingleSelectedOptionsUpdate}
          onOptionMouseEnter={handleOptionMouseEnter}
          onOptionMouseLeave={handleOptionMouseLeave}
          label={label}
          withOmitSelected={withOmitSelected}
          children={children}
          isOpen={isOpen}
          isMultiSelect={isMultiSelect}
        />
      </div>
    );
  }
);

DropDown.propTypes = {
  options: optionsPropTypes,
  /** Button label when no option is selected */
  label: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonCategory: PropTypes.string,
  buttonLabel: PropTypes.string,
  listClassName: PropTypes.string,
  listCategory: PropTypes.string,
  listLabel: PropTypes.string,
  selectedValue: selectedValuePropTypes,
  /** A callback when an option is selected (Should update the selected value) */
  onSelect: PropTypes.func.isRequired,
  /** Can be either a render props function or a unordered list element. Enables a custom option display */
  children: function (props, propName, componentName) {
    if (props.children) {
      if (typeof props.children === 'function' && !props.options) {
        return new Error(componentName + ' - When passing children as function, options isRequired');
      } else if (props.children.type !== 'ul' && !props.options) {
        return new Error(componentName + ' - Static dropdown children must be an unordered list element (ul)');
      } else if (
        props?.children?.props?.children &&
        ((props.children.props.children.length > 1 &&
          props.children.props.children.filter((li) => !li.props['data-value']).length) ||
          (props.children.props.children.length === 1 && !props.children.props.children.prop['data-value']))
      ) {
        return new Error(
          componentName + ' - All static dropdown children list items must have a "data-value" property'
        );
      }
    }
  },
  /** When using static children, use the call back to set the focused item */
  onItemFocus: PropTypes.func,
  /** When set to false, arrow icon won't be shown */
  withArrow: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  /** When set to true, selected value will be omitted from the dropdown list */
  withOmitSelected: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  closeOnScroll: PropTypes.bool,
  closeOnResize: PropTypes.bool,
  id: PropTypes.string,
  mobileIconOnly: PropTypes.bool,
};
DropDown.displayName = 'DropDown';
export default DropDown;
