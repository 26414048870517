import { useEffect, useState } from 'react';
import { CacheProvider } from '../utils/cacheProvider';

export const useCachedState = (key, initialState) => {
  let cached = CacheProvider.get(key);
  cached = !cached || cached === 'undefined' || cached === 'null' ? null : cached;
  const [state, setState] = useState(cached ?? initialState);
  useEffect(() => {
    const save = () => CacheProvider.set(key, state);
    save();
    return () => save();
  }, [state, key]);
  return [state, setState];
};
