import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Tooltip from '../Tooltip';
import Icon from '../Icon';

import styles from './FavoritesButton.module.scss';

const FavoritesButton = ({ isInFavorites, addToFavoritesLabel, removeFromFavoritesLabel, className, onClick, category, label, optionalData, ariaLabel }) => (
  <Tooltip content={<div className={styles.tooltipContent}>{isInFavorites ? removeFromFavoritesLabel : addToFavoritesLabel}</div>}>
    <Button onClick={onClick} className={className} category={category} label={label}
            optionalData={optionalData} aria-label={ ariaLabel }
    >
      <Icon
        width={22}
        height={22}
        icon={isInFavorites ? 'favorites_on' : 'favorites'}
        strokeWidth={0}
        title={isInFavorites ? removeFromFavoritesLabel : addToFavoritesLabel}
      />
    </Button>
  </Tooltip>
);

FavoritesButton.propTypes = {
  isInFavorites: PropTypes.bool,
  addToFavoritesLabel: PropTypes.string.isRequired,
  removeFromFavoritesLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  category: PropTypes.string,
  label: PropTypes.string,
  optionalData: PropTypes.object,
    ariaLabel: PropTypes.string
};

export default FavoritesButton;
