import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';
import Href from '../../Href';

import styles from '../Preview.module.scss';

const Item = ({ title, data, contextURL, openOnStart=false, alwaysOpen=false }) => {
  const [isTableOfContentsOpen, setIsTableOfContentsOpen] = useState(alwaysOpen);

  useEffect(() => {
    setIsTableOfContentsOpen(alwaysOpen || openOnStart);
  }, [alwaysOpen, openOnStart]);

  return (
    <>
      {data && (
        <div className={styles.bordered}>
          <h3 className={`${styles.label} ${isTableOfContentsOpen ? styles.open : styles.close}`}>
            {!alwaysOpen && <button
              onClick={(event) => {
                event.stopPropagation();
                setIsTableOfContentsOpen(alwaysOpen || !isTableOfContentsOpen);
              }}
            >
              {title}
              <Icon icon="arrow" width="1.1rem" height=".9rem" />
            </button>}
            {alwaysOpen && title}
          </h3>
          <ul
            className={`${styles.list} ${styles.tableOfContent} ${isTableOfContentsOpen ? styles.open : styles.close} ${alwaysOpen ? styles.tableOfContentAlwaysOpen : ''}`}
          >
            {data.map(({ id, title, chapters }, index) => (
              <li key={index}>
                <Href to={`${contextURL}/${id}`} target="_blank">
                  <strong>{title}</strong>
                  <Icon icon="arrow" width=".5rem" height=".7rem" />
                </Href>
                {chapters && (
                  <ul className={`${styles.list}`}>
                    {chapters.map(({ id, title }, index) => (
                      <li key={index}>
                        <Href to={`${contextURL}/${id}`} target="_blank">
                          {title}
                          <Icon icon="arrow" width=".5rem" height=".7rem" />
                        </Href>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      chapters: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  contextURL: PropTypes.string.isRequired,
};

export default Item;
