import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMostPopularItems, useNewestItems, useEventsSearch } from '.';
import { append } from '../state/globalItems';
export const useGlobalItems = (isInTaskManagerIframe = false) => {
  const dispatch = useDispatch();

  const isReady = useSelector(state => !!(state.user.info && state.dimensions.dimensions));

  const fetchNewestItems = useNewestItems();
  const fetchMostPopularItems = useMostPopularItems();
  const fetchEventsItems = useEventsSearch();

  const [newestItems, setNewestItems] = useState(null);
  const [mostPopularItems, setMostPopularItems] = useState(null);
  const [eventsItems, setEventsItems] = useState(null);

  const [isLoadingNewestItems, setIsLoadingNewestItems] = useState(false);
  const [isLoadingMostPopularItems, setIsLoadingMostPopularItems] = useState(false);
  const [isLoadingEventsItems, setIsLoadingEventsItems] = useState(false);

  useEffect(() => {
    if (isReady && !isLoadingNewestItems && !isInTaskManagerIframe) {
      setIsLoadingNewestItems(true);
      fetchNewestItems().then(response => setNewestItems(response));
    }
  }, [fetchNewestItems, isLoadingNewestItems, isInTaskManagerIframe, isReady]);

  useEffect(() => {
    if (isReady && !isLoadingMostPopularItems && !isInTaskManagerIframe) {
      setIsLoadingMostPopularItems(true);
      fetchMostPopularItems().then(response => setMostPopularItems(response));
    }
  }, [fetchMostPopularItems, isLoadingMostPopularItems, isInTaskManagerIframe, isReady]);

  useEffect(() => {
    if (isReady && !isLoadingEventsItems && !isInTaskManagerIframe) {
      setIsLoadingEventsItems(true);
      fetchEventsItems().then(response => setEventsItems(response));
    }
  }, [fetchEventsItems, isLoadingEventsItems, isInTaskManagerIframe, isReady]);

  useEffect(() => {
    if (newestItems || mostPopularItems || eventsItems) {
      dispatch(append({ newestItems, mostPopularItems, eventsItems }));
    }
  }, [newestItems, mostPopularItems, eventsItems, dispatch]);
};
