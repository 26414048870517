import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './SearchBoxPopUp.module.scss';

import {useAutoComplete, useGenderFormatMessage} from '../../hooks';

import { Icon } from 'cet-components-lib/dist/UI/Icon';
import { Button } from 'cet-components-lib/dist/UI/Button';
import { Modal } from 'cet-components-lib/dist/Layout/Modal';
import SearchBox from '.';
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import {setAllExistingUrlParams} from "../../utils/urls";

const SearchBoxPopUp = ({
  isOpen,
  setIsSearchBoxOpen,
  onToggle,
  onFocus
}) => {
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [optionalData, setOptionalData] = useState(null);
  const searchButtonRef = useRef();
  const searchContainerRef = useRef();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const autoCompleteText = useRef('');
  const isAutoCompleteEnabled = useRef();
  const requestAutoComplete = useAutoComplete();
  const { isMobileView } = useDeviceData();


  const handleRequestAutoComplete = async (text, ageGrade, discipline) => {
    autoCompleteText.current = text;
    if (text?.length >= 3) {
      isAutoCompleteEnabled.current = true;
      const result = await requestAutoComplete(autoCompleteText.current, ageGrade, discipline);
      if (isBlocking  && isAutoCompleteEnabled.current) {
        if (autoCompleteText.current === text) {
          setAutoCompleteOptions(result);
        }
      }
    } else {
      setAutoCompleteOptions([]);
    }
  };
  const handleSubmitSearch = (text, ageGrades, discipline, subject, subSubject, isExactSearch) => {
    isAutoCompleteEnabled.current = false;
    document.querySelector('#rootScroll').scrollTo(0, 0);

    let ageGradesValue = null;
    let disciplineValue = null;
    let subjectValue = null;
    let subSubjectValue = null;
    const exactSearchValue = isExactSearch ? '1' : '0';

    if (ageGrades && ageGrades.length) {
      if (Array.isArray(ageGrades) && ageGrades[0]?.value !== 'all') {
        ageGradesValue = ageGrades.map(ageGrade => ageGrade.value).join(',');
      }
      else if (!Array.isArray(ageGrades) && ageGrades.value !== 'all') {
        ageGradesValue = ageGrades.value;
      }
    }
    if(discipline && discipline.value !== 'all') {
      disciplineValue = discipline.value;
    }
    if(subject && subject.value !== 'all') {
      subjectValue = subject.value;
    }
    if(subSubject && subSubject.value !== 'all') {
      subSubjectValue = subSubject.value;
    }

    const urlParams = setAllExistingUrlParams({allFields: text, ageGrades: ageGradesValue, disciplines: disciplineValue, syllabusSubjects: subjectValue, syllabusSubsubjects: subSubjectValue, exactSearch: exactSearchValue});
    urlParams.pathname = `/${intl.locale}/search`;
    history.push(urlParams);

    setAutoCompleteOptions([]);
    setIsBlocking(false);
  };

  const handleSearchBoxBlur = event => {
    if (!searchContainerRef.current.contains(event.relatedTarget)) {
      setIsBlocking(false);
      if (!history.location.search.includes('allFields=')) {
        setIsSearchBoxOpen(false);
      }
    }

    setAutoCompleteOptions([]);
  };


  const handleClose = () => {
    setIsSearchBoxOpen(false)
  }

  const handleSetOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();

    onToggle(true);

    if (typeof onFocus === 'function') {
      onFocus();
    }
  };


  return (
    <div className={styles.searchBoxPopUpWrapper} ref={searchContainerRef}>
      <Button
          id="header-toolbar_search-button"
          shape="round"
          view="alternate"
          ref={searchButtonRef}
          color="basic"
          size="large"
          ariaLabel={getGenderFormatMessage('search')}
          onClick={handleSetOpen}
          category={isOpen ? "upperSearch" : "upperToolbar"}
          label={isOpen ? "startStringSearch" : "openSearch"}
          optionalData={isOpen ? { fieldsResponse: optionalData, object_additional_information: optionalData } : {}}
      >
        <Icon name="Search" width="28" height="28"></Icon>
      </Button>
      <Modal
          isOpen={isOpen}
          isWithCloseButton={isMobileView}
          isCloseFromOutsideEnabled={true}
          onClose={handleClose}
          width="100%"
      >
        <Modal.Content>
          <SearchBox
              isOpen={isOpen}
              autoCompleteCategory="upperSearch"
              autoCompleteOptions={autoCompleteOptions}
              onFocus={() => {
                setIsBlocking(true);
              }}
              onBlur={handleSearchBoxBlur}
              onRequestAutoComplete={handleRequestAutoComplete}
              onSubmitSearch={handleSubmitSearch}
          />
        </Modal.Content>
      </Modal>
    </div>

  );
};

SearchBoxPopUp.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onFocus: PropTypes.func
};

export default SearchBoxPopUp;
