import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Checkbox } from 'cet-components-lib/dist/main.js';
import Modal from '../Modal';
import Button from '../Button';
import { getInnerUrl } from '../../utils/urls';
import { contentToHash } from '../../utils/hash';
import { CacheProvider } from '../../utils/cacheProvider';
import { safelyGetInnerHTML } from '../../utils/html';

import styles from './DominateSystemMessage.module.scss';

const DominateSystemMessage = ({
  title = '',
  userType = '',
  content = '',
  showButton = false,
  showHideMessageButton = false,
  buttonText = '',
  hideMessageButtonText = '',
  link = '',
  linkTarget = '',
  lang = '',
  show = false,
  withCloseButton = true,
  handleClose,
  children,
  style,
  userInfo
}) => {
  const history = useHistory();
  const [relativeLink, setRelativeLink] = useState(false);
  const [isShowDominateSystemMessage, setIsShowDominateSystemMessage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Toggle the checkbox state when clicked
  const handleCheckboxClick = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (userType === 'teacher' && userInfo) {
      localStorage.setItem(`dominateSystemMessageTeacher${userInfo}`, JSON.stringify(newCheckedState));
      const contentHash = contentToHash(content);
      localStorage.setItem(`dominateSystemMessageContentHash${lang}`, contentHash);
    }
  };

  useEffect(() => {
    const contentHash = contentToHash(content);
    const hashKey = `dominateSystemMessageContentHash${lang}`;
    const savedHash = localStorage.getItem(hashKey);

    // Function to reset the localStorage when content changes
    const resetLocalStorage = () => {
      Object.keys(localStorage).forEach(key => {
        if (key.includes('dominateSystemMessageTeacher')) {
          localStorage.removeItem(key);
        }
      });
      localStorage.setItem(hashKey, contentHash);
    };

    // Check if content hash has changed and reset local storage if needed
    if (savedHash !== contentHash) {
      resetLocalStorage();
    }

    const isClosed = CacheProvider.get('dominateSystemMessageClosed') ?? false;

    if (userType === 'teacher') {
      const userKey = `dominateSystemMessageTeacher${userInfo || ''}`;
      const savedState = JSON.parse(localStorage.getItem(userKey) || 'false');

      setIsChecked(savedState);
      setIsShowDominateSystemMessage(!savedState ? !isClosed : !savedState); // Show message only if unchecked
    } else {
      setIsShowDominateSystemMessage(!isClosed);
    }
  }, [userType, content, lang, userInfo]);

  useEffect(() => {
    setRelativeLink(getInnerUrl(link));
  }, [link]);

  const close = () => {
    console.log(handleClose);
    if (handleClose) {
      handleClose();
      CacheProvider.set('dominateSystemMessageClosed', 'true');
    }
  };

  const onContentClick = e => {
    const aTag = e.target.closest('a');
    if (aTag && e.currentTarget.contains(aTag)) {
      if (!aTag.target || aTag.target !== '_blank') {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        let linkTag = window.document.createElement('a');
        linkTag.href = getInnerUrl(aTag.href);
        let historyState = {
          pathname: linkTag.pathname,
          search: linkTag.search
        };
        history.push(`${historyState.pathname}${historyState.search}`, historyState);
      }
      close();
    }
  };

  return (
    <>
      {isShowDominateSystemMessage && (
        <div className={styles.dominateSystemMessage}>
          <Modal isActive={show} closeButtonLabel={''} onClose={close} className={styles.modal} withCloseButton={withCloseButton}>
            <div className={styles.systemMessagePopup}>
              <div className={styles.systemMessagePopup_scroller}>
                {title && <div className={styles.systemMessagePopup_title}>{title}</div>}
                {content && (
                  <div className={styles.systemMessagePopup_content} onClick={onContentClick} style={style}>
                    {safelyGetInnerHTML(content || '', true)}
                  </div>
                )}
                {!content && <div className={styles.systemMessagePopup_content}>{children}</div>}
              </div>
              {showButton && (
                <div className={styles.systemMessagePopup_footer}>
                  <Button onClick={close} {...(relativeLink && linkTarget ? { href: relativeLink, target: linkTarget } : {})} className={styles.systemMessagePopup_button}>
                    <span className={styles.systemMessagePopup_buttonText}>{buttonText}</span>
                  </Button>
                </div>
              )}
              {showHideMessageButton && (
                <div className={showButton ? styles.systemMessagePopup_checkbox_footer : styles.systemMessagePopup_footer}>
                  <div>
                    <Checkbox id="dont-show-checkbox" label={hideMessageButtonText} isChecked={isChecked} onClick={handleCheckboxClick} />
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

DominateSystemMessage.propTypes = {
  title: PropTypes.string,
  userType: PropTypes.string,
  content: PropTypes.string,
  showButton: PropTypes.bool,
  showHideMessageButton: PropTypes.bool,
  buttonText: PropTypes.string,
  hideMessageButtonText: PropTypes.string,
  link: PropTypes.string,
  linkTarget: PropTypes.oneOf(['', '_top', '_blank']),
  show: PropTypes.bool,
  handleClose: PropTypes.func
};

export default DominateSystemMessage;
