import React, {useContext, useRef, useEffect, useState} from 'react';
import YouTube from 'react-youtube';
import {UiContext} from '../../contexts';
import Icon from '../../components/Icon';
import styles from './VideoContainer.module.scss';

const VideoContainer = () => {
  const [{videoPlayerUrl}, updateUiData] = useContext(UiContext);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState(false);
  const [youtubeId, setYoutubeId] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => { 
    if (videoPlayerUrl) {
      setVideoUrl(videoPlayerUrl);
    }
  }, [videoPlayerUrl]);

  useEffect(() => {
    let isActive = true;
  
    setIsLoading(true);
    setYoutubeId(false);
    if (videoUrl) {
      const youtubeId = youtube_parser(videoUrl);
      setYoutubeId(youtubeId);
      if (youtubeId) {

      }
      else {
        if (videoRef && videoRef.current) {
          videoRef.current.onloadeddata = () => {
            if (isActive) {
              setIsLoading(false);
              videoRef.current.play();
            }
          };
          videoRef.current.src = videoUrl;
          videoRef.current.load();
        }
      }
    }
    
    return () => {
      isActive = false;
    };
  }, [videoRef, videoUrl]);

  const youtube_parser = (url = false) => {
    if (!url) {
      return false;
    }
      if (url.toLowerCase().indexOf('youtu') < 0) {
          return false;
      }
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\/)|(\?v=|\&v=))([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[8].length === 11) {
      return match[8];
    }
    return false;
  };

  const handleClose = () => {
    updateUiData({videoPlayerUrl: false});
    setVideoUrl(false);
  };

  return <>
    {videoUrl && <div className={`${styles.videoModal} ${isLoading ? styles.videoModal_isLoading : styles.videoModal_isNotLoading}`}>
      <div className={styles.videoModal_bg} onClick={handleClose}></div>
      {!youtubeId && <video ref={videoRef} className={styles.videoModal_video} controls={true}/>}
      {youtubeId && <div className={`${styles.videoModal_video} ${styles.videoModal_youtube}`}><YouTube 
        videoId={youtubeId} 
        // opts={{playerVars: {autoplay: 1}}}
        onReady={()=>{setIsLoading(false);}}
        opts={{
          width: '100%',
          height: '100%',
          playerVars: {
              // https://developers.google.com/youtube/player_parameters
              rel: 0,
              controls: 1,
              playsinline: 1,
              showinfo: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              autoplay: 1
          },
        }} 
      /></div>}
      <div className={styles.loader}></div>
      <button className={styles.videoModal_closeButton} onClick={handleClose}>
       <Icon icon="close" width={13.231} height={13.231} />
      </button>
    </div>}
  </>;
};

export default VideoContainer;

