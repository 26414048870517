import React, {useContext, useState, useEffect} from 'react';
import { UiContext } from '../../contexts';
import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

import Icon from '../Icon';
import styles from './DropBox.module.scss';

const DropBox = () => {
  const [{dropBoxContent, dropBoxCloseType, dropBoxType}, updateUiData] = useContext(UiContext);
  const {getGenderFormatMessage} = useGenderFormatMessage();
  const [content, setContent] = useState(null);
  const {isMobileView} = useDeviceData();

  useEffect(() => {
    if (dropBoxContent) {
      setContent(dropBoxContent);
      var dropboxopenEvent = window.document.createEvent('Event');
      dropboxopenEvent.initEvent('dropboxopen', true, false, window, 0);
      window.dispatchEvent(dropboxopenEvent);
    }
  }, [dropBoxContent]);

  useEffect(() => {
    if (!isMobileView) {
      closeDropBox();
    }
  }, [isMobileView]);

  const closeDropBox = () => {
    updateUiData({dropBoxContent: null});
    var dropboxcloseEvent = window.document.createEvent('Event');
    dropboxcloseEvent.initEvent('dropboxclose', true, false, window, 0);
    window.dispatchEvent(dropboxcloseEvent);
  };

  const contentData = (dropBoxContent && ((('current' in dropBoxContent) && dropBoxContent.current) || (!('current' in dropBoxContent) && dropBoxContent))) || 
                      (content && ((('current' in content) && content.current) || (!('current' in content) && content)));

  //dropBoxType = false | 'fullscreen'
  //dropBoxCloseType = false | 'top' | 'bottom'
  return <div className={`${styles.dropBox} ${dropBoxType ? styles['dropBox_type_' + dropBoxType] : ''} ${dropBoxContent ? styles.dropBox_active : ''}`}>
    <div className={styles.dropBox_bg} onClick={closeDropBox}></div>
    <div className={styles.dropBox_box}>
      <div className={styles.dropBox_content}>
        {(contentData && typeof (contentData) === 'function') ? contentData() : contentData}
      </div>
      <div className={`${styles.dropBox_closeBtn} ${dropBoxCloseType ? styles['dropBox_closeBtn_' + dropBoxCloseType] : ''}`} onClick={closeDropBox}>
        <Icon className={styles.dropBox_closeBtn_icon} icon='close' width={30} height={30}/>
        <span className={styles.dropBox_closeBtn_label}>{getGenderFormatMessage('dropBox_close')}</span>
      </div>
    </div>
  </div>;
};

DropBox.propTypes = {};

export default DropBox;