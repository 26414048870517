import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CacheProvider } from '../utils/cacheProvider';

const getEventsItems = (intl, numResults, allDimensions, lastSchool, isExtendedCatalog, shouldSearchEvents, ageGrades) => async () => {
  if (!shouldSearchEvents) return [];

  const apiParameters = {
    lang: intl.locale,
    clientTime: new Date(),
    daysWindow: 12,
    dimensionParams: {
      sectors: isExtendedCatalog ? ['state', 'mmad', 'arab'] : lastSchool?.sectorId ? [lastSchool.sectorId] : intl.locale === 'ar' ? ['arab'] : ['state', 'mmad'],
      ageGrades
    },
    contentParams: { isPublished: [true] },
    textParams: {},
    retrieveParams: { maxResults: numResults, dimensionName: 'yearCycles' }
  };

  const onSearchItemsForUpcomingEventsSuccess = data => {
    let itemsInfo = {};
    itemsInfo.itemTypes = 'all';
    itemsInfo.title = intl.formatMessage({ id: 'home_page_events_line_title' });

    if (data?.records?.all?.length > 0) {
      itemsInfo.data = data.records.all;
    } else if (data && data.records && allDimensions && allDimensions.yearCycles) {
      const items = [];
      const itemsMap = {};
      const yearCycles = allDimensions.yearCycles
        .filter(yearCycle => data.records[yearCycle.id])
        .sort((a, b) => (a.orderBy === b.orderBy ? 0 : a.orderBy > b.orderBy ? -1 : 1));
      yearCycles.forEach(yearCycle => {
        if (data.records[yearCycle.id] && data.records[yearCycle.id].length > 0) {
          data.records[yearCycle.id].forEach(item => {
            if (!itemsMap[item.id]) {
              itemsMap[item.id] = { ...item, yearCycles: [] };
              items.push(itemsMap[item.id]);
            }
            itemsMap[item.id].yearCycles.push(yearCycle);
          });
        }
      });
      itemsInfo.data = items;
    }

    itemsInfo.totalCount = itemsInfo.data && itemsInfo.data.length > 0 ? itemsInfo.data.length : 0;

    return itemsInfo.data && itemsInfo.data.length > 0 ? [itemsInfo] : [];
  };

  const localCacheKey = CacheProvider.generateKey('searchItemsForUpcomingEvents', apiParameters);

  if (CacheProvider.get(localCacheKey)) {
    const data = JSON.parse(CacheProvider.get(localCacheKey));
    return Promise.resolve(onSearchItemsForUpcomingEventsSuccess(data));
  } else {
    return window.cet.microservices.contentapi.search
      .searchItemsForUpcomingEvents(apiParameters)
      .then(data => {
        CacheProvider.set(localCacheKey, data);
        return onSearchItemsForUpcomingEventsSuccess(data);
      })
      .catch(error => {
        console.error(error);
        return [];
      });
  }
};

export const useEventsSearch = (numResults = 10) => {
  const intl = useIntl();
  const { allDimensions, lastSchool, isExtendedCatalog, ageGrades } = useSelector(({ dimensions, user }) => {
    return {
      allDimensions: dimensions.dimensions,
      lastSchool: user.lastSchool,
      isExtendedCatalog: user.info?.isExtendedCatalog,
      ageGrades: user.schools[0]?.teacherAgeGrades?.map(grade => grade.ageGradeId)
    };
  });

  const shouldSearchEvents = typeof lastSchool !== 'undefined' && typeof isExtendedCatalog !== 'undefined';

  return useMemo(
    () => getEventsItems(intl, numResults, allDimensions, lastSchool, isExtendedCatalog, shouldSearchEvents, ageGrades),
    [intl, numResults, allDimensions, lastSchool, isExtendedCatalog, shouldSearchEvents, ageGrades]
  );
};
