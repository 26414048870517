import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { safelyGetInnerHTML } from '../utils/html';

export const useGenderFormatMessage = () => {
  const user = useSelector(({ user }) => user.info);
  const intl = useIntl();
  const { isDeviceDataReady, isMobileView } = useDeviceData();

  const getGenderFormatMessage = useCallback(
    (messageId, isAllowStyle, isPlain) => {
      const gender = user?.gender && user.gender.toLowerCase(); // false|male|female
      const device = isMobileView ? 'mobile' : 'pc';
      let keysList = [];

      if (user?.gender && isDeviceDataReady) {
        keysList.push(`${messageId}_${device}_${gender}`); //{messageId}_{mobile|pc}_{male|female}
      }
      if (isDeviceDataReady) {
        keysList.push(`${messageId}_${device}`); //{messageId}_{mobile|pc}
      }
      if (user?.gender) {
        keysList.push(`${messageId}_${gender}`); //{messageId}_{male|female}
      }
      keysList.push(messageId); //{messageId}

      for (let i = 0; i < keysList.length; i++) {
        const genderMessage = intl.messages[keysList[i]];
        if (genderMessage) {

          return isPlain ? genderMessage : safelyGetInnerHTML(genderMessage, isAllowStyle);
        }
      }

      return isPlain ? messageId : safelyGetInnerHTML(messageId, isAllowStyle);
    },
    [user?.gender, isMobileView, isDeviceDataReady, intl.messages]
  );

  const getGenderFormatMessagePlain = useCallback(
    (messageId) => {
      return getGenderFormatMessage(messageId, false, true);
    },
    [getGenderFormatMessage]
  );

  return {
    getGenderFormatMessage, getGenderFormatMessagePlain
  };
};
