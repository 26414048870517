import { useEffect, useState } from 'react';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

export const useHorizontalScroller = (scroller, isEnable, initToCenter = false, type = 'drag') => {
  const { isMobile, viewWidth } = useDeviceData();
  const [focusData, setFocusData] = useState(null);

  useEffect(() => {
    let isActive = true;
    let isOn = false;
    let isClick = false;

    const scrollFun = e => {
      if (!isActive) {
        return;
      }
      isClick = false;
      const clickDelta = 10;
      const startClientX = isMobile ? e.changedTouches[0].clientX : e.clientX;
      const startClientY = isMobile ? e.changedTouches[0].clientY : e.clientY;
      const scrollerW = scroller.current.clientWidth;
      const scrollerInnerW = scroller.current.scrollWidth;

      const style = window.getComputedStyle(scroller.current);
      const matrix = new DOMMatrixReadOnly(style.transform);
      let startD = matrix.m41;
      let d = 0;
      let isScrolling = isMobile ? null : false;

      scroller.current.dataset.horizontalScrollerOn = true;

      const onmousemove = e1 => {
        if (isScrolling) {
          return;
        }

        const clientX = isMobile ? e1.changedTouches[0].clientX : e1.clientX;
        const clientY = isMobile ? e1.changedTouches[0].clientY : e1.clientY;
        const dy = clientY - startClientY;
        const dx = clientX - startClientX;

        if (isScrolling === null) {
          if (dx < -clickDelta || dx > clickDelta) {
            isScrolling = false;
          } else if (dy < -clickDelta || dy > clickDelta) {
            isScrolling = true;
          }
        }

        if (isScrolling) {
          return;
        } else if (isScrolling === false) {
          //e1.preventDefault();
          e1.stopPropagation();
          d = dx;
          scroller.current.style.transform = `translateX(${dx + startD}px)`;
        }
      };

      const onmouseup = e1 => {
        onmousemove(e1);
        onmouseleave();
        if (-clickDelta <= d && d <= clickDelta) {
          isClick = true;
        }
      };

      const onmouseleave = () => {
        const style = window.getComputedStyle(scroller.current);
        const matrix = new DOMMatrixReadOnly(style.transform);
        let d = matrix.m41;

        if (initToCenter && scrollerInnerW <= scrollerW && scroller.current.children.length > 0 && scroller.current.children[0].clientWidth <= scrollerInnerW) {
          d = (scroller.current.children[0].clientWidth - scrollerInnerW) / 2;
        } else if (d < 0 || scrollerInnerW <= scrollerW) {
          d = 0;
        } else if (d > scrollerInnerW - scrollerW) {
          d = scrollerInnerW - scrollerW;
        }
        scroller.current.dataset.horizontalScrollerOn = false;
        scroller.current.style.transform = `translateX(${d}px)`;

        if (isMobile) {
          document.removeEventListener('touchend', onmouseup, true);
          document.removeEventListener('touchmove', onmousemove, false);
        } else {
          document.removeEventListener('mouseup', onmouseup, true);
          document.removeEventListener('mousemove', onmousemove, false);
          document.removeEventListener('mouseleave', onmouseleave, false);
        }
      };

      if (isMobile) {
        document.addEventListener('touchend', onmouseup, true);
        document.addEventListener('touchmove', onmousemove, false);
      } else {
        document.addEventListener('mouseup', onmouseup, true);
        document.addEventListener('mousemove', onmousemove, false);
        document.addEventListener('mouseleave', onmouseleave, false);
      }
    };

    const onClick = e => {
      if (!isClick) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (scroller && scroller.current && isEnable && type === 'drag') {
      isOn = true;
      scroller.current.dataset.isHorizontalScroller = true;
      scroller.current.dataset.horizontalScrollerType = type;
      scroller.current.scrollTo(0, 0);

      let start_translate = 0;
      if (focusData) {
        const scrollerW = scroller.current.clientWidth;
        const scrollerInnerW = scroller.current.scrollWidth;
        start_translate = focusData.right + focusData.width / 2 - scrollerW / 2;

        if (initToCenter && scrollerInnerW <= scrollerW && scroller.current.children.length > 0 && scroller.current.children[0].clientWidth <= scrollerInnerW) {
          start_translate = (scroller.current.children[0].clientWidth - scrollerInnerW) / 2;
        } else if (start_translate < 0 || scrollerInnerW <= scrollerW) {
          start_translate = 0;
        } else if (start_translate > scrollerInnerW - scrollerW) {
          start_translate = scrollerInnerW - scrollerW;
        }
      }
      scroller.current.dataset.horizontalScrollerOn = false;
      scroller.current.style.transform = `translateX(${start_translate}px)`;

      scroller.current.addEventListener('click', onClick, true);
      if (isMobile) {
        scroller.current.addEventListener('touchstart', scrollFun, false);
      } else {
        scroller.current.addEventListener('mousedown', scrollFun, false);
      }
    }

    if (scroller && scroller.current && isEnable && type === 'scroll') {
      scroller.current.dataset.isHorizontalScroller = true;
      scroller.current.dataset.horizontalScrollerOn = false;
      scroller.current.dataset.horizontalScrollerType = type;
      scroller.current.style.transform = '';
      scroller.current.scrollTo(0, 0);
    }

    if (!isEnable && scroller?.current?.dataset) {
      scroller.current.dataset.isHorizontalScroller = false;
      scroller.current.dataset.horizontalScrollerOn = false;
      scroller.current.dataset.horizontalScrollerType = '';
      scroller.current.style.transform = '';
      scroller.current.scrollTo(0, 0);
    }

    const scrollerRef = scroller.current;

    return () => {
      isActive = false;
      if (isOn && scrollerRef) {
        isOn = false;
        scrollerRef.removeEventListener('click', onClick, true);
        if (isMobile) {
          scrollerRef.removeEventListener('touchstart', scrollFun, false);
        } else {
          scrollerRef.removeEventListener('mousedown', scrollFun, false);
        }
        scrollerRef.dataset.isHorizontalScroller = false;
        scrollerRef.dataset.horizontalScrollerOn = false;
      }
    };
  }, [scroller, isEnable, type, isMobile, viewWidth, focusData, initToCenter]);

  return {
    setHorizontalScrollerFocusData: setFocusData
  };
};
