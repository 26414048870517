/**
 * returns true if the evaluation work message should be displayed, else false
 * @param {object} queryParams
 * @returns {boolean}
 */
export const useEvaluationWorkMessage = (queryParams) => {
	const { ageGrades, disciplines, itemTypes } = queryParams;
	const ageGradesArr = ageGrades?.split(',') || [];
	const ageGradesMessageEnabled = ['grade2','grade3','grade4','grade5','grade6'];
	const showAgeGradesMessage = ageGradesArr.some(ageGrade => ageGradesMessageEnabled.includes(ageGrade));

	return (showAgeGradesMessage && disciplines === 'math' && itemTypes === 'evaluationWork' );
};