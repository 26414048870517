import { createContext, useState, useRef } from 'react';

export const UiContext = createContext();

export const UiProvider = props => {
  const [uiData, setUiData] = useState({});
  const updateUiData = useRef();
  updateUiData.current = newUiData => {
    setUiData({
      ...uiData,
      ...newUiData
    });
  };

  return <UiContext.Provider value={[uiData, updateUiData.current]}>{props.children}</UiContext.Provider>;
};
