import React from 'react';
import {useIntl} from 'react-intl';

import styles from './NotFound.module.scss';
import Title from '../../components/Title';
import Button from '../../legacyComponents/Ui/Button';
import { useGenderFormatMessage } from '../../hooks';
import image from './404.png';

const NoFound = ({ hasErrorBoundary }) => {
  const intl  = useIntl();
  const { getGenderFormatMessage } = useGenderFormatMessage();

  return (
    <div className={styles.container}>
      <Title level={1}>{hasErrorBoundary ? getGenderFormatMessage('not_found_error_title') : getGenderFormatMessage('not_found_title')}</Title>
      <p>{getGenderFormatMessage('not_found_content')}</p>
      <Button className={styles.button} size="x-large" view="contained" color="primary" to={`/${intl.locale}`} aria-label={getGenderFormatMessage('back_to_home_page')}>{getGenderFormatMessage('back_to_home_page')}</Button>
      <img src={image} alt="" className={styles.image}/>
    </div>
  );
};

export default NoFound;
