import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Href from '../../Href';

import { useGenderFormatMessage } from '../../../hooks';

import styles from '../Preview.module.scss';

const ReferencesList = ({ title, list, startLinesCount=3 }) => {
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [linesCount, setLinesCount] = useState(startLinesCount);
  const readMoreLabel = <b>{getGenderFormatMessage('read_more')}</b>;

  return (
    <>
      {list?.length > 0 && (
        <>
          <h3 className={styles.label}>{title}</h3>
          <ul className={styles.list}>
            {list.slice(0, linesCount).map(({ href, name }, index) => (
              <li key={index}>
                <Href to={href} target="_blank">
                  {name}
                </Href>
              </li>
            ))}
          </ul>

          {list.length > linesCount && (
            <div>
              <Button onClick={() => setLinesCount(999)} type="link">
                {readMoreLabel} ...
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

ReferencesList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
};

export default ReferencesList;
