import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { UiProvider } from '../../contexts';
import { useInitApp } from '../../hooks';
import { Preloader } from 'cet-components-lib/dist/UI/Preloader';
import { CetComponentsProvider } from 'cet-components-lib/dist/providers/CetComponentsProvider';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import Error from './Error';
import Site from './Site';

import styles from './App.module.scss';

export default function App() {
  const [userReady, setUserReady] = useState(false);
  const [sitekey, setSitekey] = useState('nairobi');
  const { isMobileView } = useDeviceData();
  const { isLoading, error, language, i18nConstants } = useInitApp();
  const history = useHistory();
  const { search } = history?.location;

  if (!isLoading && error) {
    console.error(JSON.stringify(error));
  }

  useEffect(() => {
  if (search) {
    const params = new URLSearchParams(search);
    const sitekey = params?.get('sitekey');

    if (sitekey) {
      setSitekey(sitekey ?? 'nairobi')
    } 
  }
  }, [search]);
  
  try {
    return (
      <CetComponentsProvider
        value={{
          language,
          siteKey: sitekey,
          rootFontSize: isMobileView ? 14 : 16,
        }}
      >
        {(isLoading || !userReady) && !error &&
          <div className={styles.loader}>
            <Preloader />
          </div>
        }
        {!isLoading && error && error.detail?.status !== 401 && <Error language={language} />}
        {!isLoading && (!error || error?.detail?.status === 401) &&
          <IntlProvider locale={language} defaultLocale="he" messages={i18nConstants[language]}>
            <UiProvider>
              <Site language={language} onUserReady={() => { setUserReady(true); }} />
            </UiProvider>
          </IntlProvider>
        }
      </CetComponentsProvider>
    );
  }
  catch (error) {
    return <Error language={language} />;
  }
}
