import {useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { callWithCache } from '../utils/callWithCache';
import { useCachedState } from './useCachedState';

export const useAllRecommendations = () => {
    const intl = useIntl();
    const { isLoggedIn, user } = useSelector(({  user }) => ({
        user,
        isLoggedIn: !!Object.keys(user.info).length
    }));
    const [recommendations, setRecommendations] = useCachedState('allRecommendations',{});

    /**
     * Gets recommendations for logged in teacher and sets it in session storage
     * @type {(function(): void)|*}
     */
    const getAllRecommendations = useCallback(() => {
        const apiParameters = {
            lang: "",
            teacherId: user?.info?.userId,
            dimensionParams: {
                sectors: user.info?.isExtendedCatalog ? ['state', 'mmad', 'arab'] : user.lastSchool?.sectorId ? [user.lastSchool.sectorId] : intl.locale === 'ar' ? ['arab'] : ['state', 'mmad']

            },
            contentParams: {
                isPublished: [
                    true
                ]
            },
            retrieveParams: {
                dimensionName: "syllabusSubjects",
                maxResults: 200
            }
        }
        callWithCache(window.cet.microservices.recommendationsapi?.recommendations?.getAllRecommendations, 'getAllRecommendations', apiParameters)
            .then( response => setRecommendations(response))
            .catch( error => {
                console.error(error);
            });
    },[intl.locale, setRecommendations, user.info?.isExtendedCatalog, user.info?.userId, user.lastSchool?.sectorId]);

    useEffect(() => {
        if (isLoggedIn) {
            getAllRecommendations();
        }
    }, [isLoggedIn, getAllRecommendations]);

    return recommendations;
}