import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearch } from './';

const getMostPopularItems = (intl, dispatchSearch, numResults) => async () => {
  const mostPopularIds = [
    '05DE31DE',
    '04C9B07C',
    '04F4A5C8',
    '04FC5C72',
    '028A582A',
    '031598D1',
    '03361F1B',
    '04ABC9E0',
    '04C25598',
    '0523AEB8',
  ];

  const result = await dispatchSearch([{ id: mostPopularIds }], null, numResults);
  if (result?.length) {
    const items = result[0];
    items.title = intl.formatMessage({ id: 'most_popular_items' });

    return items;
  }

  return null;
};

export const useMostPopularItems = (numResults = 10) => {
  const intl = useIntl();
  const dispatchSearch = useSearch();
  
  return useMemo(() => getMostPopularItems(intl, dispatchSearch, numResults), [intl, dispatchSearch, numResults]);
};
