import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useDeviceData, useLabels, useStylesUtils } from 'cet-components-lib/hooks';
import { labels } from './SideBar.labels';
import { SideBarListItem } from './Partials/SideBarListItem';
import { Menu } from 'cet-components-lib/dist/UI/Menu';
import { Icon } from 'cet-components-lib/dist/UI/Icon';
import { sidebarHeight } from 'cet-components-lib/dist/Content/Site/Site.config';
import useStyles from './SideBar.styles';

export const SideBar = props => {
  const { items, maxMobileItems = 5 } = props;
  const { pathname } = useLocation();
  const { isMobileView } = useDeviceData();
  const intl = useIntl();
  const { pxToRem } = useStylesUtils();
  const getLabel = useLabels({ labels });
  const styles = useStyles();

  const menuRef = useRef(null);
  const sidebarRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const { isLoggedIn } = useSelector(({ user }) => ({
    isLoggedIn: Boolean(user?.info?.userId)
  }));

  let btnProps = {};
  let listItems = [...items];
  let menuItems = [];

  if (isMobileView && listItems.length > maxMobileItems) {
    listItems = items.slice(0, maxMobileItems - 1);
    menuItems = items.slice(maxMobileItems - 1);

    btnProps = {
      shape: 'rectangle',
      view: 'alternate',
      color: 'basic',
      customStyle: {
        width: '100%',
        height: pxToRem(sidebarHeight)
      },
      children: (
        <div className={styles['cc-SideBar__menu__item__more-btn']}>
          <Icon name="More" />
          <div>{getLabel('more')}</div>
        </div>
      )
    };
  }

  const handleItemClick = expandOnHover => {
    if (!isMobileView && isMinimized !== expandOnHover) {
      isLoggedIn && setIsMinimized(expandOnHover);
    }
    if (menuRef.current) {
      menuRef.current.closeMenu();
    }
  };

  const handleAccordionOpen = accordionOpen => {
    if (!isMobileView && isAccordionOpen !== accordionOpen) {
      isLoggedIn && setIsAccordionOpen(accordionOpen);
    }
  };

  useEffect(() => {
    const isOnShvilimPage = new RegExp(`^/${intl.locale}/teachers-center-shvilim(?:\\?|/item.*)?(?:\\?.*)?$`).test(pathname);
    if (isOnShvilimPage && isLoggedIn) {
      handleItemClick(true); // Trigger expand on hover
    } else if (!isOnShvilimPage) {
      handleItemClick(false);
    }
  }, [pathname, isLoggedIn]);

  return (
    <>
      <aside
        ref={sidebarRef}
        className={`${styles['cc-SideBar']} ${isMinimized && !isAccordionOpen ? styles['cc-SideBar_Minimized'] : ''}`}
        aria-label={getLabel('sidebarAriaLabel')}
      >
        <nav>
          <ul className={styles['cc-SideBar__menu']}>
            {listItems &&
              listItems.map((item, i) => {
                const itemProps = { item, onItemClick: handleItemClick, isMinimized: isMinimized, handleAccordionOpen: handleAccordionOpen };
                return <SideBarListItem key={i} {...itemProps} />;
              })}
            {isMobileView && menuItems.length && (
              <li className={styles['cc-SideBar__menu__item']}>
                <Menu btnProps={btnProps} anchorWidth="auto" ref={menuRef}>
                  <ul className={styles['cc-SideBar__menu__item__more-menu']}>
                    {menuItems.map((item, i) => {
                      const itemProps = { item, isMoreMenuItem: true, onItemClick: handleItemClick };
                      return <SideBarListItem key={i} {...itemProps} />;
                    })}
                  </ul>
                </Menu>
              </li>
            )}
          </ul>
        </nav>
      </aside>
    </>
  );
};
