import React from 'react';
import styles from './AudienceForm.module.scss';

const AudienceForm = ({ onClose }) => (
  <h1 onClick={onClose} className={styles.container}>
    AudienceForm<br /><br /><br /><br /><br /><br /><br />
  </h1>
);

AudienceForm.propTypes = {};

export default AudienceForm;
