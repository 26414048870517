import React from 'react';
import PropTypes from 'prop-types';
import styles from './Title.module.scss';

const Title = ({ level = 1, children, className, ...rest }) => {
  if (level < 1 && level > 6) {
    throw new Error('Title level must be between 1 and 6');
  }
  const Htag = `h${level}`;

  return (
    <Htag className={className || styles.title} {...rest}>
      {children}
    </Htag>
  );
};

Title.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Title;
