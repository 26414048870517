import React from 'react';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import styles from './MobileLandscapeView.module.scss';

const MobileLandscapeView = () => {
  const {isDeviceDataReady, isMobile, isLandscape} = useDeviceData();

  return isDeviceDataReady && isMobile && isLandscape && <div className={styles.mobileLandscapeView}>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 581 581">
      <g>
        <g>
          <path className={styles.path1} d="M502.5,383.5L369.7,516.3c-16.8,16.8-44.1,16.8-60.9,0l-213-213c-16.8-16.8-16.8-44.1,0-60.9l132.8-132.8     c16.8-16.8,44.1-16.8,60.9,0l213,213C519.3,339.5,519.3,366.7,502.5,383.5z"></path>
          <ellipse className={styles.path3} cx="374.4" cy="388.3" rx="21.5" ry="21.5"></ellipse>
          <path className={styles.path1} d="M406.7,76.2c71.3,0,129.1,57.8,129.1,129.1"></path>
          <path className={styles.path1} d="M191.5,549.7c-71.3,0-129.1-57.8-129.1-129.1"></path>
        </g>
        <g>
          <polyline className={styles.path2} points="45.2,142.2 100.6,142.2 100.6,31.3"></polyline>
        </g>
      </g>
    </svg>
  </div>;
};

export default MobileLandscapeView;