import React, { Suspense, lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

//Lazy loading the item preview container, makes the page to flash the first time displaying an item.
import PreviewContainer from '../../containers/PreviewContainer';
import { ProtectedRoute } from './ProtectedRoute';
import ErrorBoundary from '../ErrorBoundary';
import { lazyWithRetry } from '../../utils/lazyWithRetry';

const TempL = lazy(() => lazyWithRetry(() => import('../../pages/Temp')));
const HomeL = lazy(() => lazyWithRetry(() => import('../../pages/Home')));
const ProfileL = lazy(() => lazyWithRetry(() => import('../../pages/Profile')));
const BooksL = lazy(() => lazyWithRetry(() => import('../../pages/Books')));
const CoursesL = lazy(() => lazyWithRetry(() => import('../../pages/Courses')));
const CatalogL = lazy(() => lazyWithRetry(() => import('../../pages/Lists/Catalog')));
const SearchL = lazy(() => lazyWithRetry(() => import('../../pages/Lists/Search')));
const TeachersCenterShvilim = lazy(() => lazyWithRetry(() => import('../../pages/TeachersCenterShvilim')));
const FavoritesL = lazy(() => lazyWithRetry(() => import('../../pages/Favorites')));
const RecommendationsL = lazy(() => lazyWithRetry(() => import('../../pages/Recommendations')));
const ToolsL = lazy(() => lazyWithRetry(() => import('../../pages/Tools')));
const AiMain = lazy(() => lazyWithRetry(() => import('../../pages/ToolsAI/AiMain')));
const AiSkills = lazy(() => lazyWithRetry(() => import('../../pages/ToolsAI/AiSkills')));
const AiContent = lazy(() => lazyWithRetry(() => import('../../pages/ToolsAI/AiContent')));
const AiChat = lazy(() => lazyWithRetry(() => import('../../pages/ToolsAI/AiChatContainer')));
const TeachersLearnL = lazy(() => lazyWithRetry(() => import('../../pages/TeachersLearn')));
const TeachingSkills = lazy(() => lazyWithRetry(() => import('../../pages/TheachersRoom/TeachingSkills')));
const TeachingPractices = lazy(() => lazyWithRetry(() => import('../../pages/TheachersRoom/TeachingPractices')));
const SelTime = lazy(() => lazyWithRetry(() => import('../../pages/TheachersRoom/SelTime')));
const TasksL = lazy(() => lazyWithRetry(() => import('../../pages/Tasks')));
const TaskManagerL = lazy(() => lazyWithRetry(() => import('../../pages/TaskManager')));
const NotFoundL = lazy(() => lazyWithRetry(() => import('../../pages/NotFound')));
const TaskViewerL = lazy(() => lazyWithRetry(() => import('../../pages/TaskViewer'))); //refactor/edit
const Academe = lazy(() => lazyWithRetry(() => import('../../pages/Academe')));
const Icons = lazy(() => lazyWithRetry(() => import('../../components/Icon/icons')));

export default function NairobiRoutes() {
  const { isUserCoordinator } = useSelector(({ user }) => ({ isUserCoordinator: user.info?.role?.toLowerCase() === 'coordinator' }));

  // Redirect to the same url without the last slash
  useEffect(() => {
    if (window.location.href.slice(-1) === '/') {
      window.location.replace(window.location.href.slice(0, -1));
    }
  }, []);

  return (
    <Suspense fallback="">
      <ErrorBoundary>
        <Switch>
          <Route exact path={`/:language/`} component={HomeL} />
          <Route path={`/:language/my-profile`} component={ProfileL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/books/all`} component={TempL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/courses`} component={CoursesL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/books`} component={BooksL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/courses/all`} component={CoursesL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/sequences/all`} component={TempL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/sequences`} component={TempL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/recommendations`} component={RecommendationsL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/catalog`} component={CatalogL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/search`} component={SearchL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/teachers-center-shvilim`} component={TeachersCenterShvilim} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/my-content`} component={TempL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/my-favorites/:categoryId?`} component={FavoritesL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/tasks`} component={TasksL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/pedagogical-help`} component={TempL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/teachers-learn`} component={TeachersLearnL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/teaching-skills`} component={TeachingSkills} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/teaching-practices`} component={TeachingPractices} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/sel-time`} component={SelTime} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/tools`} component={ToolsL} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/ai-main`} component={AiMain} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/ai-skills`} component={AiSkills} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/ai-content`} component={AiContent} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/ai-chat`} component={AiChat} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/icons`} component={Icons} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/academe`} component={Academe} />
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/task/:taskId`} component={TaskViewerL} /> {/* refactor/edit */}
          <ProtectedRoute isAuth={!isUserCoordinator} path={`/:language/task-manager`} component={TaskManagerL} /> {/* !TEMPORARY! - till LO will use CET component */}
          <ProtectedRoute isAuth={!isUserCoordinator} exact path={`/:language/item/:itemId`} component={HomeL} />
          <Route component={NotFoundL} />
        </Switch>
        <Route path={`/:language*/item/:itemId*`} component={PreviewContainer} />
      </ErrorBoundary>
    </Suspense>
  );
}
