import React from 'react';
import PropTypes from 'prop-types';
import { safelyGetInnerHTML } from '../../../utils/html';

import Button from '../../Button';

import styles from '../Preview.module.scss';

const AuthorizationMessage = ({ message, isOpenBtn = true, openText, onOpenClick, isPurchaseBtn = true, purchaseText, onPurchaseClick, children }) => {

  return (
    <div className={styles.authorizationMessage}>
      <div className={styles.messageContent}>{safelyGetInnerHTML(message)}</div>
      {children}
      <div className={styles.messageButtons}>
        {isOpenBtn && <Button type="line" size="small" className={styles.messageButton} onClick={onOpenClick}>{openText}</Button>}
        {isPurchaseBtn && <Button type="primary" size="small" className={styles.messageButton} onClick={onPurchaseClick}>{purchaseText}</Button>}
      </div>
    </div>
  );
};

AuthorizationMessage.propTypes = {
  message: PropTypes.string,
  isOpenBtn: PropTypes.bool,
  openText: PropTypes.string,
  onOpenClick: PropTypes.func,
  isPurchaseBtn: PropTypes.bool,
  purchaseText: PropTypes.string,
  onPurchaseClick: PropTypes.func,
  children: PropTypes.node
};

export default AuthorizationMessage;
