const types = {
  append: 'append',
};

const reducer = (
  state = {
    newestItems: {
      itemTypes: 'all',
      title: 'newestItems',
      data: [],
    },
    mostPopularItems: {
      itemTypes: 'all',
      title: 'mostPopularItems',
      data: [],
    },
  },
  action
) => {
  switch (action.type) {
    case types.append:
      return { ...state, ...action.items };
    default:
      return state;
  }
};

export const append = (items) => ({
  type: types.append,
  items,
});

export default reducer;
