import React from 'react';
import PropTypes from 'prop-types';
import { useCetEvents } from 'cet-components-lib/dist/hooks';
import Href from '../Href';

import styles from './Button.module.scss';

const Button = React.forwardRef(
  ({ href, onClick, type = 'default', size = 'medium', children, className, category, label, optionalData, ...rest }, outerRef) => {
    const classNames = [styles.button, styles[type], styles[size], className ?? ''].join(' ').trimEnd();
    const { sendUiUsageEvent } = useCetEvents();

    const handleOnClick = (event) => {
      if (category && label){
        sendUiUsageEvent({category, label, optionalData})
      }
      if (onClick){
        onClick(event);
      }
    }
    return (
      <>
        {href ? (
          <Href ref={outerRef} {...rest} className={classNames} to={href} onClick={onClick} category={category} label={label} optionalData={optionalData}>
            {children}
          </Href>
        ) : (
          <button ref={outerRef} {...rest} onClick={handleOnClick} className={classNames}>
            {children}
          </button>
        )}
      </>
    );
  }
);

Button.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary', 'link', 'default', 'line', 'success', 'warning', 'danger', 'none']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  category: PropTypes.string,
  label: PropTypes.string,
  optionalData:PropTypes.object
};
Button.displayName = 'Button';
export default Button;
