export const animationFrameController = () => {
    let rootScroll = document.querySelector('#rootScroll');
    let lastScrollTop = false;

    const animationFrame = () => {
      let fontSize = window.parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));
      let newScrollTop = rootScroll.scrollTop;
      let isScroolUp = false;
      let isScroolDown = false;
      let isTop = false;
      let headerHeight = 0;
      // let headerTop = 0; //TOREMOVE?
      let headerTopMarker = 0;
      let eliteMessageHeight = 0;
    
      if (lastScrollTop === false) {
        lastScrollTop = newScrollTop;
      }
    
      if (lastScrollTop < newScrollTop) {
        //console.log('onScroll V', lastScrollTop, newScrollTop);
        isScroolDown = true;
      }
      else if (lastScrollTop > newScrollTop) {
        //console.log('onScroll A', lastScrollTop, newScrollTop);
        isScroolUp = true;
      }
    
      if (newScrollTop <= 0) {
        //console.log('onScroll isTop', lastScrollTop, newScrollTop);
        isTop = true;
      }
      if (newScrollTop < 0) {
        newScrollTop = 0;
        rootScroll.scrollTo(0,0);
      }
    
      document.querySelectorAll('[data-scrolling-handler-element="header"]').forEach(header => {
        let mobileHeaderRect = header.getBoundingClientRect();
        headerHeight = mobileHeaderRect.height;
        let oldHeaderTop = mobileHeaderRect.y;
        let newHeaderTop = oldHeaderTop;
    
        if (header.getAttribute('data-scrolling-handler-data') === 'mobile') {
          if (isScroolDown) {
            if (newScrollTop < headerHeight) {
              newHeaderTop = (-newScrollTop);
            }
            else {
              newHeaderTop = -headerHeight;
            }
          }
          else if (isScroolUp) {
            if (oldHeaderTop !== 0) {
              if (newScrollTop < headerHeight) {
                newHeaderTop = -newScrollTop;
              }
              else {
                if (lastScrollTop - newScrollTop >= 5) {
                  newHeaderTop = 0;
                }
              }
            }
          }
          else if (newScrollTop === 0) {
            newHeaderTop = 0;
          }
      
          if (oldHeaderTop !== newHeaderTop) {
            let delta = (oldHeaderTop > newHeaderTop) ? (oldHeaderTop - newHeaderTop) : (newHeaderTop - oldHeaderTop);
            let time = (newScrollTop < headerHeight || delta < headerHeight / 2) ? 0 : (0.2 * delta / headerHeight);
            header.style.transition = `top ${time}s linear`;
            header.style.top = newHeaderTop + 'px';
          }
          else {
            header.style.transition = `none`;
          }
        }
        else {
          newHeaderTop = 0;
          if (oldHeaderTop !== 0) {
            header.style.transition = `none`;
            header.style.top = newHeaderTop + 'px';
          }
        }
    
        // headerTop = newHeaderTop; //TOREMOVE?
        let headerTopMarkerRef = header.querySelector('[data-scrolling-handler-element="headerTopMarker"]');
        let headerTopMarkerRect = headerTopMarkerRef.getBoundingClientRect();
        headerTopMarker = headerTopMarkerRect.y;
      });
    
      document.querySelectorAll('[data-scrolling-handler-element="eliteSystemMessage"]').forEach(eliteMessage => {
        let eliteSystemMessagePadding = eliteMessage.querySelector('[data-scrolling-handler-element="eliteSystemMessagePadding"]');
        let eliteSystemMessageContaner = eliteMessage.querySelector('[data-scrolling-handler-element="eliteSystemMessageContaner"]');
        let eliteSystemMessageText = eliteMessage.querySelector('[data-scrolling-handler-element="eliteSystemMessageText"]');
    
        eliteMessageHeight = eliteSystemMessageText.clientHeight;
    
        eliteSystemMessageContaner.style.top = headerTopMarker + 'px';
        eliteSystemMessageContaner.style.height = eliteMessageHeight + 'px';
        eliteSystemMessageContaner.style.width = eliteSystemMessagePadding.clientWidth + 'px';
        eliteSystemMessagePadding.style.height = eliteMessageHeight + 'px';
      });
    
      document.querySelectorAll('[data-scrolling-handler-element="stuckMenuPadding"]').forEach(stuckMenuPadding => {
        let stuckMenu = stuckMenuPadding.nextElementSibling;
        let stuckMenuUp = stuckMenu.querySelector('[data-scrolling-handler-element="stuckMenuUp"]');
        let stuckMenuCenter = stuckMenu.querySelector('[data-scrolling-handler-element="stuckMenuCenter"]');
        let stuckMenuStart = stuckMenu.querySelector('[data-scrolling-handler-element="stuckMenuStart"]');
        let stuckMenuEnd = stuckMenu.querySelector('[data-scrolling-handler-element="stuckMenuEnd"]');
        let type = stuckMenu.getAttribute('data-scrolling-handler-data-type');
        let fixOn = stuckMenu.getAttribute('data-scrolling-handler-data-fixon') === '1';
        let isRTL = stuckMenu.getAttribute('data-scrolling-handler-data-isrtl') === '1';
    
        let stuckMenuPaddingRect = stuckMenuPadding.getBoundingClientRect();
        let layoutStartRect = stuckMenuStart.getBoundingClientRect();
        let layoutEndRect = stuckMenuEnd.getBoundingClientRect();
        let stuckUpRect = stuckMenuUp.getBoundingClientRect();
    
        let scroller;
        let topMarker;
        let olldIsTop = stuckMenu.dataset.is_top && stuckMenu.dataset.is_top + '' === 'true';
    
        if (type === 'page') {
          scroller = rootScroll;
          topMarker = headerTopMarker + eliteMessageHeight;
        }
        else if (type === 'item') {
          scroller = stuckMenu.closest('[data-scrolling-handler-element="scroller"]');
          topMarker = scroller.querySelector('[data-scrolling-handler-element="itemHeaderTopMarker"]');
          topMarker = topMarker.getBoundingClientRect().y;
        }
        else if (type === 'itemHeader') {
          scroller = stuckMenu.closest('[data-scrolling-handler-element="scroller"]');
          topMarker = scroller.querySelector('[data-scrolling-handler-element="itemTopMarker"]');
          topMarker = topMarker.getBoundingClientRect().y;
        }
    
        let isTop = (topMarker > stuckMenuPaddingRect.y) && fixOn;
        stuckMenu.dataset.is_top = (stuckMenu.dataset.is_top === 'start') ? '' : isTop;
    
        if (isTop) {
          stuckMenu.style.top = (topMarker) + 'px'; 
          stuckMenu.style.width = stuckMenuPaddingRect.width + 'px';
          stuckMenuPadding.style.height = stuckUpRect.height + 'px';
          if (isRTL) {
            stuckMenuCenter.style.marginRight = layoutStartRect.width > 0 ? (layoutStartRect.width + fontSize + fontSize) + 'px' : '0px';
            stuckMenuCenter.style.marginLeft = layoutEndRect.width > 0 ? (layoutEndRect.width + fontSize + fontSize) + 'px' : '0px';
          }
          else {
            stuckMenuCenter.style.marginLeft = layoutStartRect.width > 0 ? (layoutStartRect.width + fontSize + fontSize) + 'px' : '0px';
            stuckMenuCenter.style.marginRight = layoutEndRect.width > 0 ? (layoutEndRect.width + fontSize + fontSize) + 'px' : '0px';
          }
          stuckMenuCenter.style.paddingTop = fontSize + 'px';
        }
        else {
          stuckMenu.style.top = '0px';
          stuckMenu.style.width = '100%';
          stuckMenuPadding.style.height = '0px';
          stuckMenuCenter.style.marginRight = '0px';
          stuckMenuCenter.style.marginLeft = '0px';
          stuckMenuCenter.style.paddingTop = (layoutStartRect.height > 0 || layoutEndRect.height > 0) ? (fontSize + fontSize + window.Math.max(layoutStartRect.height, layoutEndRect.height)) + 'px' : fontSize + 'px';
        }
    
        if (type === 'page' && olldIsTop !== isTop) {
          var resizeEvent = window.document.createEvent('UIEvents');
          resizeEvent.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(resizeEvent);
    
          setTimeout(()=>{
            window.dispatchEvent(resizeEvent);
          },500);
        }
      });
    
      if (isScroolDown || (isScroolUp && (lastScrollTop - newScrollTop >= 10)) || isTop) {
        document.querySelectorAll('[data-scrolling-handler-element="tabs"]').forEach(tabs => {
          tabs.dataset.scrollingHandlerDataShow = isScroolUp || isTop;
        });
    
        document.querySelectorAll('[data-scrolling-handler-element="filters"]').forEach(tabs => {
          tabs.dataset.scrollingHandlerDataisOpen = isScroolUp || isTop;
        });
      }
    
      lastScrollTop = newScrollTop;
      window.requestAnimationFrame(animationFrame);
    };
    window.requestAnimationFrame(animationFrame);
};