export const isObjectEmpty = (objectName) => {
	return Object.keys(objectName).length === 0
};

export const inverseObject = (obj) => {
	let result = {};

	for (let key in obj) {
		obj[key].forEach(item => {
			if (!result[item]) {
				result[item] = [];
			}
			result[item].push(key);
		});
	}

	return result;
}