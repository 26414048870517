import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CacheProvider } from '../utils/cacheProvider';
import { callWithCache } from '../utils/callWithCache';
import { useCetEvents } from 'cet-components-lib/dist/hooks';

export const useAnnualProfileUpdate = (isInTaskManagerIframe = false) => {
  const [shouldUserUpdateProfile, setShouldUserUpdateProfile] = useState(false);
  const { sendLearningEvent } = useCetEvents();
  const { userId, lastSchoolId } = useSelector(({ user }) => ({
    userId: user?.info?.userId,
    lastSchoolId: user.lastSchool ? user.lastSchool.externalSchoolId : ''
  }));

  useEffect(() => {
    if (!isExcludedPage(window.location.pathname) && userId && !isInTaskManagerIframe) {
      const fetchTeacherProfile = async () => {
        const teacherProfile = await callWithCache(window.cet?.microservices?.teachersapi?.profile?.getProfileById, 'getProfileById', { profileId: userId });
        if (teacherProfile) {
          if (shouldUpdateProfile(teacherProfile)) {
            showProfileUpdater();
          }
        }
      };
      fetchTeacherProfile()
      .catch(console.error);
    }
  }, [lastSchoolId, userId, isInTaskManagerIframe]);

  function isExcludedPage(pathname) {
    return pathname?.includes('my-profile');
  }

  function updateSessionCount(value) {
    let shouldTeacherUpdateProfileSession = Number(CacheProvider.get('shouldTeacherUpdateProfile')) || 0;
    shouldTeacherUpdateProfileSession++;
    CacheProvider.set('shouldTeacherUpdateProfile', value || shouldTeacherUpdateProfileSession);
  }

  function shouldUpdateProfile(teacherProfile) {
    let shouldTeacherUpdateProfileSession = Number(CacheProvider.get('shouldTeacherUpdateProfile')) || 0;
    const lastUpdatedDate = new Date(teacherProfile.lastUpdatedDate)
    const today = new Date();
    const augustFirst = new Date(today.getFullYear(), 7, 1); // August 1st of the current year
    const didUpdatedThisYear = lastUpdatedDate > augustFirst
    const lastUpdatedSource = teacherProfile.lastUpdatedSource;
    const isNewYear = today > augustFirst;

    return ((isNewYear && !didUpdatedThisYear) || lastUpdatedSource !== 'Manual') && shouldTeacherUpdateProfileSession < 1;
  }

  function forceManualSave(selectedGrades) {
    sendLearningEvent({
      verbType: 'updated',
      objectId: userId,
      objectName: 'profile',
      objectType: 'user',
      optionalData: {
        interactionType: 'User',
        objectAdditionalInformation: { selectedGrades }
      }
    });
  }

  function endAnnualSession() {
    updateSessionCount(2);
  }

  function showProfileUpdater() {
    setShouldUserUpdateProfile(true);
  }

  function closeProfileUpdater() {
    setShouldUserUpdateProfile(false);
    updateSessionCount();
  }

  return {
    shouldUserUpdateProfile,
    showProfileUpdater,
    closeProfileUpdater,
    forceManualSave,
    endAnnualSession
  };
};
