import { useEffect, useState } from 'react';

export const useScripts = (scriptsList = []) => {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isActive = true;

    if (!scriptsList || !scriptsList.length) {
      setIsReady(true);
      setError(null);
    } else {
      setIsReady(false);
      setError(null);

      const loader = async () => {
        let promises = [];
        for (let i = 0; i < scriptsList.length; i++) {
          const scriptData = scriptsList[i];
          const promise = new Promise((resolve, reject) => {
            if (scriptData.isAlreadyLoaded && typeof scriptData.isAlreadyLoaded === 'function' && scriptData.isAlreadyLoaded()) {
              resolve();
            } else {
              var scriptElement = document.createElement('script');
              scriptElement.addEventListener('load', () => resolve());
              scriptElement.addEventListener('error', () => reject({ failedScriptData: scriptData }));
              scriptElement.src = scriptData.src;
              document.head.appendChild(scriptElement);
            }
          });

          if (scriptData.wait) {
            await promise;
          }

          promises.push(promise);
        }

        Promise.all(promises)
          .then(() => {
            if (isActive) {
              setIsReady(true);
              setError(null);
            }
          })
          .catch(error => {
            if (isActive) {
              setIsReady(true);
              setError(error);
            }
          });
      };

      loader();
    }

    return () => {
      isActive = false;
    };
  }, [scriptsList]);

  return {
    isReady,
    error
  };
};

export default useScripts;
