import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tag.module.scss';

import NewTag from './New';
import AttachedTag from './Attached';

export { NewTag, AttachedTag };

const Tag = ({ gradientFrom, gradientTo, angle="180deg", color, label, withSpout = true, className }) => {
  gradientTo = gradientTo || gradientFrom;
  return (
    <div
      className={`${styles.container} ${className || ''}`}
      style={{ background: `linear-gradient(${angle}, ${gradientFrom} 35%, ${gradientTo} 100%)`, color }}
    >
      {withSpout && <div className={styles.spout} style={{ backgroundColor: gradientFrom }} />}
      {label}
    </div>
  );
};

Tag.propTypes = {
  gradientFrom: PropTypes.string.isRequired,
  gradientTo: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  withSpout: PropTypes.bool,
};

export default Tag;
