import React, { useLayoutEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { UiContext } from '../../contexts';
import styles from './Modal.module.scss';
import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';

import Icon from '../Icon';

const Modal = ({ isActive, withCloseButton = true, closeButtonLabel, onClose, children, containerClassName = '', className = '', enableCloseFromOutside = true, hideOnLogin = true, skin = 'default' }) => {
  const { isMobileView } = useDeviceData();
  const [{ showLogin }] = useContext(UiContext);
  const { isLoginOpen } = useSelector(({ ui }) => ({ isLoginOpen: ui.login.isOpen }));
  const [modalContainerClassName, setModalContainerClassName] = useState(styles.container);
  const { sendUiUsageEvent } = useCetEvents();

  useLayoutEffect(() => {
    let animationTimeout;
    let isOn = true;

    animationTimeout = setTimeout(() => {
      animationTimeout = null;
      if (isOn) {
        setModalContainerClassName(`${styles.container} ${containerClassName} ${isActive ? styles.active : ''} ${styles['skin--' + skin]}`.trimEnd());
      }
    }, 200);

    return () => {
      isOn = false;

      if (animationTimeout) {
        clearTimeout(animationTimeout);
        animationTimeout = null;
      }
    };
  }, [containerClassName, isActive, skin]);

  const handleClose = (event) => {
    event.stopPropagation();

    const id = event?.currentTarget?.baseURI?.split('/');
    const documentId = id[id?.length - 1];
    const optionalData = {
      objectAdditionalInformation: {
        itemDocumentID: documentId ? documentId : ''
      }
    }
    sendUiUsageEvent({ category: 'modal', label: 'cancelButon', optionalData })
    onClose();
  };

  const handleCloseFromOutside = (event) => {
    if (enableCloseFromOutside)
      handleClose(event);
  }


  return (
    <>
      {isActive && (
        <FocusLock>
          <div className={modalContainerClassName} style={((hideOnLogin && showLogin) || isLoginOpen) ? { display: 'none' } : {}}>
            {!isMobileView && <div className={styles.blocker} onClick={handleCloseFromOutside} />}
            <div className={`${styles.modal} ${className}`} >
              {withCloseButton && (
                <button className={styles.closeButton} onClick={handleClose} aria-label={closeButtonLabel}>
                  <Icon icon="close" width={13.231} height={13.231} />
                </button>
              )}
              <div className={styles.modal_content}>{children}</div>
            </div>
          </div>
        </FocusLock>
      )}
    </>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool,
  withCloseButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  closeButtonLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideOnLogin: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  skin: PropTypes.oneOf(['default', 'mobileFullScreen', 'costume'])
};

export default Modal;
