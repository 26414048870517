import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { useSelector } from 'react-redux';
import { useGenderFormatMessage } from './useGenderFormatMessage';
import { UiContext } from '../contexts';
import useRecommendedSites from './useRecommendedSites';
import { KesemSearchParams } from '../utils/urls';

export const useSideBar = () => {
  const { isMobileView } = useDeviceData();
  const intl = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [{}, updateUiData] = useContext(UiContext);
  const { recommendedSites } = useRecommendedSites();
  const { isLoggedIn, lastSchool, isExtendedCatalog, productSubscriptions } = useSelector(({ user }) => ({
    isLoggedIn: Boolean(user?.info?.userId),
    lastSchool: user.lastSchool,
    isExtendedCatalog: user.info?.isExtendedCatalog,
    productSubscriptions: user.productSubscriptions
  }));
  const [subscriptionShvilimHe, setSubscriptionShvilimHe] = useState(null);
  const [subscriptionShvilimAr, setSubscriptionShvilimAr] = useState(null);
  const [subscriptionKesem, setSubscriptionKesem] = useState(null);

  const sectors = useMemo(() => {
    return isExtendedCatalog ? ['state', 'mmad', 'arab'] : lastSchool?.sectorId ? [lastSchool.sectorId] : intl.locale === 'ar' ? ['arab'] : ['state', 'mmad'];
  }, [isExtendedCatalog, lastSchool?.sectorId, intl.locale]);

  useEffect(() => {
    setSubscriptionShvilimHe(
      productSubscriptions?.filter(
        subscription =>
          (subscription.source === 'capsules' || subscription.source === 'generalProducts') && subscription.productID === getGenderFormatMessage('shvilimHe_productID')
      ).length > 0
    );
    setSubscriptionShvilimAr(
      productSubscriptions?.filter(
        subscription =>
          (subscription.source === 'capsules' || subscription.source === 'generalProducts') && subscription.productID === getGenderFormatMessage('shvilimAr_productID')
      ).length > 0
    );
    setSubscriptionKesem(
      productSubscriptions?.filter(
        subscription =>
          (subscription.source === 'capsules' || subscription.source === 'generalProducts') && subscription.productID === getGenderFormatMessage('kesem_productID')
      ).length > 0
    );
  }, [productSubscriptions, getGenderFormatMessage]);

  const filteredRecommendedSites = recommendedSites?.map(item => {
    return {
      image: item.hero.image,
      link: item.hero.link,
      link_target: item.hero.link_target,
      name: item.name
    };
  });

  const handleClick = (e, url) => {
    e.preventDefault();
    if (url !== '') {
      history.push(`/${intl.locale}/${url}`);
    } else {
      history.push(`/${intl.locale}`);
    }
  };

  const handleMyContent = (e, url) => {
    e.preventDefault();
    updateUiData({ oldSiteUrl: getGenderFormatMessage('mybag_items_url') });
  };

  const handleNewContent = (e, url) => {
    const myStudioLink = getGenderFormatMessage('myStudio_link');
    window.open(myStudioLink, '_blank');
  };

  return useMemo(() => {
    const toReturn = [];

    const home = {
      id: 'sideBar_homePage_mobile',
      onClick: e => handleClick(e, ''),
      label: getGenderFormatMessage('home_page'),
      isSelected: pathname === `/${intl.locale}`,
      icon: 'Home'
    };

    const aiChat = {
      id: 'sideBar_aiChat_menu',
      onClick: e => handleClick(e, 'ai-chat'),
      label: getGenderFormatMessage('sidebar_ai_chat'),
      isSelected: pathname === `/${intl.locale}/ai-chat`,
      icon: 'Stars',
      isNewItem: true,
      withTopSeparator: true
    };
    const aiSkills = {
      id: 'sideBar_aiSkills_menu',
      onClick: e => handleClick(e, 'ai-skills'),
      label: getGenderFormatMessage('sidebar_ai_skills'),
      isSelected: pathname === `/${intl.locale}/ai-skills`,
      icon: 'MagicPen',
      isNewItem: true
    };
    // const aiMain = { id: 'sideBar_aiMain_menu', onClick: (e) => handleClick(e, 'ai-main'), label: getGenderFormatMessage('sidebar_ai_main'), isSelected: pathname === `/${intl.locale}/ai-main`, icon: "Stars", isNewItem: true }
    const academe = {
      id: 'sideBar_academe_menu',
      onClick: e => handleClick(e, 'academe'),
      label: getGenderFormatMessage('sidebar_academe'),
      isSelected: pathname === `/${intl.locale}/academe`,
      icon: 'VideoCamera',
      isNewItem: true
    };

    const catalog = {
      id: 'sideBar_catalog',
      onClick: e => handleClick(e, 'catalog'),
      label: getGenderFormatMessage('sidebar_catalog'),
      isSelected: pathname === `/${intl.locale}/catalog`,
      icon: 'Flower',
      withTopSeparator: true
    };
    const books = {
      id: 'sideBar_books',
      onClick: e => handleClick(e, 'books'),
      label: getGenderFormatMessage('sidebar_books'),
      isSelected: pathname === `/${intl.locale}/books`
    };
    const courses = {
      id: 'sideBar_courses',
      onClick: e => handleClick(e, 'courses'),
      label: getGenderFormatMessage('sidebar_courses'),
      isSelected: pathname === `/${intl.locale}/courses`
    };
    const tools = {
      id: 'sideBar_creation_tools_menu',
      onClick: e => handleClick(e, 'tools'),
      label: getGenderFormatMessage('sidebar_tools'),
      isSelected: pathname === `/${intl.locale}/tools`
    };

    const tasks = {
      id: 'sideBar_tasks_menu_mobile',
      onClick: e => handleClick(e, 'tasks'),
      label: getGenderFormatMessage('tasks_management'),
      isSelected: pathname === `/${intl.locale}/tasks`,
      icon: 'Tasks',
      withTopSeparator: true
    };
    const favorites = {
      id: 'sideBar_favorites_mobile',
      onClick: e => handleClick(e, 'my-favorites'),
      label: getGenderFormatMessage('favorites'),
      isSelected: pathname === `/${intl.locale}/my-favorites`,
      icon: 'Favorites'
    };
    // const recommendations = { id: 'sideBar_recommendations_mobile', onClick:  (e) => handleClick(e,'recommendations'), label: getGenderFormatMessage('content_recommendations'), isSelected: pathname === `/${intl.locale}/recommendations` }
    // const teachersLearn = {
    //   id: 'learn_for_teachers_pageTitle',
    //   onClick: e => handleClick(e, 'teachers-learn'),
    //   label: getGenderFormatMessage('learn_for_teachers_pageTitle'),
    //   isSelected: pathname === `/${intl.locale}/teachers-learn`,
    //   icon: 'PedagogicalHelp'
    // };

    const newContent = {
      id: 'sideBar_newContent',
      onClick: e => handleNewContent(e, 'new-content'),
      label: getGenderFormatMessage('sidebar_new_content'),
      isSelected: pathname === `/${intl.locale}/tools/studio`
    };
    const myContent = {
      id: 'sideBar_myContent',
      onClick: e => handleMyContent(e, 'my-content'),
      label: getGenderFormatMessage('my_content'),
      isSelected: pathname === `/${intl.locale}/my-content`
    };
    const myStudio = { id: 'sideBar_myStudio_menu', label: getGenderFormatMessage('my_studio'), icon: 'Studio', items: [newContent, myContent] };

    const selTime = {
      id: 'sideBar_selTime',
      onClick: e => handleClick(e, 'sel-time'),
      label: getGenderFormatMessage('sel_time'),
      isSelected: pathname === `/${intl.locale}/sel-time`
    };
    const teachingSkills = {
      id: 'sideBar_teachingSkills',
      onClick: e => handleClick(e, 'teaching-skills'),
      label: getGenderFormatMessage('teaching_skills'),
      isSelected: pathname === `/${intl.locale}/teaching-skills`
    };
    const teachingPractices = {
      id: 'sideBar_teachingPractices',
      onClick: e => handleClick(e, 'teaching-practices'),
      label: getGenderFormatMessage('teaching_practices'),
      isSelected: pathname === `/${intl.locale}/teaching-practices`
    };

    const teachersCenterShvilim = {
      id: 'sideBar_teachersCenterShvilim_menu',
      onClick: e => handleClick(e, 'teachers-center-shvilim'),
      label: getGenderFormatMessage('teachersCenter_sidebar_subtitle'),
      expandOnHover: true,
      name: 'teachersCenterShvilim'
    };

    const teachersCenterKesem = {
      id: 'sideBar_teachersCenterKesem_menu',
      onClick: e => handleClick(e, `${KesemSearchParams}`),
      label: getGenderFormatMessage('kesem_and_friends'),
      name: 'teachersCenterKesem'
    };

    const teachersCenterChildren = [
      {
        title: getGenderFormatMessage('creation_tools_additional_title'),
        data: [
          subscriptionShvilimHe || subscriptionShvilimAr ? teachersCenterShvilim : undefined,
          subscriptionKesem ? teachersCenterKesem : undefined
        ].filter(item => item !== undefined),
        name: 'teachersCenter'
      },
      { title: getGenderFormatMessage('creation_tools_recommendedSites'), data: filteredRecommendedSites, name: 'recommendedSites' }
    ];

    const teachersCenter = {
      id: 'sideBar_creation_teachersCenter_menu',
      label:
        teachersCenterChildren[0].data.length === 0 || (sectors.length === 1 && sectors.includes('haredi')) || (intl.locale === 'ar' && !subscriptionShvilimAr)
          ? getGenderFormatMessage('creation_tools_recommendedSites')
          : getGenderFormatMessage('sidebar_tools'),
      children: teachersCenterChildren
    };

    const teachersRoom = {
      id: 'sideBar_teachersRoom_menu',
      label: getGenderFormatMessage('sidebar_teachers_room'),
      icon: 'TeachersRoom',
      items: [selTime, teachingSkills, teachingPractices]
    };

    if (isMobileView) {
      const content = { id: 'sideBar_content_menu', label: getGenderFormatMessage('learning_subjects'), icon: 'Flower', items: [books, courses] };
      catalog.icon = 'Content';
      content.items = [{ ...catalog, icon: null, withTopSeparator: false }, books, courses, tools];
      if (intl.locale === 'ar') {
        toReturn.push(home, catalog, tasks, favorites, { ...aiChat, withTopSeparator: false }, academe, content);
      } else {
        toReturn.push(home, catalog, tasks, favorites, { ...aiChat, withTopSeparator: false }, aiSkills, academe, content);
      }
    } else {
      books.icon = 'BookOpen';
      courses.icon = 'Globe';
      teachersCenter.icon = 'Directions';

      if (!isLoggedIn) {
        teachersCenter.children = [{ title: getGenderFormatMessage('creation_tools_recommendedSites'), data: filteredRecommendedSites, name: 'recommendedSites' }];
        teachersCenter.label = getGenderFormatMessage('creation_tools_recommendedSites');
        toReturn.push(home, { ...academe, withTopSeparator: true }, catalog, books, courses, teachersCenter);
      } else {
        if (intl.locale === 'ar' && sectors.includes('haredi')) {
          teachersCenter.children = [{ title: getGenderFormatMessage('creation_tools_recommendedSites'), data: filteredRecommendedSites, name: 'recommendedSites' }];
          toReturn.push(home, aiChat, academe, catalog, books, courses, teachersCenter, tasks, favorites, myStudio, teachersRoom);
        } else if (intl.locale === 'ar') {
          teachersCenter.children[0].data = [subscriptionShvilimAr ? teachersCenterShvilim : undefined].filter(item => item !== undefined);
          toReturn.push(home, aiChat, academe, catalog, books, courses, teachersCenter, tasks, favorites, myStudio, teachersRoom);
        } else if (sectors.length === 1 && sectors.includes('haredi')) {
          teachersCenter.children[0].data = [subscriptionKesem ? teachersCenterKesem : undefined].filter(item => item !== undefined);
          toReturn.push(home, aiChat, aiSkills, academe, catalog, books, courses, teachersCenter, tasks, favorites, myStudio, teachersRoom);
        } else {
          toReturn.push(home, aiChat, aiSkills, academe, catalog, books, courses, teachersCenter, tasks, favorites, myStudio, teachersRoom);
        }
      }

      teachersCenter.children = teachersCenter.children.filter(child => child.data.length > 0);
    }

    return toReturn;
  }, [intl.locale, isMobileView, pathname, isLoggedIn, filteredRecommendedSites]);
};
