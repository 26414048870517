import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { buttonPropTypes } from './PropTypes';
import { useCetEvents } from 'cet-components-lib/dist/hooks';

import './Button.scss';

const Button = ({
  sitekey = 'ebag',
  lang = 'he',
  color = 'none',
  view = 'contained',
  shape = 'ellipse',
  size = 'medium',
  isDisabled = false,
  onClick,
  to,
  target,
  children,
  className,
  category,
  label,
  optionalData,
  isActive = false,
  ...rest
}) => {
  const { sendUiUsageEvent } = useCetEvents(sitekey, lang);
  const buttonRef = useRef();

  const buttonCss = classNames({
    'cc-Button': true,
    ['cc-Button--' + [color]]: typeof color === 'string',
    ['cc-Button--' + [view]]: true,
    ['cc-Button--' + [shape]]: true,
    ['cc-Button--' + [size]]: true,
    'cc-Button--active': isActive,
    [className]: className
  });

  const handleOnClick = event => {
    if (category && label) {
      sendUiUsageEvent({ category, label, optionalData });
    }
    if (onClick) {
      onClick(event);
    }
  };

  const Comp = to ? 'a' : 'button';

  return (
    <Comp
      ref={buttonRef}
      {...rest}
      href={to}
      target={target}
      style={{
        color: typeof color === 'object' && color?.color,
        backgroundColor: typeof color === 'object' && color?.backgroundColor,
        border: typeof color === 'object' && color?.borderColor && `1px solid ${color?.borderColor}`
      }}
      onClick={handleOnClick}
      className={buttonCss}
      disabled={isDisabled}
    >
      {children}
    </Comp>
  );
};

Button.propTypes = {
  ...buttonPropTypes,
  children: PropTypes.node.isRequired
};

export default Button;
