const types = {
  setCurrentItem: 'setCurrentItem',
  setListItems: 'setListItems',
  setFilters: 'setFilters',
  setDimensions: 'setDimensions',
  updateFreeStatus: 'updateFreeStatus',
  updateAuthorizationStatus: 'updateAuthorizationStatus'
};

const initialState = {
  currentItem: null,
  listItems: [],
  dimensions: [],
  filters: {},
  freeStatus: {},
  authorizationStatus: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setCurrentItem:
      return { ...state, currentItem: { ...action.itemData } };
    case types.setListItems: {
      const { filters } = state;
      let { listItems } = action;

      if (filters.itemTypes && filters.itemTypes !== 'all') {
        listItems = listItems.filter(group => group.itemTypes === filters.itemTypes);
      }

      return { ...state, listItems };
    }
    case types.setDimensions: {
      const filters = { ...state.filters };
      if (filters.itemTypes) {
        const typeIsActive = action.dimensions.find(dimension => dimension.value === filters.itemTypes);
        if (!typeIsActive) {
          delete filters.itemTypes;
        }

        return { ...state, filters, dimensions: [...action.dimensions] };
      }

      return { ...state, dimensions: [...action.dimensions] };
    }
    case types.updateFreeStatus:
      return { ...state, freeStatus: { ...state.freeStatus, ...action.itemFreeStatus } };
    case types.updateAuthorizationStatus:
      return { ...state, authorizationStatus: { ...state.authorizationStatus, ...action.itemAuthorizationStatus } };
    default:
      return state;
  }
};

export const setCurrentItem = itemData => ({
  type: types.setCurrentItem,
  itemData
});


export const setDimensions = dimensions => ({
  type: types.setDimensions,
  dimensions
});

export const setListItems = listItems => ({
  type: types.setListItems,
  listItems
});

export const updateFreeStatus = itemFreeStatus => ({
  type: types.updateFreeStatus,
  itemFreeStatus
});

export const updateAuthorizationStatus = itemAuthorizationStatus => ({
  type: types.updateAuthorizationStatus,
  itemAuthorizationStatus
});

export default reducer;
