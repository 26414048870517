import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getImageUrl } from '../utils/resourceLoader';
import { useProductAuthorization } from '../hooks';
import { setCurrentItem } from '../state/items';

export const useItem = itemId => {
  const intl = useIntl();
  const { dimensions } = useSelector(({ dimensions }) => ({
    dimensions: dimensions.dimensions
  }));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(null);
  const [item, setItem] = useState(null);
  const { checkIsAuthorizedItem } = useProductAuthorization();

  const createDataList = useCallback(
    (subjects, subSubjects, details, data) => {
      let dataList = [];

      let subjectsList = [];
      if (dimensions[subjects] && data.dimensions[subjects]) {
        Object.keys(data.dimensions[subjects]).map(subjectKey => {
          let filter = dimensions[subjects].filter(f => f.id === subjectKey);
          if (filter.length > 0) {
            subjectsList.push(filter[0]);
          }
          return null;
        });
      }

      let subSubjectsList = [];
      if (dimensions[subSubjects] && data.dimensions[subSubjects]) {
        Object.keys(data.dimensions[subSubjects]).map(subSubjectKey => {
          let filter = dimensions[subSubjects].filter(f => f.id === subSubjectKey);
          if (filter.length > 0) {
            subSubjectsList.push(filter[0]);
          }
          return null;
        });
      }

      let detailsList = [];
      if (dimensions[details] && data.dimensions[details]) {
        Object.keys(data.dimensions[details]).map(detailsKey => {
          let filter = dimensions[details].filter(f => f.id === detailsKey);
          if (filter.length > 0) {
            detailsList.push(filter[0]);
          }
          return null;
        });
      }

      subjectsList.map(subject => {
        let detailsFilter = detailsList.filter(f => f.parentId === subject.id);
        let subSubjectsFilter = subSubjectsList.filter(f => f.parentId === subject.id);
        if (detailsFilter.length === 0 && subSubjectsFilter.length === 0) {
          dataList.push({
            subject: subject.name
          });
        }
        detailsFilter.map(detail => {
          dataList.push({
            subject: subject.name,
            details: detail.name
          });
          return null;
        });
        subSubjectsFilter.map(subSubject => {
          let detailsFilter1 = detailsList.filter(f => f.parentId === subSubject.id);
          if (detailsFilter1.length === 0) {
            dataList.push({
              subject: subject.name,
              subSubject: subSubject.name
            });
          }
          detailsFilter1.map(detail => {
            dataList.push({
              subject: subject.name,
              subSubject: subSubject.name,
              details: detail.name
            });
            return null;
          });
          return null;
        });
        return null;
      });

      return dataList;
    },
    [dimensions]
  );

  const getSortedItemTypes = useCallback(
    data => {
      let sortedItemTypes = [];
      if (data.dimensions.itemTypes) {
        if (Object.keys(data.dimensions.itemTypes).length > 1) {
          const sortByReference = dimensions.itemTypes.map(item => item.id);
          data.dimensions.itemTypes = Object.keys(data.dimensions.itemTypes)
            .sort((a, b) => (sortByReference.indexOf(a) < sortByReference.indexOf(b) ? -1 : sortByReference.indexOf(a) > sortByReference.indexOf(b) ? 1 : 0))
            .reduce((acc, key) => ({ ...acc, [key]: data.dimensions.itemTypes[key] }), {});
        }
        sortedItemTypes = Object.keys(data.dimensions.itemTypes).map(key => {
          return { icon: key, title: data.dimensions.itemTypes[key] };
        });
      }
      return sortedItemTypes;
    },
    [dimensions.itemTypes]
  );

  const getItemInfo = useCallback(itemId => {
    if (itemId) {
      return window.cet.microservices.contentapi.content
        .getItemById({
          itemId: itemId
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      return null;
    }
  }, []);

  const checkItemAuthorization = useCallback(
    (externalId, type, parentId, lang) => {
      checkIsAuthorizedItem(externalId, type, parentId, lang)
        .then(response => {
          return response;
        })
        .catch(error => {
          console.log(error);
        });
    },
    [checkIsAuthorizedItem]
  );

  const loadItem = useCallback(() => {
    if (itemId) {
      setIsLoading(true);
      setErrorLoading(null);
      setItem(null);

      window.cet.microservices.contentapi.content
        .getItemAndDimensionsByIdFast({
          itemId: itemId,
          lang: intl.locale
        })
        .then(async data => {
          let type = 'Lo';
          let isLoItem = false;
          switch (data.content.itemSource.toLowerCase()) {
            case 'lo':
              type = 'Lo';
              isLoItem = true;
              break;
            case 'kotar':
              type = 'Book';
              break;
            case 'toc':
              type = 'Course';
              break;
            case 'marketing':
              type = 'Lo';
              break;
            case 'externalcourse':
              type = 'externalCourse';
              break;
            case 'academe':
              type = 'academe';
              break;
            default:
              type = 'Lo';
              break;
          }

          if (data?.content?.additionalInfo && typeof data.content.additionalInfo === 'string') {
            try {
              data.content.additionalInfo = JSON.parse(data.content.additionalInfo);
            } catch (error) {
              console.error(error);
              data.content.additionalInfo = null;
            }
          }

          let activityTypeLabel = '';
          if (dimensions && dimensions.activityTypes) {
            let activityTypeFilter = dimensions.activityTypes.filter(activityType => activityType.id === data.content.activityType);
            if (activityTypeFilter && activityTypeFilter.length > 0) {
              activityTypeLabel = activityTypeFilter[0].name;
            }
          }

          //remove duplicates values from a list of objects
          const unique = arr => {
            return arr.filter((item, index) => index === arr.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item)));
          };

          let generalSkills = unique(createDataList('generalSkills', 'generalSubskills', 'generalSkillsDetails', data));
          let skills = unique(createDataList('skills', 'subskills', 'skillsDetails', data));
          skills = [...generalSkills, ...skills];
          let syllabus = unique(createDataList('syllabusSubjects', 'syllabusSubsubjects', 'syllabusDetails', data));

          const now = new Date();
          const publishedDate = new Date(data.content.lastCatalogPublishedDate);
          const dateDiff = (now.getFullYear() - publishedDate.getFullYear()) * 12 - publishedDate.getMonth() + now.getMonth();
          const isNew = dateDiff < 2;

          setItem({
            ...data.content,
            isActive: true,
            isNew,
            type: type,
            isLoItem: isLoItem,
            isInFavorites: false,
            banner: getImageUrl(data.content.thumbnailId, 666, 239) || '/bad-image.svg',
            thumbnail: getImageUrl(data.content.thumbnailId, 220, 186) || '/bad-image.svg',
            documentId: data.content.externalContextId,
            ageGrades: data.dimensions.ageGrades
              ? Object.keys(data.dimensions.ageGrades)
                  .map(grade => grade.split('grade')[1])
                  .join(', ')
              : '',
            grades: data.dimensions.ageGrades
              ? Object.keys(data.dimensions.ageGrades)
                  .map(key => intl.formatMessage({ id: key }))
                  .join(', ')
              : '',
            disciplines: data.dimensions.disciplines ? Object.keys(data.dimensions.disciplines).join(', ') : '',
            professions: data.dimensions.disciplines ? Object.values(data.dimensions.disciplines).join(', ') : '',
            yearCycles: data.dimensions.yearCycles ? Object.values(data.dimensions.yearCycles).join(', ') : '',
            language: data.content.contextLanguage,
            activityTypeLabel: activityTypeLabel,
            itemTypes: getSortedItemTypes(data),
            isCourse: type === 'Course' || (data && data.dimensions && data.dimensions.itemTypes && data.dimensions.itemTypes.course),
            pedagogicValues: data.dimensions.pedagogicValues
              ? Object.values(data.dimensions.pedagogicValues).map(val => {
                  let valList = val.split(' > ');
                  return {
                    subject: valList[0],
                    details: valList.length > 1 ? valList[1] : ''
                  };
                })
              : [],
            skills: skills,
            syllabus: syllabus,
            contentParent: data.contentParent,
            itemDimensions: data.dimensions
          });
          setIsLoading(false);
          setErrorLoading(null);
        })
        .catch(error => {
          setIsLoading(false);
          setErrorLoading(error);
          setItem(null);
        });
    } else {
      setIsLoading(false);
      setErrorLoading(null);
      setItem(null);
    }
  }, [createDataList, dimensions, getSortedItemTypes, intl, itemId]);

  useEffect(() => {
    loadItem();
  }, [itemId]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (item) {
      dispatch(setCurrentItem({ ...item }));
      if (item.contentParent && item.contentParent[0].parentContext === 'course') {
        getItemInfo(item.contentParent[0].parentId).then(parentItem => {
          checkItemAuthorization(item.externalContextId, item.type, parentItem.externalContextId, item.language);
        });
      } else {
        checkItemAuthorization(item.externalContextId, item.type, null, item.language);
      }
    }
  }, [checkItemAuthorization, dispatch, getItemInfo, item]);

  return {
    isItemLoading: isLoading,
    itemErrorLoading: errorLoading,
    item: item,
    reloadItem: loadItem,
    getItemInfo: getItemInfo
  };
};
