import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DominateSystemMessage from '../DominateSystemMessage';
import { CacheProvider } from '../../utils/cacheProvider';
import { getInnerUrl } from '../../utils/urls';
import { safelyGetInnerHTML } from '../../utils/html';
import { useGenderFormatMessage } from '../../hooks';

import Href from '../Href';
import Icon from '../Icon';

import styles from './EliteSystemMessage.module.scss';

const EliteSystemMessage = ({
  content = '',
  link = '',
  linkTarget = '',
  popupContent = '',
  show = false,
  showPopup = false,
  handleClose,
  handlePopupOpen,
  handlePopupClose
}) => {
  const history = useHistory();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [relativeLink, setRelativeLink] = useState(false);
  const [isShowEliteSystemMessage, setIsShowEliteSystemMessage] = useState(false);

  useEffect(() => {
    const isClosed = CacheProvider.get('eliteSystemMessageClosed') ?? false
    setIsShowEliteSystemMessage(!isClosed);
  }, []);

  useEffect(() => {
    setRelativeLink(getInnerUrl(link));
  }, [link]);

  const close = () => {
    if (handleClose) {
      handleClose();
      CacheProvider.set('eliteSystemMessageClosed', 'true');
    }
    setTimeout(() => {
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 1);
  };

  const popupOpen = () => {
    if (handlePopupOpen) {
      handlePopupOpen();
    }
    setTimeout(() => {
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 1);
  };

  const onContentClick = e => {
    const aTag = e.target.closest('a');
    if (aTag && e.currentTarget.contains(aTag)) {
      if (!aTag.target || aTag.target !== '_blank') {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        let linkTag = window.document.createElement('a');
        linkTag.href = getInnerUrl(aTag.href);
        let historyState = {
          pathname: linkTag.pathname,
          search: linkTag.search
        };
        history.push(`${historyState.pathname}${historyState.search}`, historyState);
      }
      close();
    } else if (linkTarget !== '') {
      if (linkTarget === 'popup') {
        popupOpen();
      }
      close();
    }
  };

  let message = (
    <div
      data-scrolling-handler-element="eliteSystemMessageText"
      className={styles.message_content}
      style={linkTarget === '' ? {} : { cursor: 'pointer' }}
      onClick={onContentClick}
    >
      {safelyGetInnerHTML(content || '')}
    </div>
  );
  let isMessageHasLink = relativeLink && (linkTarget === '_top' || linkTarget === '_blank');

  return (
    <>
      {isShowEliteSystemMessage && (
        <div data-scrolling-handler-element="eliteSystemMessage">
          <div className={styles.message_padding} data-scrolling-handler-element="eliteSystemMessagePadding"></div>
          <div className={styles.message_contaner} data-scrolling-handler-element="eliteSystemMessageContaner">
            <div className={styles.message}>
              {isMessageHasLink && (
                <Href to={relativeLink} target={linkTarget}>
                  {message}
                </Href>
              )}
              {!isMessageHasLink && message}
              <button className={styles.closeButton} onClick={close} aria-label={getGenderFormatMessage('close')}>
                <Icon icon="close" width={13.231} height={13.231} />
              </button>
            </div>
          </div>
        </div>
      )}
      <DominateSystemMessage content={popupContent} show={showPopup} handleClose={handlePopupClose} />
    </>
  );
};

EliteSystemMessage.propTypes = {
  content: PropTypes.string,
  link: PropTypes.string,
  linkTarget: PropTypes.oneOf(['', '_top', '_blank', 'popup']),
  popupContent: PropTypes.string,
  show: PropTypes.bool,
  showPopup: PropTypes.bool,
  handleClose: PropTypes.func,
  handlePopupOpen: PropTypes.func,
  handlePopupClose: PropTypes.func
};

export default EliteSystemMessage;
