export const labels = {
  he: {
    newItemLabel: 'חדש',
    more: 'עוד',
    sidebarAriaLabel: 'תפריט צד'
  },
  ar: {
    newItemLabel: 'جديد',
    more: 'اخرى',
    sidebarAriaLabel: 'قائمة جانبية'
  },
  en: {
    newItemLabel: 'New',
    more: 'more',
    sidebarAriaLabel: 'Side Menu'
  }
};
