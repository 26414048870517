const types = {
    setDeviceData: 'setDeviceData',
};

const initialState = {
    isDeviceDataReady: false,
    isMobile: false,
    isMobileView: false,
    isDesktop: true,
    isAndroid: false,
    isIOS: false,
    isPortrait: true,
    isLandscape: false,
    viewWidth: 0,
    viewHeight: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setDeviceData:
      return { ...state, ...(action.deviceData), isDeviceDataReady: true };
    default:
      return state;
  }
};

export const setDeviceData = (deviceData) => ({
  type: types.setDeviceData,
  deviceData: deviceData
});

export default reducer;
  