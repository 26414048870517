import React, {useState, useRef, useEffect, useContext, useMemo, useCallback} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { optionsPropTypes } from '../DataList/PropTypes';
import { debounce, sleep } from '../../utils/events';
import { getQueryStringParams, isHomePage, KesemSearchParams } from '../../utils/urls';
import { useDimensionsForSelect, useFilterItemTypes, useGenderFormatMessage, useGroupOptionsByAgeGrades } from '../../hooks';
import { callWithCache } from '../../utils/callWithCache';
import { useDeviceData, useCetEvents } from 'cet-components-lib/dist/hooks';
import { useDispatch, useSelector } from "react-redux";
import { setFilteredDimensions } from '../../state/dimensions';

import { Icon } from 'cet-components-lib/dist/UI/Icon';
import { Button } from 'cet-components-lib/dist/UI/Button';
import { Title } from 'cet-components-lib/dist/UI/Title';
import { Select } from 'cet-components-lib/dist/UI/Select';

import { useLists } from '../../pages/Lists/useLists';


import Input from '../Input';

import styles from './SearchBox.module.scss';
import clearIcon from "../../img/clear_white.svg";

const SearchBox = ({
  isOpen,
  searchPlaceholder,
  autoCompleteOptions,
  autoCompleteCategory,
  onRequestAutoComplete,
  onFocus,
  onBlur,
  onSelectChange = () => {},
  onSubmitSearch,
  isTeacherCenterKesem = false,
  clearSearchRef
}) => {
  const intl = useIntl();
  const { search, pathname } = useLocation();
  const isSearchPage = pathname === `/${intl.locale}/search`;
  const isCatalogPage = pathname === `/${intl.locale}/catalog`;
  const dispatchToStore = useDispatch();
  const { sendUiUsageEvent } = useCetEvents();

  const { disciplinesAndAgeGrades, ageGradesAndDisciplines } = useLists(isCatalogPage, isSearchPage);
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const { push } = useHistory();
  const allItemTypes = useFilterItemTypes();
  const dimensionsToSelect = useRef(['ageGrades', 'disciplines', 'syllabusSubjects', 'syllabusSubsubjects']);
  const { ageGrades, disciplines, syllabusSubsubjects, dimensionsMapper, dependenciesMapper } = useDimensionsForSelect(dimensionsToSelect.current, false);

  const [searchBy, setSearchBy] = useState('');
  const [selectedAgeGrades, setSelectedAgeGradesState] = useState([]);
  const selectedAgeGradesRef = useRef([]);
  const setSelectedAgeGrades = (grades) => {
    selectedAgeGradesRef.current = grades;
    setSelectedAgeGradesState(() => grades);
  }
  const [selectedDisciplines, setSelectedDisciplines] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedSubSubject, setSelectedSubSubject] = useState();
  const [syllabusSubjectsOptions, setSyllabusSubjectsOptions] = useState([]);
  const [syllabusSubsubjectsOptions, setsyllabusSubsubjectsOptions] = useState([]);
  const [isShowSubjects, setIsShowSubjects] = useState(false);
  const [optionalData, setOptionalData] = useState();
  const [error, setError] = useState();
  const [clearFiltersCalled, setClearFiltersCalled] = useState(false);
  const [updateDimensions, setUpdateDimensions] = useState({getDimensions: false, selectChange: false});
  const dimensionsDataRef = useRef();
  const searchFormRef = useRef();
  const searchButtonRef = useRef();
  const focusedOnOpenRef = useRef();
  const didSearchFromAutocomplete = useRef();
  const { sectors, relevantDisciplinesBySectors } = useSelector(({ sectors }) => ({
    sectors: sectors.sectors,
    relevantDisciplinesBySectors: sectors.relevantDisciplinesBySectors
  }));

  const searchIconSize = isMobileView ? 32 : 22;
  const searchPlaceholderText = searchPlaceholder || getGenderFormatMessage('search_placeholder');

  const getDisciplineByAgeGrade = useCallback((ageGrades) => {
    let result = JSON.parse(JSON.stringify(disciplines));
    if (ageGrades?.length && disciplinesAndAgeGrades) {

      const ageGradesValues = ageGrades.map(ageGrade => ageGrade.value);
      let relevantDisciplines = ageGradesValues.flatMap(key => Object.keys(dependenciesMapper.ageGrades.disciplines[key] || {}));
      relevantDisciplines = [...new Set(relevantDisciplines)];

      if (relevantDisciplines) {
        result = result.filter(discipline => relevantDisciplines.includes(discipline.value));
      }
    }
    return result
        .filter(discipline => relevantDisciplinesBySectors.includes(discipline.value))
        .sort((a, b) =>  a.text < b.text ? -1 : a.text > b.text ? 1 : 0);
  }, [disciplines, disciplinesAndAgeGrades, selectedAgeGrades, relevantDisciplinesBySectors]);

  const getAgeGradesByDiscipline = useCallback((disciplines) => {
    let result = JSON.parse(JSON.stringify(ageGrades));
    // Remove grade13 and grade14 from ageGrades options. They are currently not relevant.
    result = result.filter(ageGrade => ageGrade.value !== 'grade13' && ageGrade.value !== 'grade14');

    if (disciplines?.value) {
      const relevantAgeGrades = Object.keys(dependenciesMapper?.disciplines?.ageGrades[disciplines.value]);
      if (relevantAgeGrades) {
        result.map(ageGrade => {
          if(!relevantAgeGrades.find(item => item === ageGrade.value)){
            ageGrade.isDisabled = true;
          }
        });
      }
    }
    return result;
  },[ageGrades, selectedDisciplines, ageGradesAndDisciplines]);

  const disciplinesOptions = getDisciplineByAgeGrade(selectedAgeGrades);
  const ageGradesOptions = getAgeGradesByDiscipline(selectedDisciplines);


  const handleSearch = (searchString, option1, option2, option3, option4, isExactSearch, isTeacherCenterKesem) => {
    setError(false);
    if (!searchString && option1?.length === 0 && !option2) {
      setError(true);
      return;
    }
    handleSearchBlur({
      relatedTarget: searchFormRef.current,
    });
    onSubmitSearch(searchString, option1, option2, option3, option4, isExactSearch, isTeacherCenterKesem);
  };

  const handleAgeGradesChange = (options) => {
    setSelectedSubject();
    setSelectedSubSubject();
    setSelectedAgeGrades(options);
    onSelectChange('ageGrades', options);
  };

  // Disable selected discipline if it's not relevant to the selected ageGrade
  useEffect(() => {
    if(selectedDisciplines?.value) {
      const selectedDiscipline = disciplinesOptions.find(discipline => discipline.value === selectedDisciplines.value);
      if(selectedDiscipline?.isDisabled) {
          setSelectedDisciplines(null);
      }
    }
  }, [handleAgeGradesChange]);

  const handleDisciplinesChange = (option) => {
    setSelectedSubject(null);
    setSelectedSubSubject(null);
    setSelectedDisciplines(option);
    onSelectChange('disciplines', [option]);
  };

  const handleSubjectChange = (option) => {
    setSelectedSubSubject(null);
    if (option.value === 'all') {
      setSelectedSubject(null);
    } else {
      setSelectedSubject(option);
    }
  }

  const handleSubSubjectChange = (option) => {
    if (option.value === 'all') {
      setSelectedSubSubject(null);
    } else {
      setSelectedSubSubject(option);
    }
  }

  const handleSearchTextChange = (event) => {
    const { value } = event.target;
    setSearchBy(value);
    // TODO: remove this when we want to enable autocomplete
    // const isSpacebarPressed = event.nativeEvent.data === ' ';
    // const wait = isSpacebarPressed ? 100 : 1000;
    // debounce(onRequestAutoComplete, wait)(value, selectedAgeGrades.map(ageGrade => ageGrade.value).join(','), selectedDisciplines?.value);
  };

  const handleSubmitSearch = async (event) => {
    if (event.type === 'submit') {
      event.stopPropagation();
      event.preventDefault();
    }
    await sleep(10);
    (event.type === 'click' || event?.key === 'Enter') && debounce(handleSearch, 100, true)(searchBy, selectedAgeGradesRef.current, selectedDisciplines, selectedSubject, selectedSubSubject, false, isTeacherCenterKesem);
  };

  const handleSearchFocus = () => {
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleSearchBlur = (event) => {
    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  useEffect(() => {
    if (!isSearchPage) {
      window.last_location_before_search = {
        pathname,
        search
      };
    }
  }, [pathname, search])

  const handleSearchByChange = (event) => {
    if (event.key === 'Enter') {
      if (didSearchFromAutocomplete.current) {
        didSearchFromAutocomplete.current = false;
      } else {
        sendUiUsageEvent({ category: 'upperSearch', label: getGenderFormatMessage( 'search' ), optionalData: { fieldsResponse: optionalData, object_additional_information: optionalData } });
        handleSubmitSearch(event);
      }
    }
  };

  const handleAutoCompleteSelect = (option) => {
    let ageGrades, disciplines;
    if (option.dimensions.length) {
      ageGrades = option.dimensions.find((op) => op.prop === 'ageGrades');
      setSelectedAgeGrades(ageGrades);
      disciplines = option.dimensions.find((op) => op.prop === 'disciplines');
      setSelectedDisciplines(disciplines);
    } else {
      ageGrades = selectedAgeGrades;
      disciplines = selectedDisciplines;
    }
    didSearchFromAutocomplete.current = true;
    setSearchBy(option.realValue);
    handleSearch(option.realValue, ageGrades, disciplines, null, null, false);
    handleSearchBlur({ relatedTarget: searchFormRef.current, });
  };
  const groupedOptionsByAgeGrade = useGroupOptionsByAgeGrades(syllabusSubjectsOptions, selectedAgeGrades.map(ageGrade => ageGrade.value), 'syllabusSubjects');

  useEffect(() => {
    if (selectedDisciplines?.value && selectedAgeGrades.length) {
      let itemTypesArr = [];
      if (Object.keys(allItemTypes).length) { // Create list of all existing itemTypes
        itemTypesArr = [...new Set(Object.values(allItemTypes).flat())].filter(type => type !== 'all');
      }
      const params = {
        ageGrade: selectedAgeGrades.map(ageGrade => ageGrade.value),
        discipline: selectedDisciplines.value,
        itemTypes: itemTypesArr,
        syllabusSubjects: selectedSubject?.value || null,
        syllabusSubsubjects: selectedSubSubject?.value || null,
        sectors,
        lang: intl.locale
      };

      callWithCache(window.cet.microservices.contentapi.content.getDimensionsByProfile, 'getDimensionsByProfile', params)
        .then(data => {
          dimensionsDataRef.current = data.dimensions;
          setUpdateDimensions(updateDimensions => ({...updateDimensions, getDimensions: true}));

          dispatchToStore(setFilteredDimensions({ filteredDimensions: data } ));
        });
    }
  }, [selectedAgeGrades, selectedDisciplines?.value, dimensionsMapper?.syllabusSubjects, dimensionsMapper?.syllabusSubsubjects, allItemTypes, getGenderFormatMessage, intl.locale, sectors, selectedSubject?.value, selectedSubSubject?.value]);

  useEffect(() => {
    const asyncUpdateDimensions = async () => {
      await sleep(1000); // wait for fetch data from getDimensionsByProfile to update
      if (selectedAgeGrades.length && selectedDisciplines?.value) {
        setUpdateDimensions(updateDimensions => ({...updateDimensions, selectChange: true}));
      }
    };
    asyncUpdateDimensions();
  }, [selectedAgeGrades, selectedDisciplines?.value]);

  useEffect(() => {
    if (updateDimensions.selectChange && updateDimensions.getDimensions) {
      updateSyllabusSubjectsOptions(dimensionsDataRef.current?.syllabusSubjects);
      updateSyllabusSubsubjectsOptions(dimensionsDataRef.current?.syllabusSubsubjects);
      setUpdateDimensions({getDimensions: false, selectChange: false});
    }
  },[updateDimensions.getDimensions, updateDimensions.selectChange]);

  const updateSyllabusSubjectsOptions = (syllabusSubjects = []) => {
    const syllabusSubjectsOptions = syllabusSubjects.map(subject => {
      const label = dimensionsMapper.syllabusSubjects[subject].name;
      return { value: subject, label, text: label }
    }).sort((a, b) => a.text.localeCompare(b.text));

    syllabusSubjectsOptions.unshift({ value: 'all', label: getGenderFormatMessage('all'), text: getGenderFormatMessage('all') });
    setSyllabusSubjectsOptions(syllabusSubjectsOptions);
  };

  const updateSyllabusSubsubjectsOptions = (syllabusSubsubjects = []) => {
    const syllabusSubsubjectsOptions = syllabusSubsubjects.map(subsubject => {
      const label = dimensionsMapper.syllabusSubsubjects[subsubject].name;
      return { value: subsubject, label, text: label }
    })
    syllabusSubsubjectsOptions.unshift({ value: 'all', label: getGenderFormatMessage('all'), text: getGenderFormatMessage('all') })
    setsyllabusSubsubjectsOptions(syllabusSubsubjectsOptions);
  };

  // Set searchBy, ageGrades, disciplines, subjects and subSubjects from url params
  useEffect(() => {
    const params = getQueryStringParams();
    if(!searchBy && searchBy !== null){
      const allFields = isNaN(params.allFields) ? params.allFields : params.allFields.toString();
      setSearchBy(allFields);
    }
    if (!selectedAgeGrades.length && params?.ageGrades && !clearFiltersCalled) {
      const paramsAgeGrades = params.ageGrades.split(',');
      setSelectedAgeGrades(ageGrades.filter((option) => paramsAgeGrades.includes(option.value)));
    }
    if (!selectedDisciplines && !clearFiltersCalled) {
      setSelectedDisciplines(() => disciplines.find((option) => option.value === params.disciplines));
    }

    setTimeout(() => { // wait for syllabusSubjectsOptions and syllabusSubsubjects to be loaded
      if (!selectedSubject && selectedSubject !== null) {
        setSelectedSubject(() => syllabusSubjectsOptions.find((option) => option.value === params.syllabusSubjects));
      }
      if (!selectedSubSubject && selectedSubSubject !== null) {
        setSelectedSubSubject(() => syllabusSubsubjects.find((option) => option.value === params.syllabusSubsubjects));
      }
    }, 100);
  }, [search, ageGrades, disciplines, syllabusSubjectsOptions, syllabusSubsubjects]);

  // Reset clearFiltersCalled
  useEffect(() => {
    if(clearFiltersCalled) {
      setTimeout(() => {
        setClearFiltersCalled(false);
      }, 100)
    }
  }, [clearFiltersCalled]);

  useEffect(() => {
    setError(false);
    const searchByInput = searchFormRef.current?.querySelector('input');
    if (isOpen) {
      if (searchByInput && !searchBy && !focusedOnOpenRef.current) {
        focusedOnOpenRef.current = true;
        searchByInput.focus();
      }
    } else {
      focusedOnOpenRef.current = false;
    }
  }, [isOpen, searchBy]);

  useEffect(() => {
    if (isMobileView && isOpen && searchBy) {
      debounce(onRequestAutoComplete, 100)(searchBy, selectedAgeGrades.map(ageGrade => ageGrade.value).join(','), selectedDisciplines?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView, isOpen, searchBy, selectedAgeGrades, selectedDisciplines?.value]);

  // Updates event data
  useEffect(() => {
    let data = {};
    data.string = searchBy;
    if (selectedAgeGrades.length) {
      data['ageGrades'] = selectedAgeGrades.map(option => option.value).join(',');
    }
    if (selectedDisciplines) {
      data['disciplines'] = selectedDisciplines.value;
    }
    if (selectedSubject) {
      data['syllabusSubjects'] = selectedSubject.value;
    }
    if (selectedSubSubject) {
      data['syllabusSubsubjects'] = selectedSubSubject.value;
    }

    setOptionalData(data);

    if (selectedAgeGrades.length && selectedDisciplines) {
      setIsShowSubjects(true);
    } else {
      setIsShowSubjects(false);
      clearSubjects();
    }

  }, [searchBy, selectedAgeGrades, selectedDisciplines, selectedSubject, selectedSubSubject]);

  const clearSubjects = () => {
    setSelectedSubject();
    setSelectedSubSubject();
  }

  const clearFilters = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setClearFiltersCalled(true);
    setSearchBy(null);
    if (!isTeacherCenterKesem) {
      setSelectedAgeGrades([]);
      setSelectedDisciplines(null);
    }

    clearSubjects();
    onRequestAutoComplete('');
    onSelectChange('ageGrades', []);
    onSelectChange('disciplines', []);

    let params = {};
    if (isTeacherCenterKesem){
      params = `/${intl.locale}/${KesemSearchParams}`; // Return to Kesem
    }  

    push(params);
  };

  if (clearSearchRef) {
    clearSearchRef.current = clearFilters;
  }
  const clearAgeGrades = () => {
    setClearFiltersCalled(true);
    setSelectedAgeGrades([]);
    onSelectChange('ageGrades', []);
  };
  
  // listen for popstate event
  useEffect(() => {
    const handlePopState = () => {
      clearFilters();
    };

    window.addEventListener('popstate', handlePopState);
    
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const clearBtn =
    <Button color="basic" view="link" onClick={(e) => clearFilters(e)} id="nwaClearFiltersButton" label="clearFilters">
      <div className={styles.selects_undo}>
        <span className={styles.selects_undo_icon}>
          <img src={clearIcon} alt={getGenderFormatMessage('clear')} />
        </span>
        <span>
          {getGenderFormatMessage('clear')}
        </span>
      </div>
    </Button>;

  const syllabusSubsubjectsOptionsForDropDown = useMemo(() => {
    const allOption = syllabusSubsubjectsOptions.find(option => option.value === 'all');
    const filteredSyllabusSubsubjects = syllabusSubsubjects
      ?.filter(item1 => syllabusSubsubjectsOptions.some(item2 => item2.value === item1.value))
      .sort((a, b) => a.text.localeCompare(b.text))
      .filter(item => item.parentId === selectedSubject?.value);

    const result = allOption ? [allOption, ...filteredSyllabusSubsubjects] : filteredSyllabusSubsubjects;
    
    return result;
  }, [selectedSubject?.value, syllabusSubsubjects, syllabusSubsubjectsOptions]);

  const subjectsDropdowns = useMemo(() => {

    return (
    <>
      <div className={styles.selectButton}>
        <Select
          btnLabel={getGenderFormatMessage('subjectsDropdown')}
          value={selectedSubject}
          options={groupedOptionsByAgeGrade}
          arrowSpaceSize="large"
          optionSize="medium"
          onChange={handleSubjectChange}
          isFocusFilterOnOpen={true}
          btnProps={{
            id: "nwaSubjectsDropdown",
            view: "alternate",
            shape: "roundedRectangle",
            size: "large",
            color: "basic",
            category: 'upperSearch',
            customStyle: {height: '100%', width: isMobileView ? '44vw' : '26.7vw' },
            label: "filterSubject",
            ariaLabel: getGenderFormatMessage('subjectsDropdown'),
            isDisabled: groupedOptionsByAgeGrade?.length < 2
          }}
        />
      </div>
      <div className={styles.selectButton}>
        <Select
          btnLabel={getGenderFormatMessage('subSubjectsDropdown')}
          value={selectedSubSubject}
          options={syllabusSubsubjectsOptionsForDropDown}
          arrowSpaceSize="large"
          optionSize="medium"
          onChange={handleSubSubjectChange}
          isFocusFilterOnOpen={true}
          btnProps={{
            id: "nwaSubsubjectsDropdown",
            view: "alternate",
            shape: "roundedRectangle",
            size: "large",
            color: "basic",
            category: 'upperSearch',
            customStyle: {height: '100%', width: isMobileView ? '44vw' : '26.7vw' },
            label: "filterSubSubject",
            ariaLabel: getGenderFormatMessage('subSubjectsDropdown'),
            isDisabled: !selectedSubject?.value || syllabusSubsubjectsOptionsForDropDown?.length < 3
          }}
        />
      </div>
    </>)
  }, [syllabusSubjectsOptions, syllabusSubsubjectsOptionsForDropDown, selectedSubject, selectedSubSubject]);

  return (
    <form onSubmit={handleSubmitSearch} className={`${styles.form} ${isHomePage(intl.locale) ? styles.home : ''}`}>
      {isHomePage(intl.locale) ?
        <div className={styles.header}>
          <Title level={1} color="#fff">
            {getGenderFormatMessage('search_header')}
          </Title>
        </div>
        :
        (isCatalogPage || isSearchPage) &&
        <div className={`${styles.pageTitle}`}>
          <Title level={1} color="#fff">
            {isSearchPage && isTeacherCenterKesem && (`${getGenderFormatMessage('teachers_center')} - ${getGenderFormatMessage('kesem_and_friends')}`)}
            {isSearchPage && !isTeacherCenterKesem && getGenderFormatMessage('search_results_for')}
            {isCatalogPage && getGenderFormatMessage('catalog')}
            <span className={styles.subTitle}></span>
          </Title>
        </div>
      }
      <div ref={searchFormRef} className={styles.topRowWrapper}>
        <Input
          id="nwaSearchInput"
          aria-label={searchPlaceholderText}
          containerClassName={`${styles.input} ${error ? styles.error : ''}`}
          placeholder={
            error
              ? getGenderFormatMessage('search_is_missing')
              : searchPlaceholderText
          }
          value={searchBy}
          onChange={handleSearchTextChange}
          onFocus={handleSearchFocus}
          onKeyPress={handleSearchByChange}
          onAutoCompleteSelect={handleAutoCompleteSelect}
          onBlur={handleSearchBlur}
          autoCompleteOptions={autoCompleteOptions}
          autoCompleteClassName={styles.autoComplete}
          autoCompleteCategory={autoCompleteCategory}
          autoComplete="off"
          maxLength={200}
        >
          {(autoCompleteOption, i) => {
            return (
              <div key={`option-${i}`} className={styles.autocompleteOption}>
                <span className={styles.autocompleteTitle}>{autoCompleteOption.text}</span>
                {!isMobileView && autoCompleteOption.dimensions.map((dimension, j) => (
                  <span key={`dimension-${i}-${j}`} className={styles.autocompleteDimension} data-dimension={dimension.prop}>{dimension.text}</span>
                ))}
              </div>
            );
          }}
        </Input>
        {searchBy && <button className={styles.searchCancelButton} label="clearText" onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (event?.nativeEvent?.pointerType === 'mouse' || event?.nativeEvent?.pointerType === 'touch') { // Prevents from clearing the search text when clicking enter on the keyboard
            setSearchBy(null);
          }
          onRequestAutoComplete('');
          searchFormRef.current?.querySelector('input').focus();
        }}>
          <Icon name="Close" width="24" height="24" />
        </button>}
        <div className={styles.selectorsWrapper}>
          {ageGrades && (
            <>
              <div className={styles.selectButton}>
                <Select
                  btnLabel={getGenderFormatMessage('ageGrades')}
                  values={selectedAgeGrades}
                  options={ageGradesOptions}
                  arrowSpaceSize="large"
                  optionSize="medium"
                  onChange={handleAgeGradesChange}
                  isSelectOnBlur={!isMobileView}
                  isFocusFilterOnOpen={true}
                  isMultiSelect={true}
                  showSelection={true}
                  onSecondaryBtnClick={clearAgeGrades}
                  btnProps={{
                    id: "nwaAgeGradesDropdown",
                    view: "alternate",
                    shape: "roundedRectangle",
                    size: "large",
                    color: "basic",
                    category: 'upperSearch',
                    customStyle: {height: '100%', width: isMobileView ? '44vw' : '12vw' },
                    label: "filterGrade",
                    ariaLabel: getGenderFormatMessage('ageGrades'),
                    isDisabled: isTeacherCenterKesem
                  }}
                />
              </div>
            </>
          )}
          {disciplines && (
            <>
              <span className={styles.spacer} />
              <div className={styles.selectButton}>
                <Select
                  btnLabel={getGenderFormatMessage('discipline')}
                  value={selectedDisciplines}
                  options={disciplinesOptions}
                  arrowSpaceSize="large"
                  optionSize="medium"
                  onChange={handleDisciplinesChange}
                  isFocusFilterOnOpen={true}
                  btnProps={{
                    id: "nwaDisciplineDropdown",
                    view: "alternate",
                    shape: "roundedRectangle",
                    size: "large",
                    color: "basic",
                    category: 'upperSearch',
                    customStyle: {height: '100%', width: isMobileView ? '44vw' : '12vw' },
                    label: "filterDiscipline",
                    ariaLabel: getGenderFormatMessage('discipline'),
                    isDisabled: isTeacherCenterKesem
                  }}
                />
              </div>
            </>
          )}
        </div>
        {isMobileView &&
          <div className={`${styles.selectorsWrapper} ${styles.subjectsSelectorsWrapper}`}>
            {isShowSubjects && subjectsDropdowns}
          </div>
        }
        <div className={styles.searchButtonWrapper}>
          <Button
            id="nwaSearchButton"
            ref={searchButtonRef}
            color="primary"
            view="outlined"
            shape="roundedRectangle"
            customStyle={{ background: "white", height: "54px", width: isMobileView ? '100%' : ''}}
            size="large"
            ariaLabel={getGenderFormatMessage('search')}
            onClick={handleSubmitSearch}
            category="upperSearch"
            label={getGenderFormatMessage('search')}
            optionalData={{ fieldsResponse: optionalData, object_additional_information: optionalData }}
          >
            <Icon name="Search" width={searchIconSize} height={searchIconSize} fillColor={'var(--primaryColor)'} />
            &nbsp;
            <span>
              {getGenderFormatMessage('search')}
            </span>
          </Button>
        </div>
        <div className={styles.clearBtnWrapper}>
          {(searchBy || selectedAgeGrades?.length || selectedDisciplines) && clearBtn}
        </div>
      </div>
      {!isMobileView &&
        <div className={`${styles.selectorsWrapper} ${styles.subjectsSelectorsWrapper}`}>
          {isShowSubjects && subjectsDropdowns}
        </div>
      }
    </form>
  );
};

SearchBox.propTypes = {
  isOpen: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  autoCompleteOptions: optionsPropTypes,
  autoCompleteCategory: PropTypes.string,
  onRequestAutoComplete: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSubmitSearch: PropTypes.func,
};

export default SearchBox;
