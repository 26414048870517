import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export const safelyGetInnerHTML = (htmlString = '', isAllowStyle = false) => {
  if (typeof htmlString !== 'string') {
    return htmlString;
  }
  const config = {
    FORBID_ATTR: [isAllowStyle ? null : 'style'],
    FORBID_DATA_ATTR: false
  }
  const cleanHtml = DOMPurify.sanitize(htmlString, config);
  const safeElements = parse(cleanHtml);

  try {
    return safeElements;
  } catch (error) {
    console.error(error);
    return htmlString;
  }
};
