import { useEffect, useState } from 'react';
import { useScripts } from './useScripts';
import { apiGatewayUrl } from '../utils/urls';

const useScriptsList = [
    {
        src: `${apiGatewayUrl}/dashboardapi/provider/dashboardprovider.js`,
        isAlreadyLoaded: () => window.cet?.microservices?.dashboardapi
    }
];

export const useDashboard = (userRole) => {
    const {isReady, error} = useScripts(useScriptsList);
    const [dashboardAPI, setDashboardAPI] = useState(null);

    useEffect(() => {
        if(isReady && !error && window.cet?.microservices?.dashboardapi) {
            if (userRole?.toLowerCase() === 'teacher') {
                setDashboardAPI(window.cet.microservices.dashboardapi.teachers);
            } else if (userRole?.toLowerCase() === 'student') {
                setDashboardAPI(window.cet.microservices.dashboardapi.students);
            }
        }
    }, [userRole, isReady, error]);

    return { dashboardAPI };
};

export default useDashboard;