import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { callWithCache } from '../utils/callWithCache';
import { parameterFormation } from './useSearch';
// import { useGenderFormatMessage } from './useGenderFormatMessage';

const searchRecommendations = (intl, allDimensions, userInfo, lastSchool, isExtendedCatalog) => async (searchByParameters, groupBy, maxResults) => {
  // const { getGenderFormatMessage } = useGenderFormatMessage();
  if (searchByParameters && userInfo && userInfo.userId) {
    const apiParameters = {
      lang: '',
      teacherId: userInfo.userId,
      dimensionParams: {
        sectors: isExtendedCatalog ? ['state', 'mmad', 'arab'] : lastSchool?.sectorId ? [lastSchool.sectorId] : intl.locale === 'ar' ? ['arab'] : ['state', 'mmad']
      },
      contentParams: { isPublished: [true] },
      retrieveParams: {}
    };
    const dimensionKey = groupBy || 'itemTypes';

    if (groupBy) {
      apiParameters.retrieveParams.dimensionName = groupBy;
    }

    if (maxResults) {
      apiParameters.retrieveParams.maxResults = maxResults;
    }

    searchByParameters.forEach(parameter => {
      const parameterName = Object.keys(parameter)[0];
      if (parameterFormation[parameterName]) {
        if (parameterFormation[parameterName] !== 'textParams') {
          apiParameters[parameterFormation[parameterName]][parameterName] = parameter[parameterName];
        }
      } else {
        apiParameters[parameterName] = parameter[parameterName];
      }
    });

    apiParameters.contentParams.isPublished = [true];

    const response = await callWithCache(window.cet.microservices.recommendationsapi.recommendations.getRecommendations, 'getRecommendations', apiParameters);
    const items = [];
    const sortByReference = allDimensions[dimensionKey].map(item => item.id);

    Object.keys(response).forEach(key => {
      let title = null;
      if (!title && allDimensions && groupBy && allDimensions[groupBy]) {
        var filterDimensions = allDimensions[groupBy].filter(d => d.id === key);
        if (filterDimensions.length > 0) {
          title = filterDimensions[0].name;
        }
      }
      if (!title && response[key].length > 0 && response[key][0][dimensionKey] && response[key][0][dimensionKey][key]) {
        title = response[key][0][dimensionKey][key];
      }
      if (!title) {
        title = key.toString(); //getGenderFormatMessage(key);
      }

      if (response[key].length > 0) {
        response[key].forEach(item => {
          if (item[dimensionKey] && Object.keys(item[dimensionKey]).length > 1) {
            item[dimensionKey] = Object.keys(item[dimensionKey])
              .sort((a, b) => (sortByReference.indexOf(a) < sortByReference.indexOf(b) ? -1 : sortByReference.indexOf(a) > sortByReference.indexOf(b) ? 1 : 0))
              .reduce((acc, key) => ({ ...acc, [key]: item[dimensionKey][key] }), {});
          }
        });
      }

      items.push({
        [dimensionKey]: key,
        title: title,
        data: response[key],
        totalCount: response[key].length
      });
    });

    items.sort((a, b) =>
      sortByReference.indexOf(a[dimensionKey]) < sortByReference.indexOf(b[dimensionKey])
        ? -1
        : sortByReference.indexOf(a[dimensionKey]) > sortByReference.indexOf(b[dimensionKey])
          ? 1
          : 0
    );

    return items;
  }
};

export const useRecommendationsSearch = () => {
  const intl = useIntl();
  const { allDimensions, userInfo, lastSchool, isExtendedCatalog } = useSelector(({ dimensions, user }) => {
    return {
      allDimensions: dimensions.dimensions,
      userInfo: user.info,
      lastSchool: user.lastSchool,
      isExtendedCatalog: user.info?.isExtendedCatalog
    };
  });

  return useMemo(() => searchRecommendations(intl, allDimensions, userInfo, lastSchool, isExtendedCatalog), [allDimensions, intl, userInfo, lastSchool, isExtendedCatalog]);
};
