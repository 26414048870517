import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callWithCache } from '../utils/callWithCache';

export const useRecommendationsHistogtram = () => {
  const user = useSelector(({ user }) => user.info);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(null);
  const [recommendationsHistogtram, setRecommendationsHistogtram] = useState(null);

  useEffect(() => {
    if (user && user.userId) {
      setIsLoading(true);
      setErrorLoading(null);
      

      callWithCache(window.cet.microservices.recommendationsapi?.recommendationsHistogtram?.getRecommendationsHistogtram, 'getRecommendationsHistogtram', {
        teacherId: user.userId
      })
        .then(data => {
          setRecommendationsHistogtram(data);
          setIsLoading(false);
          setErrorLoading(null);
        })
        .catch(error => {
          console.error(error);
          setRecommendationsHistogtram([]);
          setIsLoading(false);
          setErrorLoading(error);
        });
    }
    else {
      setRecommendationsHistogtram([]);
      setIsLoading(false);
      setErrorLoading(null);
    }
  }, [user]);

  return {
    isRecommendationsHistogtramLoading: isLoading,
    recommendationsHistogtramErrorLoading: errorLoading,
    recommendationsHistogtram: recommendationsHistogtram
  };
};
