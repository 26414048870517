import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { extrinsicScript, loadScripts } from './utils/resourceLoader';
import { Provider } from 'react-redux';
import { store } from './state';

import App from './containers/App';

import './index.css';
import * as serviceWorker from './serviceWorker';
import {animationFrameController} from './animationFrameController';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (typeof String.prototype.replaceAll == "undefined") {  
  String.prototype.replaceAll = function(match, replace) {  
    return this.replace(new RegExp(match, 'g'), () => replace);  
  }  
}

(async function () {
  // Load scripts Asynchronically
  await loadScripts();
  // Load BigDataProvider script Synchronically (no await)
  loadScripts(extrinsicScript);

  if (!document.querySelector('body[page-ready]')) {
    (function () {
      var __fetch = window.fetch;
      var fetch_counter = 0;
      var time_out = null;
      var timer = null;
      var isReady = false;

      var onPageReady = function () {
        if (!isReady) {
          isReady = true;
          window.fetch = __fetch;
          if (time_out) {
            clearTimeout(time_out);
            time_out = null;
          }
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          document.querySelector('body').setAttribute('page-ready', 'true');
        }
      };

      var checkCounter = function (count) {
        if (!isReady) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          fetch_counter += count;
          if (fetch_counter <= 0) {
            timer = setTimeout(onPageReady, 3 * 1000);
          }
        }
      };

      time_out = setTimeout(onPageReady, 30 * 1000);
      window.fetch = (...args) => {
        checkCounter(1);

        var f = __fetch(...args);
        f.then(function () {
          checkCounter(-1);
        }).catch(function () {
          checkCounter(-1);
        });
        return f;
      };
    })();

    root.render(
      <Provider store={store}>
        <React.StrictMode>
          <Router>
            <Switch>
              <Route path="/:language?" component={App} />
            </Switch>
          </Router>
        </React.StrictMode>
      </Provider>
    );
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

animationFrameController();