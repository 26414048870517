import { useContext, useMemo } from 'react';
import { css } from '@emotion/css';
import { CetComponentsContext } from 'cet-components-lib/providers/CetComponentsProvider';
import { useDeviceData, useStylesUtils } from 'cet-components-lib/hooks';
import { sidebarWidth, sidebarHeight, sidebarShrinkWidth } from 'cet-components-lib/dist/Content/Site/Site.config';

const useStyles = (isSelected, isOpen, isMoreMenuItem, withTopSeparator, isNewItem) => {
  const { isMobileView } = useDeviceData();
  const { fontFamily, themeColors } = useContext(CetComponentsContext);
  const { pxToRem } = useStylesUtils();

  const ccSideBarStyle = useMemo(() => {
    const deviceProps = () => {
      if (isMobileView && !isMoreMenuItem) {
        return {
          top: 'auto',
          width: '100vw',
          height: pxToRem(sidebarHeight),
          zIndex: 5,
          boxShadow: `0 0 ${pxToRem(7)} ${themeColors?.primary.shadow}`
        };
      }
      return {
        top: 0,
        transition: 'width 0.3s ease',
        width: pxToRem(sidebarWidth),
        zIndex: 1,
        '&:hover': {
          width: pxToRem(sidebarWidth),
          background: '#fff'
        }
      };
    };

    const menuDeviceProps = () => {
      if (isMobileView && !isMoreMenuItem) {
        return {
          width: '100%',
          height: '100%',
          margin: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          background: themeColors?.general.light
        };
      }
      return {
        margin: pxToRem(10)
      };
    };

    const menuItemDeviceProps = () => {
      if (isMobileView && !isMoreMenuItem) {
        return {
          flex: '1 1',
          fontSize: pxToRem(14)
        };
      }
      return {
        marginTop: pxToRem(4)
      };
    };

    const menuItemAnchorContentDeviceProps = () => {
      if (isMobileView && !isMoreMenuItem) {
        return {
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center'
        };
      }
      return {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      };
    };

    const ccSideBar = css({
      fontFamily,
      position: 'fixed',
      bottom: 0,
      marginTop: pxToRem(50),
      ...deviceProps()
    });

    const ccSideBarMinimized = css({
      width: pxToRem(sidebarShrinkWidth),
      '&:hover': {
        transition: 'width 0.3s ease',
        overflow: 'hidden',
        textWrap: 'nowrap'
      }
    });

    const ccSideBarMenu = css({
      listStyle: 'none',
      padding: 0,
      fontWeight: 'bold',
      ...menuDeviceProps()
    });

    const ccSideBarMenuItem = css({
      ...menuItemDeviceProps(),
      borderTop: withTopSeparator ? `1px solid #DAD9FC` : '',
      paddingTop: withTopSeparator ? pxToRem(4) : '0'
    });

    const ccSideBarMenuItemAnchor = () => ({
      width: '100%',
      boxSizing: 'border-box',
      paddingInline: 0,
      height: isMobileView ? (isMoreMenuItem ? pxToRem(30) : '100%') : pxToRem(36),
      color: isSelected ? themeColors?.primary.main : 'inherit',
      background: isSelected ? themeColors?.basic.main : ''
    });

    const ccSideBarMenuItemAnchorContent = css({
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      overflow: 'hidden',
      height: '100%',
      ...menuItemAnchorContentDeviceProps()
    });

    const menuItemLabelDeviceProps = () => {
      if (isMobileView) {
        if (isMoreMenuItem) {
          return {
            marginRight: pxToRem(10),
            fontSize: pxToRem(16)
          };
        }
        return {
          padding: `${pxToRem(4)} 0`,
          fontSize: pxToRem(12),
          lineHeight: 1
        };
      }
      return {
        marginInlineStart: pxToRem(10),
        fontSize: 'inherit'
      };
    };

    const ccSideBarMenuItemIcon = css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    });

    const ccSideBarMenuItemLabel = css({
      display: 'flex',
      alignItems: 'center',
      color: isNewItem ? themeColors?.primary.main : '',
      ...menuItemLabelDeviceProps()
    });

    const ccSideBarMenuItemNewItem = css({
      background: 'linear-gradient(180deg, #5750FA 0%, #0A00FF 100%)',
      borderRadius: pxToRem(20),
      border: 'none',
      padding: `${pxToRem(2.5)} ${pxToRem(9)} ${pxToRem(2)}`,
      marginInlineStart: isMobileView && !isMoreMenuItem ? pxToRem(10) : 'auto',
      lineHeight: pxToRem(18),
      textAlign: 'center',
      fontSize: isMobileView ? pxToRem(10) : pxToRem(12),
      color: themeColors?.general.light,
      fontWeight: 'normal',
      letterSpacing: 0.75,
      width: 'auto',
      transition: 'width 0.5s ease',
      marginLeft: pxToRem(12)
    });

    const ccSideBarMenuItemArrow = css({
      display: 'flex',
      alignItems: 'center',
      marginInlineStart: 'auto',
      marginLeft: pxToRem(12),
      svg: {
        marginTop: pxToRem(15)
      }
    });

    const ccSideBarMenuItemInnerList = css({
      fontWeight: 'normal',
      paddingInlineStart: pxToRem(0),
      listStyleType: 'none',
      maxHeight: isOpen ? `calc(100vh - ${pxToRem(60)})` : 0,
      overflowX: 'auto',
      padding: `0 ${pxToRem(10)}`
    });

    const ccSideBarMenuItemInnerAccordion = css({
      width: pxToRem(240),
      height: '100%',
      position: 'fixed',
      marginRight: pxToRem(230),
      top: pxToRem(60),
      zIndex: 10,
      backgroundColor: 'white',
      borderRight: `2px solid #F0F1F6`
    });

    const ccSideBarMenuItemChildrenList = css({
      listStyleType: 'none',
      padding: 0,
      margin: 0
    });

    const ccSideBarMenuItemChildrenListTitle = css({
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      marginBottom: pxToRem(5),
      ...menuItemLabelDeviceProps()
    });

    const ccSideBarMenuItemChildrenListBorderTop = css({
      borderTop: `1px solid #DAD9FC`,
      marginBottom: pxToRem(20)
    });

    const ccSideBarMenuItemChildrenListIcon = css({
      display: 'flex',
      alignItems: 'center',
      marginLeft: pxToRem(12)
    });

    const ccSideBarMenuItemInnerListItem = css({
      border: '1px solid #00000026',
      borderRadius: pxToRem(10),
      width: pxToRem(210),
      height: pxToRem(80),
      marginBottom: pxToRem(10)
    });

    const ccSideBarMenuItemImage = css({
      width: '100%',
      height: '100%',
      borderRadius: pxToRem(8),
      objectFit: 'fill'
    });

    const ccSideBarMenuItemMoreBtn = css({
      fontSize: pxToRem(12),
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column'
    });

    const ccSideBarMenuItemMoreMenu = css({
      listStyle: 'none',
      padding: 0,
      fontWeight: 'bold'
    });

    return {
      ccSideBar,
      ccSideBarMinimized,
      ccSideBarMenu,
      ccSideBarMenuItem,
      ccSideBarMenuItemIcon,
      ccSideBarMenuItemLabel,
      ccSideBarMenuItemNewItem,
      ccSideBarMenuItemArrow,
      ccSideBarMenuItemAnchor,
      ccSideBarMenuItemAnchorContent,
      ccSideBarMenuItemInnerList,
      ccSideBarMenuItemInnerAccordion,
      ccSideBarMenuItemChildrenList,
      ccSideBarMenuItemChildrenListTitle,
      ccSideBarMenuItemChildrenListBorderTop,
      ccSideBarMenuItemChildrenListIcon,
      ccSideBarMenuItemInnerListItem,
      ccSideBarMenuItemImage,
      ccSideBarMenuItemMoreBtn,
      ccSideBarMenuItemMoreMenu
    };
  }, [
    fontFamily,
    pxToRem,
    isOpen,
    isMobileView,
    isMoreMenuItem,
    themeColors?.primary.shadow,
    themeColors?.primary.main,
    themeColors?.general.light,
    themeColors?.basic.main,
    isSelected,
    withTopSeparator,
    isNewItem
  ]);

  return {
    'cc-SideBar': ccSideBarStyle.ccSideBar,
    'cc-SideBar_Minimized': ccSideBarStyle.ccSideBarMinimized,
    'cc-SideBar__menu': ccSideBarStyle.ccSideBarMenu,
    'cc-SideBar__menu__item': ccSideBarStyle.ccSideBarMenuItem,
    'cc-SideBar__menu__item__anchor': ccSideBarStyle.ccSideBarMenuItemAnchor,
    'cc-SideBar__menu__item__anchor-content': ccSideBarStyle.ccSideBarMenuItemAnchorContent,
    'cc-SideBar__menu__item__icon': ccSideBarStyle.ccSideBarMenuItemIcon,
    'cc-SideBar__menu__item__label': ccSideBarStyle.ccSideBarMenuItemLabel,
    'cc-SideBar__menu__item__new-item': ccSideBarStyle.ccSideBarMenuItemNewItem,
    'cc-SideBar__menu__item__arrow': ccSideBarStyle.ccSideBarMenuItemArrow,
    'cc-SideBar__menu__item__inner-list': ccSideBarStyle.ccSideBarMenuItemInnerList,
    'cc-SideBar__menu__item__inner-accordion': ccSideBarStyle.ccSideBarMenuItemInnerAccordion,
    'cc-SideBar__menu__item__children-list': ccSideBarStyle.ccSideBarMenuItemChildrenList,
    'cc-SideBar__menu__item__children-list-title': ccSideBarStyle.ccSideBarMenuItemChildrenListTitle,
    'cc-SideBar__menu__item__children-list-borderTop': ccSideBarStyle.ccSideBarMenuItemChildrenListBorderTop,
    'cc-SideBar__menu__item__children-list-icon': ccSideBarStyle.ccSideBarMenuItemChildrenListIcon,
    'cc-SideBar__menu__item__inner-list-item': ccSideBarStyle.ccSideBarMenuItemInnerListItem,
    'cc-SideBar__menu__item__image': ccSideBarStyle.ccSideBarMenuItemImage,
    'cc-SideBar__menu__item__more-btn': ccSideBarStyle.ccSideBarMenuItemMoreBtn,
    'cc-SideBar__menu__item__more-menu': ccSideBarStyle.ccSideBarMenuItemMoreMenu
  };
};

export default useStyles;
