import React from 'react';
import { useGenderFormatMessage } from '../../hooks';
import PropTypes from 'prop-types';

import Button from '../Button';
import Modal from '../Modal';

import styles from './OldSiteMessage.module.scss';

const OldSiteMessage = ({ userName, link, onClose }) => {
  const { getGenderFormatMessage } = useGenderFormatMessage();
  return (
    <Modal containerClassName={styles.container} isActive={Boolean(link)} closeButtonLabel={getGenderFormatMessage('close')} onClose={onClose}>
      <>
        <div className={styles.content}>
          <h2 className={styles.title}>
            {getGenderFormatMessage('hey')} {userName}
          </h2>
          <p>{getGenderFormatMessage('message_going_to_old_site')}</p>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={() => {
              window.open(link);
              onClose();
            }}
            type="line"
            size="medium"
          >
            {getGenderFormatMessage('got_it')}
          </Button>
        </div>
      </>
    </Modal>
  );
};

OldSiteMessage.propTypes = {
  userName: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OldSiteMessage;
