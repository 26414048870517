import { useState, useEffect } from 'react';

let animationTimeout;

export const useDisplay = (isActive, containerRef, tipRef, offset = 0) => {
  const [left, setLeft] = useState(0);
  const [isIn, setIsIn] = useState(false);

  useEffect(() => {
    if (containerRef.current && tipRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const tipRect = tipRef.current.getBoundingClientRect();

      const contentElement = containerRef.current.children[1];
      if (contentElement) {
        const contentElementRect = contentElement.getBoundingClientRect();

        const contentLeft = contentElementRect.left - containerRect.left;
        const tipCenter = contentLeft + contentElementRect.width / 2 - tipRect.width / 2 + offset;
        setLeft(tipCenter);
      }

      animationTimeout = setTimeout(() => {
        setIsIn(true);
      }, 300);
    }

    return () => clearTimeout(animationTimeout);
  }, [containerRef, isActive, offset, tipRef]);

  return [isIn, left];
};
